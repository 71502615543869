import {AccountService} from "src/ApiService/Account";
import {BillingService} from "src/ApiService/Billing";
import {BlockingService, BlockingTypeService} from "src/ApiService/Blocking";
import ContractService from "src/ApiService/Contract";
import {ContragentService, RuleService} from "src/ApiService/Contragent";
import CurrencyService from "src/ApiService/Currency";
import {AttributeService, DictionaryService, EnumGroupService, MeasureService} from "src/ApiService/Dictionary";
import DiscountService from "src/ApiService/Discount";
import DocumentService, {
    BillService,
    DebtInvoiceService,
    InvoiceService,
    PayOrderService,
    ReconService,
    RefillInvoiceService
} from "src/ApiService/Document";
import {EdoContragentService, EdoMessageService, EdoService} from "src/ApiService/Edo";
import {EventService} from "src/ApiService/Event";
import {MailService} from "src/ApiService/Mail";
import {NumeratorService} from "src/ApiService/Numerator";
import {OfferService} from "src/ApiService/Offer";
import {CartService, OrderItemService, OrderService} from "src/ApiService/Order";
import {PackageService} from "src/ApiService/Package";
import {FableService} from "src/ApiService/Fable";
import {ProductNomenclatureService,
    // ProductOfferService,
    ProductService} from "src/ApiService/Product";
import RevenueService, {RevenueItemService} from "src/ApiService/Revenue";
import {CounterService, SubscribeService, SubscribeServiceGroup} from "src/ApiService/Service";
import {TagService} from "src/ApiService/Tags";
import UserService from "src/ApiService/User";
import {ReportService} from "src/ApiService/Report";
import {SettingService} from "src/ApiService/Sys";
import TemplateService from "src/ApiService/Template";

// @formatter:off
export
let PropertyMixin = supclass => class extends supclass {
    get Order() {return ParentizeService(this, Order);}
    get Revenue() {return ParentizeService(this, Revenue);}
    get Document() {return ParentizeService(this, Document);}
    get Product() {return ParentizeService(this, Product);}

    get Blocking() {return ParentizeService(this, BlockingService);}
    get Contract() {return ParentizeService(this, ContractService);}
    get Cart() {return ParentizeService(this, CartService);}
    get Account() {return ParentizeAccountService(this, AccountService);}
    get Discount() {return ParentizeService(this, DiscountService);}
    get Package() {return ParentizeService(this, PackageService);}
    get Offer() {return ParentizeService(this, OfferService);}
    // get Product() {return ParentizeService(this, ProductService);}
    get Numerator() {return ParentizeService(this, NumeratorService);}
    get Mail() {return ParentizeService(this, MailService);}
    get Service() {return ParentizeService(this, SubscribeService);}
    get Counter() {return ParentizeService(this, CounterService);}
    get ServiceGroup() {return ParentizeService(this, SubscribeServiceGroup);}
    get Nomenclature() {return ParentizeService(this, ProductNomenclatureService);}

    get Report() {return ParentizeService(this, ReportService);}
    get Rule() {return ParentizeService(this, RuleService);}
    get Template() {return ParentizeService(this, TemplateService);}
}
// @formatter:on

/**
 * делает из класса объект сервиса и встраивает его в древовидную иерархию
 * @param srvclass {Class}
 * @param mod {function}
 * @param field {string}
 * @returns {function(Object|int): BaseService}
 */
export function Servicize(srvclass, mod = x => x, field = 'id') {
    return item => {
        const id = item?.[field] || item;
        return mod(new srvclass(id));
    };
}

/**
 * делает из класса объект сервиса и встраивает его в древовидную иерархию (чисто для Account)
 * @param srvclass {Class}
 * @param mod {function}
 * @returns {function(Object|int): BaseService}
 */
function AccountServicize(srvclass, mod = x => x) {
    return Servicize(srvclass, mod, 'number');
}

/**
 * Создаёт объект сервиса srvclass и делает его подчинённым (url) к parent
 * @param parent {BaseService}
 * @param srvclass {Class}
 * @returns {function((Object|int)): BaseService}
 */
export function ParentizeService(parent, srvclass) {
    const res = parent._mk_internal_res();

    function _parentize(srv) {
        srv.MODEL = `${res}/${srv.MODEL}`;
        srv._parent = parent;
        return srv;
    }

    return Servicize(srvclass, _parentize);
}

/**
 * Создаёт объект сервиса srvclass и делает его подчинённым (url) к parent (Чисто для Account)
 * @param parent {BaseService}
 * @param srvclass {Class}
 * @returns {function((Object|int)): BaseService}
 */
function ParentizeAccountService(parent, srvclass) {
    const res = parent._mk_internal_res();

    function _parentize(srv) {
        srv.MODEL = `${res}/${srv.MODEL}`;
        return srv;
    }

    return AccountServicize(srvclass, _parentize);
}

/**
 * Создаёт родителя для остальных сервисов (в цепочке url)
 * @param parent {Object|int}
 * @param ParentService {Class}
 * @returns {BaseService}
 */
export function Parent(parent = null, ParentService = null) {
    // class ExtendedParent extends ParentService {}
    return new ParentService(parent?.id || parent);
}

/**
 * @param contragent {int|Contragent}
 * @returns {ContragentService|ExtendedContragent}
 */
export function Contragent(contragent = null) {
    class ExtendedContragent extends
        PropertyMixin(ContragentService) {}

    return Parent(contragent?.id || contragent, ExtendedContragent);
}

/**
 *
 * @param order {int|null|Object}
 * @returns {ExtendedOrder}
 */
export function Order(order = null) {
    class ExtendedOrder extends OrderService {
        get Items() {
            return ParentizeService(this, OrderItemService);
        }
    }
    return Parent(order, ExtendedOrder);
}

/**
 *
 * @param document {int|null|Object}
 * @returns {ExDocum}
 */
export function Document(document = null) {
    class ExDocum extends DocumentService {
        // @formatter:off
        get Bill() { return ParentizeService(this._parent, BillService); }
        get Recon() { return ParentizeService(this._parent, ReconService); }
        get Invoice() { return ParentizeService(this._parent, InvoiceService); }
        get PayOrder() { return ParentizeService(this._parent, PayOrderService); }
        get Refill() { return ParentizeService(this._parent, RefillInvoiceService); }
        get DebtInvoice() { return ParentizeService(this._parent, DebtInvoiceService); }
        // @formatter:on
    }

    return Parent(document, ExDocum);
}

/**
 * Product Parent
 * @param product
 * @returns {BaseService}
 */
export function Product(product = null) {
    class ExProduct extends ProductService {
        get Offer() { return ParentizeService(this, OfferService); }
    }

    return Parent(product, ExProduct);
}

/**
 * @param revenue {int|null}
 * @returns {ExtendedRevenue}
 */
export function Revenue(revenue = null) {
    class ExtendedRevenue extends RevenueService {
        get Items() {
            return ParentizeService(this, RevenueItemService);
        }
    }

    return Parent(revenue, ExtendedRevenue);
}

function Query() {
    return {
        Contragent,
        Order,
        Revenue,
        Product,
        Blocking: Servicize(BlockingService),
        BlockingType: Servicize(BlockingTypeService),
        Contract: Servicize(ContractService),
        Dictionary: Servicize(DictionaryService),
        Document: Servicize(DocumentService),
        Account: AccountServicize(AccountService),
        OrderItem: Servicize(OrderItemService),

        Discount: Servicize(DiscountService),
        Package: Servicize(PackageService),
        Billing: Servicize(BillingService),
        Currency: Servicize(CurrencyService),
        Edo: Servicize(EdoService),
        EdoContragent: Servicize(EdoContragentService),
        EdoMessage: Servicize(EdoMessageService),
        Event: Servicize(EventService),

        Measure: Servicize(MeasureService),
        Attribute: Servicize(AttributeService),
        EnumGroup: Servicize(EnumGroupService),


        Fable: Servicize(FableService),
        Mail: Servicize(MailService),
        Tag: Servicize(TagService),
        User: Servicize(UserService),
        Report: Servicize(ReportService),
        Setting: Servicize(SettingService)
    };
}


const X = Query();

export {
    X,
    Query,
};
