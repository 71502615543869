import {Form, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {X} from "app/ApiService/Query";


export default function DictionaryForm(props) {

    const [attributes, setAttributes] = useState([]);
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        X.Attribute().list().then(res => {
            setAttributes(res.data.data);
        })
        X.Dictionary().getEntities().then(res => {
            setEntities(res.data.data)
        })
    }, [])

    return <Form layout='vertical' {...props}>
        <Form.Item name='name' label='Название словаря' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='codename' label='codename словаря' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='attributes' label='атрибуты словаря'>
            <Select mode='multiple'>
                {attributes.map(el => {
                    return <Select.Option key={el.id} value={el.id}>
                        {el.name}
                    </Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item name='entities_list' label='привязать к'>
            <Select mode='multiple'>
                {entities.map(el => {
                    return <Select.Option key={el.value} value={el.value}>
                        {el.name}
                    </Select.Option>
                })}
            </Select>
        </Form.Item>
    </Form>
}
