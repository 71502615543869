import Modal from "app/Base/Modals/Modal";
import React, {useEffect} from "react";
import {Form, Typography} from "antd";
import {DiscountForm} from "../Form/DiscountForm";
import useUser from "src/Providers/UserProvider";
import moment from "moment";
import {X} from "app/ApiService/Query";


export function DiscountModal({item, ...props}) {

    const [form] = Form.useForm();
    const {contragent} = useUser();

    useEffect(() => {
        form.resetFields();

        if (item) {
            X.Contragent(contragent).Discount(item.id).get().then(res => {
                res.data.date_start = res.data.date_start && moment(res.data.date_start);
                res.data.date_end = res.data.date_end && moment(res.data.date_end);
                if (!res.data.date_end) {
                    delete res.data.date_end;
                }
                if (!res.data.date_start) {
                    delete res.data.date_start;
                }
                let new_conditions = res.data.conditions?.map(el => {
                    return {
                        field: `${el.field.entity}.${el.field.field}`,
                        value: el.value.val,
                        operator: el.operator,
                        offer: el?.offer,
                        id: el?.id,
                        package: el?.package,
                    };
                });
                res.data.conditions = new_conditions || [];
                form.setFieldsValue(res.data);
            });
        }
    }, [item]);

    return <Modal width="80%"
                  title={item ?
                      <Typography.Text>
                          Редактировать модификатор
                          <Typography.Text code>
                              #{item?.id}
                          </Typography.Text>
                      </Typography.Text> :
                      "Создать модификатор"}
                  form="discount_form"
                  btnname={"Сохранить"}
                  {...props}>
        <DiscountForm form={form} item={item} id="discount_form" onFinish={() => {
            typeof props.onCancel === "function" && props.onCancel();
        }}/>
    </Modal>;
}
