import React from "react";
import {Card, Descriptions} from 'antd'
import useUser from "../../Providers/UserProvider";
import './Profile.module.less';
import AsideMenu, {AsideTab} from "../../Base/Components/AsideMenu/AsideMenu";


export default function ProfileSettings(props){

    const {setContragent, groups} = useUser()

    // function updateSelfContragent(data){
    //     patchUserContragentAPI(data).then(res => {
    //         message.success('Данные обновлены');
    //         setContragent(res.data)
    //     }).catch(err => {
    //         message.error('Возникла ошибка')
    //     })
    // }

    return (
        <AsideMenu defaultId='1'>
            <AsideTab title='Настройки' id='1' key='1'>
                <Card title='Информация о контрагенте'>
                    {/* <Card size='small' title='общее'>
                        <ContragentForm id='contragent_form' onFinish={updateSelfContragent} />
                        <Button type='primary' htmlType='submit' form='contragent_form'>Сохранить</Button>
                    </Card> */}
                    {/* <br /> */}
                    <Card size='small' title='группы пользователя'>
                        <Descriptions column={1} bordered>
                            {groups.map(group => (
                                <Descriptions.Item label={group.title}>{group.name}</Descriptions.Item>
                            ))}
                        </Descriptions>
                    </Card>
                </Card>
            </AsideTab>
            <AsideTab title='Помощь' id='2' key='2'>

            </AsideTab>
        </AsideMenu>
    )

}
