import {PlusOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {Button, Col, Row, Space} from "antd";
import {X} from "app/ApiService/Query";
import {AtolX} from "app/ApiService/AtolQuery";
import SearchTable from "app/Base/Tables/SearchTable";
import {usePaginationEx} from "app/Components/Common/Hooks/usePagination";
import React, {useEffect, useState} from "react";

import {useMap, useToggle} from "react-use";

import Money from "src/Components/Accounts/utils/Money";
import useContragent from "../../Contragents/ContragentContext";
import {OrderModal} from "../../Products/Modals/OrderModal";
import useIntegration from '../../../Providers/IntegrationsProvider'

export function MarketTable({...props}) {

    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, {setAll: setModalVisible}] = useMap({visible: false});
    const [update, toggle] = useToggle(false);
    const [search, setSearch] = useState();
    const {atol} = useIntegration();

    const [fltdOffers, setFltdOffers] = useState(offers);

    const {contragent, refreshCart} = useContragent();
    const [{page, limit, order}, pagination, {setTotal, onSortChange}] = usePaginationEx();

    useEffect(() => {
        setLoading(true);
        // позже сделать декоратор, который в зависимости от модуля подключает компоненты

        let ctgsrv = X.Contragent(contragent);

        if (atol)
            ctgsrv = AtolX.Contragent(contragent);

        ctgsrv.getMarket({page, limit, search, order: order || "name"}).then(res => {
            setOffers(res.data.data);
            setTotal(res.data.count);
        }).finally(() => {
            setLoading(false);
        });

    }, [update, contragent, page, limit, search, order]);

    useEffect(() => {
        setFltdOffers(
            offers.filter(x =>
                !search ||
                x.name.toLowerCase().includes(search.toLowerCase()))
        );
    }, [offers, search]);

    function handleToBucketClick(record) {
        const data = {
            offer: record.id,
            price: record.price,
            contragent: contragent.id,
            count: 1,
            tax: 0,
        };
        X.Contragent(contragent).Cart().add_item(data);
        refreshCart();
    }

    const columns = [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "описание",
            dataIndex: "name",
            key: "name",
            // sorter: (a, b) => a.name.localeCompare(b.name),
            sorter: true,
            defaultSortOrder: "ascend",
        },
        {
            title: "контрагент",
            dataIndex: "contragent_name",
            key: "contragent_name",
        },
        {
            title: "цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "налоговая ставка",
            dataIndex: "taxes",
            key: "taxes",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "предоплата",
            dataIndex: "pre",
            key: "pre",
            render: (value) => {
                return `${value} %`;
            },
        },
        {
            title: "тип услуги",
            dataIndex: "type_name",
            key: "type_name",
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            align: "right",
            render: (value, record) => (
                <Space disabled={false}>
                    <Button
                        size="small" icon={<PlusOutlined/>}
                        onClick={() => {
                            setModalVisible({visible: true, item: record});
                        }}
                    />
                    <Button
                        size="small"
                        icon={<ShoppingCartOutlined/>}
                        onClick={() => handleToBucketClick(record)}
                    />
                </Space>
            ),
        },
    ];

    return <SearchTable size="small"
                        columns={columns}
                        dataSource={fltdOffers}
                        loading={loading}
                        toggle={toggle}
                        onSearchEdit={setSearch}
                        pagination={pagination}
                        onChange={onSortChange}
                        title={() => (
                            <Row justify="space-between">
                                <Col>
                                    <OrderModal
                                        visible={modalVisible?.visible}
                                        item={modalVisible?.item}
                                        onCancel={() => {
                                            setModalVisible({visible: false});
                                            toggle();
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {...props}
    />;
}
