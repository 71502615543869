import { message } from "antd";
import {BaseService} from "./Base";


export class NumeratorService extends BaseService {
    MODEL = 'numerator'

    update_index(data){
        return this._post('index', data).then(res => {
            message.success('Последовательность обновлена');
            return Promise.resolve(res);
        }).catch(this.error_handler)
    }
}
