import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Form} from 'antd';
import {X} from "app/ApiService/Query";

const RuleContext = React.createContext();


class Rule {

    constructor({id, event, contragent_id, type}) {
        this.id = id
        this.event = event
        this.contragent_id = contragent_id;
        this.type = type;
    }

    fetch_detail() {
        return X.Contragent(this.contragent_id).Rule(this.id).retrieve();
    }
}

export function RuleProvider({children}) {
    // Контекст хранилища по работе с правилами
    const [form] = Form.useForm();
    const [actions, setActions] = useState([]);
    const [event, setEvent] = useState({});
    const [expressions, setExpressions] = useState([]);
    const [operators, setOperators] = useState([]);
    const [inputs, setInputs] = useState({});
    const [edited, setEdited] = useState(false);

    const [rule, _setRule] = useState(null);

    function setRule(data) {
        if (data) {
            _setRule(new Rule(data));
        } else {
            _setRule(null);
            setEvent(null);
            form.resetFields();
        }
    }

    useEffect(async () => {
        if (rule?.event) {
            setExpressions([]);
            setActions([]);
            const {data} = await X.Event(rule?.event).retrieve();
            // new EventService(rule?.event).get().then(res => {
            setActions(data.actions);
            setExpressions(data.expressions);
            setEvent({name: data.name});
            setOperators(data.operators);
            setInputs(data.inputs);
            // });
        }
    }, [rule])


    const memoedValue = useMemo(() => ({
        rule,
        actions,
        expressions,
        event,
        form,
        operators,
        inputs,
        edited
    }), [rule, form, actions, expressions, event, operators, inputs, edited]);

    const functions = {
        setRule,
        setEdited
    }
    let values = {...memoedValue, ...functions}

    return (
        <RuleContext.Provider value={values}>
            {children}
        </RuleContext.Provider>
    );
}

export default function useRule() {
    return useContext(RuleContext);
}
