import React from "react";
import {Layout, Result, Button} from "antd";
import {Content, Header, Sider, Footer} from "./Layout";
import {Route, Switch, Redirect, useHistory} from "react-router-dom";
import {Client, Module, ProfileSettings, Variable, Rules} from "./index";
import User from "./Users/User";
import Dictionaries from "src/Components/Dictionary/Dictionaries";
import useUser from "../Providers/UserProvider";
// import CtgSelectModal from "./Auth/CtgSelectModal";
// import Product from './Products/Products'
import {OffersTable} from "./Products/Table/OffersTable";
import Template from "./Templates/Templates";
import {Settings} from "./Settings/Settings";
import Package from "./Products/Packages";
import OrdersTable from "./Contragents/Tables/OrdersTable";
import ReportsComponent from "./Reports/Reports";
import {QueryClientProvider, QueryClient} from "react-query";
// import { DiscountForm } from "./Discount/Form/DiscountForm";
import DiscountRouter from "./Discount/Discount";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false,
            enabled: true,
        },
    },
});

export default function PriceplanWelcome() {
    // Основной компонент админки
    const {contragent, permissions, is_global_permitted} = useUser();

    const history = useHistory();

    if (!contragent) {
        return <Result
            status="403"
            title="403"
            subTitle="Недоступно"
            extra={<Button type="primary" onClick={() => {
                localStorage.removeItem("jwt_sec_token_v1");
                history.push("signin");
            }}>Выйти</Button>}
        />;
    }

    if (permissions?.length === 0) {
        return <Result
            status="403"
            title="403"
            subTitle="Недостаточно прав"
            extra={<Button type="primary" onClick={() => {
                localStorage.removeItem("jwt_sec_token_v1");
                history.push("signin");
            }}>Выйти</Button>}
        />;
    }

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header/>
            <Layout>
                <Sider/>
                <Content>
                    <QueryClientProvider client={queryClient}>
                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div style={{flex: 1}}>
                                <Switch>
                                    <Route path="/offers">
                                        {is_global_permitted("application.offer.access") ?
                                            <OffersTable/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route path="/packages">
                                        {is_global_permitted("application.package.access") ?
                                            <Package/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route path="/contragents/:contragent_id/discount">
                                        {is_global_permitted("application.discount.access") ?
                                            <DiscountRouter/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route path="/contragents">
                                        {is_global_permitted("application.contragent.access") ?
                                            <Client/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route exact path="/profile">
                                        <ProfileSettings/>
                                    </Route>
                                    <Route path="/settings">
                                        {is_global_permitted("application.setting.access") ?
                                            <Settings/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route exact path="/variables">
                                        <Variable/>
                                    </Route>
                                    <Route exact path="/dictionary">
                                        <Dictionaries/>
                                    </Route>
                                    <Route path="/modules">
                                        <Module/>
                                    </Route>
                                    <Route exact path="/">
                                        <Redirect to="/contragents"/>
                                    </Route>
                                    <Route path="/templates">
                                        {is_global_permitted("application.template.access") ?
                                            <Template/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route path="/rules">
                                        {is_global_permitted("rules.rule.access") ?
                                            <Rules/> :
                                            <Result title="Недостаточно прав"/>
                                        }
                                    </Route>
                                    <Route exact path="/order-manage">
                                        <OrdersTable/>
                                    </Route>
                                    <Route path="/reports">
                                        <ReportsComponent/>
                                    </Route>
                                </Switch>
                            </div>
                            <Footer/>
                        </div>
                    </QueryClientProvider>
                </Content>
            </Layout>
        </Layout>
    );
}
