import {Menu, message, Popconfirm, Space, Tag, Tooltip} from "antd";
import {Card} from "app/Base";
import {BlockingModal} from "app/Components/Common/Modal/BlockingModal";
import ContragentCreateButton from "app/Components/Contragents/ActionButtons/ContragentCreateButton";
import ClientModal from "app/Components/Contragents/Modals/ClientModal";
import {useContragentData} from "app/Data/Base";
import {useEdoContragentData} from "app/Data/EdoBase";
import useIntegration from "app/Providers/IntegrationsProvider";
import {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {BillingManageModal} from "./ClientTable";
import {X} from "app/ApiService/Query";
import {Fable} from "app/Base/Tables/Fable/Fable";


export function BaseContragentTable({model, title, columns: _clms, menu}) {
    const [modalVisible, setModalVisible] = useState(false);
    const [time_modal, setTimeModal] = useState(false);

    const [isModalBlock, setModalBlock] = useState(false);
    const [targetContragent, setTargetContragent] = useState();
    const history = useHistory();

    let columns = _clms || [
        {
            title: "Наименование", dataIndex: "name", key: "name",
            render: (val, record) => (
                <Link
                    to={`/contragents/${record.id}`}
                    target="_blank"
                >
                    {val}
                </Link>
            ),
        },
        {
            title: "Статус", dataIndex: "status", key: "status",
            render: (val, record) => <Tag color={val?.color}>{val?.label}</Tag>,
        },
        {
            title: "Форма деятельности", dataIndex: "type", key: "type",
            // render: (val, record) => <Tag>{val}</Tag>
        },
        {
            title: "Последний рассчитанный день", dateIndex: "last_billed_day", key: "last_billed_day",
            render: (val, rec) => <Tooltip title={rec.error_message}>
                <Tag color={rec.bill_status}>{val}</Tag>
            </Tooltip>,
        },
    ];

    const renders = {
        role: (val, rec) => {
            switch (val.name) {
                case "MAIN_OPERATOR": return <Tag color={"volcano"}>{val.label}</Tag>;
                case "REGULAR": return <Tag color={"green"}>{val.label}</Tag>;
                default: return <Tag>{val.label}</Tag>
            }
        },
    };

    // if (!time_machine) {
    //     columns.push({
    //         title: "Последний рассчитанный день", dateIndex: "last_billed_day", key: "last_billed_day",
    //         render: (val, rec) => <Tooltip title={rec.error_message}>
    //             <Tag color={rec.bill_status}>{val}</Tag>
    //         </Tooltip>,
    //     });
    // }

    function onCloseContragentModal() {
        setModalVisible(false);
    }

    function disabledRowClassName(record) {
        if (record.status.code === 2) {
            return "disabled-row";
        }
        return "";
    }

    return <>
        <Card
            title={title || "Контрагенты"}
            extra={
                <Space size={8}>
                    <ContragentCreateButton>Создать контрагента</ContragentCreateButton>
                </Space>
            }>
            <Fable model={model}
                   columns={columns}
                   renders={renders}
                   rowClassName={disabledRowClassName}
                   click={(rec) => {
                       history.push(`/contragents/${rec.id}`);
                   }}
                   actions={(record) => <Menu>
                       {record.status.code === 2 &&
                           <Popconfirm
                               placement="topRight"
                               title={"Вы уверены, что хотите восстановить контрагента?"}
                               onConfirm={() => {
                                   X.Contragent(record.id).restore().then(res => {
                                       message.success("Контрагент восстановлен");
                                       model.toggleTableUpdate();
                                   });
                               }}
                               okText="Да"
                               cancelText="Нет"
                           >
                               <Menu.Item key={"restore_contragent"}>
                                   Восстановить
                               </Menu.Item>
                           </Popconfirm>
                       }
                       {((record.status.code === 0) || (record.status.code === 1)) && <>
                           <Menu.Item key={"set_blocking"}
                                      onClick={() => {
                                          setTargetContragent(record);
                                          setModalBlock(true);
                                      }}>
                               Установить блокировку
                           </Menu.Item>
                           <Menu.Item key={"show_contragent"}
                                      onClick={() => {
                                          history.push(`/contragents/${record.id}`);
                                      }}>
                               Показать карточку контрагента
                           </Menu.Item>
                           <Popconfirm
                               placement="topRight"
                               title={"Вы уверены, что хотите удалить контрагента?"}
                               onConfirm={() => {
                                   X.Contragent(record.id).delete().then(res => {
                                       message.success("Контрагент удален");
                                       model.toggleTableUpdate();
                                   });
                               }}
                               okText="Да"
                               cancelText="Нет"
                           >
                               <Menu.Item danger key={"delete_contragent"}>
                                   Удалить
                               </Menu.Item>
                           </Popconfirm>
                           {/*{!time_machine &&*/}
                           <Menu.Item key={"calc_next_day"}
                                      onClick={() => {
                                          setTargetContragent(record);
                                          setTimeModal(true);
                                      }}>
                               Рассчитать следующий пропущенный день
                           </Menu.Item>
                           {/*}*/}

                       </>}
                       {menu?.(record)}
                   </Menu>}/>
        </Card>
        <ClientModal
            visible={modalVisible}
            title="Создание"
            onCancel={onCloseContragentModal}
        />
        <BlockingModal
            targetObject={targetContragent}
            onCancel={() => setModalBlock(false)}
            onSuccess={() => setModalBlock(false)}
            visible={isModalBlock}
        />
        <BillingManageModal visible={time_modal}
                            contragent={targetContragent}
                            onClose={() => setTimeModal(false)}/>

    </>;
}

export function DefaultContragentTable() {
    const model = useContragentData();

    return <BaseContragentTable model={model}/>;
}

export function EdoContragentTable() {
    const model = useEdoContragentData();

    const columns = [
        {
            title: "Наименование", dataIndex: "name", key: "name",
            render: (val, record) => (
                <Link
                    to={`/contragents/${record.id}`}
                    target="_blank"
                >
                    {val}
                </Link>
            ),
        },
        {
            title: "Статус", dataIndex: "status", key: "status",
            render: (val, record) => <Tag color={val?.color}>{val?.label}</Tag>,
        },
        {
            title: "Форма деятельности", dataIndex: "type", key: "type",
            // render: (val, record) => <Tag>{val}</Tag>
        },
        {
            title: "Последний рассчитанный день", dateIndex: "last_billed_day", key: "last_billed_day",
            render: (val, rec) => <Tooltip title={rec.error_message}>
                <Tag color={rec.bill_status}>{val}</Tag>
            </Tooltip>,
        },
        {
            title: "ЭДО подключено",
            dataIndex: "relation", key: "relation",
            render: val => (val) ? <Tag color={"green"}>Да</Tag> : <Tag color={"red"}>Нет</Tag>,
        },
        {
            title: "Статус ЭДО ДИАДОК",
            dataIndex: "edo_status", key: "edo_status"
        }
    ];

    return <BaseContragentTable title={"Контрагенты (ЭДО)"}
                                model={model}
                                columns={columns}
                                menu={record => <>
                                    <Menu.Item key={"refresh_edo_status"}
                                               onClick={async () => {
                                                   await X.EdoContragent(record.id).check_edo_status();
                                                   model.toggleTableUpdate();
                                               }}>
                                        Запросить статус ЭДО
                                    </Menu.Item>
                                </>}
    />;

}


export default function ContragentTable() {
    const {isEnabled, loading} = useIntegration();

    if (!loading) {
        if (isEnabled("edo.Edo"))
            return <EdoContragentTable/>;
        else
            return <DefaultContragentTable/>;
    } else
        return null;
}
