import {message, notification} from "antd";
import {ObjectService} from "./Object";
import BaseService from "./Base";


class OrderService extends ObjectService {
    MODEL = 'order'
    ITEMS = 'item'

    get_items() {
        return this._get("item")
    }

    createNewPackageOrder(data) {
        // Создание пакета
        return this._post('package', data).then(res => {
            notification['success']({
                message: 'Заказ №' + res.data.id + ' успешно создан',
                description: 'Для просмотра заказа перейдите в профиль контрагента в раздел "Заказы"'
            });
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    comply(data) {
        // Актирование заказа
        return this._post("comply", data).then(res => {
            message.success('Заказ завершен.')
            return Promise.resolve(res)
        }).catch(OrderService.create_error_handler("Нельзя завершить заказ"))
    }

    cancel(data) {
        // Отмена заказа
        return this._post('cancel', data).then(res => {
            message.success('Заказ отменён')
        }).catch(OrderService.create_error_handler("Нельзя отменить заказ"))
    }

    pay(data) {
        // Оплата заказа
        return this._post('pay-order', data).then(res => {
            message.success('Заказ оплачен')
        }).catch(OrderService.create_error_handler("Невозможно оплатить заказ"))
    }

    refund(data) {
        // Возврат ДС
        return this._post('refund', data)
                   .catch(OrderService.error_handler)
    }

    calculate(data) {
        // предварительный расчёт
        return this._post(`calculate`, data).then(res => {
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    calculatePackage(data) {
        // предварительный расчёт
        return this._post(`package/calculate`, data).then(res => {
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    changeStatusToProcessing() {
        return this._post(`status`).then(res => {
            message.success('Статус изменен на В процессе.')
            return Promise.resolve(res)
        }).catch(OrderService.error_handler);
    }

    listTransactions() {
        return this._get(`transactions`).then(res => {
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    changeItem(item_id, data) {
        return this._patch(`${this.ITEMS}/${item_id}`, data).then(res => {
            message.success('Данные изменены.')
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    deleteItem(item_id, data) {
        return this._delete(`${this.ITEMS}/${item_id}`, data).then(res => {
            message.success('Позиция удалена.')
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    setPromocode(data) {
        return this._post('discount', data).then(res => {
            message.success('Промокод применен');
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    clearDiscounts(data) {
        return this._delete('discount', data).then(res => {
            message.success('Скидки сброшены');
            return Promise.resolve(res)
        }).catch(OrderService.error_handler)
    }

    // get items() {
    //     const res = this._mk_internal_res();
    //
    //     function _parentize(srv) {
    //         srv.MODEL = `${res}/${srv.MODEL}`;
    //         return srv;
    //     }
    //
    //     return item => {
    //         const id = item?.id || item;
    //         return _parentize(new OrderItemService(item));
    //     };
    // }
}

class OrderItemService extends BaseService {
    MODEL = 'item'

    add_counter(data) {
        return this._post("counter", data).then(res => {
            return Promise.resolve(res)
        }).catch(OrderItemService.error_handler)
    }

    cost_counter() {
        return this._get("counter/cost")
    }

    history_list_counter() {
        return this._get("counter/history")
    }

}

class CartService extends BaseService {
    MODEL = 'cart'

    get_items() {
        return this._get()
    }

    add_item(data) {
        return this._post('item', data).then(res => {
            message.success('Позиция добавлена.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    delete_item(data) {
        const itemId = data['order_item_id']
        return this._delete(`item/${itemId}`).then(res => {
            message.success('Позиция удалена.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    change_item_count(data) {
        const itemId = data['order_item_id']
        return this._patch(`item/${itemId}`, data).then(res => {
            message.success('Количество изменено.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    add_package(data) {
        const packageId = data['package']
        return this._post(`package/${packageId}/increase`).then(res => {
            message.success('Пакет добавлен в корзину.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    decrease_package(data) {
        const packageId = data['package']
        return this._post(`package/${packageId}/decrease`).then(res => {
            message.success('Пакет удален из корзины.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    delete_package(data) {
        const packageId = data['package']
        return this._delete(`package/${packageId}`).then(res => {
            message.success('Пакет удален из корзины.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    convert_to_orders(data) {
        return this._post('convert_to_orders', data).then(res => {
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    transform_items_to_pre_orders() {
        return this._post('preview').then(res => {
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    delete_all_items() {
        return this._delete(`clear`).then(res => {
            message.success('Корзина очищена.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    pre_order_to_orders(data) {
        return this._post('confirm', data).then(res => {
            message.success('Заказы созданы.')
            return Promise.resolve(res)
        }).catch(CartService.error_handler)
    }

    checkPromocode(data) {
        return this._post('promocode', data).then(res => {
            return Promise.resolve(res)
        })
    }

    removePromocodes() {
        return this._delete('promocode').then(res => {
            return Promise.resolve(res)
        })
    }

}

export {
    OrderService,
    OrderItemService,
    CartService,
}
