import React, {useEffect, useState} from "react";
import {Form, Input, Select} from 'antd';
import {error_handler} from "app/ApiService/Base";
import {X} from "app/ApiService/Query";


export default function AttributeForm({onFinish, form, ...props}) {
    const [types, setTypes] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [enums, setEnums] = useState([]);

    function is_enum_selected() {
        return (form.getFieldValue('type') || '').includes('enum');
    }

    useEffect(() => {
        X.Attribute().getTypes().then(res => {
            setTypes(res.data.data)
        }).catch(err => error_handler(err));

        X.Measure().list().then(res => {
            setMeasures(res.data.data)
        }).catch(err => error_handler(err));

        if (is_enum_selected()) {
            loadEnums();
        }
    }, [])

    function _onFinish(data) {
        // const msr = measures.find(e => e.id === data.measure);
        const {measure, ..._data} = data;
        return onFinish({..._data, measure_id: measure});
    }

    function loadEnums(enumgroup) {
        X.EnumGroup(enumgroup).list().then(r => {
            setEnums(r.data.data);
        }).catch(err => {
            error_handler(err, "Не удалось получить список");
        });
    }

    function onSelect(d) {
        if (is_enum_selected()) {
            loadEnums();
        }
    }

    return <Form layout='vertical' onFinish={_onFinish} form={form} {...props}>
        <Form.Item name='name' label='Название' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='codename' label='codename' rules={[
            {required: true, message: 'Это поле необходимо заполнить'}
        ]}>
            <Input type='text'/>
        </Form.Item>
        <Form.Item name='type' label='тип данных' rules={[
            {required: false, message: 'Это поле необходимо заполнить'}
        ]}>
            <Select onSelect={onSelect}>
                {types.map(el => <Select.Option key={el.type} value={el.type}>{el.name}</Select.Option>)}
            </Select>
        </Form.Item>
        {is_enum_selected() &&
            <Form.Item name={'enum_group'} label={'Список с вариантами'}>
                <Select>
                    {enums.map(eg => <Select.Option key={eg.id} value={eg.id}>{eg.name}</Select.Option>)}
                </Select>
            </Form.Item>
        }
        {is_enum_selected() ||
            <Form.Item name='measure' label='тип измерения'>
                <Select>
                    {measures.map(el => {
                        return <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>
                    })}
                </Select>
            </Form.Item>}
        {/*<Form.Item name='is_required' label='обязательное поле?'>*/}
        {/*    <Switch />*/}
        {/*</Form.Item>*/}
    </Form>
}
