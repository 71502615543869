import {Button, Form, Modal, Popconfirm} from "antd";
import {X} from "src/ApiService/Query";
import {EditAttributesForm} from "src/Components/Common/Forms/AttributesForm";
import React, {useEffect, useState} from "react";
import CrossgradeServiceChangeForm, {
    ChangeProlongForm,
    CrossgradeServiceGroupChangeForm,
} from "../Forms/CrossgradeServiceChangeForm";


export default function SubscribeCrossgradeModal({
                                                     item,
                                                     service_item,
                                                     onSuccess,
                                                     group = false,
                                                     isAtolSpecial,
                                                     ...props
                                                 }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [conftext, setConftext] = useState("");

    function onSuccessForm(data) {
        if (!data.test)
            typeof onSuccess === "function" && onSuccess();
        else if (data.test) {
            if (data.scenario)
                setConftext(`Кроссгрейд идёт по сценнарию №${data.scenario}`);
            else
                setConftext(`Сценнарий кроссгрейда не определён`);

        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Modal {...props}
               destroyOnClose
               title="Смена тарифа подписки"
               width={800}
               footer={isAtolSpecial ?
                   <Popconfirm placement={"topRight"}
                               title={`${conftext}. Уверены, что хотите продолжить?`}
                               onConfirm={() => {
                                   form.setFields([{name: "test", value: false}]);
                                   form.submit();
                                   setLoading(true);
                               }}
                               onCancel={() => {
                                   setLoading(false);
                               }}
                               okText={"Да"}
                               onVisibleChange={visible => {
                                   if (visible) {
                                       form.setFields([{name: "test", value: true}]);
                                       form.submit();
                                       setLoading(true);
                                   } else
                                       setLoading(false);
                               }}
                               cancelText={"Нет"}>
                       <Button type="primary"
                               loading={loading}>
                           Подтвердить
                       </Button>
                   </Popconfirm> :
                   <Button type="primary"
                           onClick={() => {
                               form.submit();
                           }}>
                       Подтвердить
                   </Button>
               }>
            {(group) ?
                <CrossgradeServiceGroupChangeForm
                    form={form}
                    id="crossgrade_change_form"
                    item={item}
                    service_item={service_item}
                    isAtolSpecial={isAtolSpecial}
                    onFinish={onSuccessForm}/> :
                <CrossgradeServiceChangeForm
                    form={form}
                    id="crossgrade_change_form"
                    item={item}
                    service_item={service_item}
                    isAtolSpecial={isAtolSpecial} // явный ключ!
                    onFinish={onSuccessForm}/>
            }
        </Modal>
    );
}

// export function SubscribeGroupModal({item, service_item, onSuccess, ...props}) {
//     // модальное окно по информации кроссгрейдов
//
//     function onSuccessForm() {
//         typeof onSuccess === "function" && onSuccess();
//     }
//
//     return (
//         <Modal {...props} width={800}
//                destroyOnClose title="Выбор тарифа" footer={
//             <Button htmlType="submit" form="crossgrade_change_form" type="primary">Подтвердить</Button>
//         }>
//             <CrossgradeServiceGroupChangeForm
//                 id="crossgrade_change_form"
//                 item={item}
//                 service_item={service_item}
//                 isAtolSpecial={props.isAtolSpecial}
//                 onFinish={onSuccessForm}
//             />
//         </Modal>
//     );
// }

export function SubscribeProlongGroupModal({service, onFinish, ...props}) {
    // модальное окно по информации кроссгрейдов

    const onSuccessForm = () => {
        typeof onFinish === "function" && onFinish();
    };

    return (
        <Modal {...props}
               destroyOnClose
               title="Изменить дату продления"
               footer={
                   <Button htmlType="submit" form="change_group_prolong" type="primary">Подтвердить</Button>
               }>
            <ChangeProlongForm
                id="change_group_prolong"
                service={service}
                onFinish={onSuccessForm}
            />
        </Modal>
    );
}


export function SubscribeAttributesModal({service_item, onFinish, ...props}) {
    // модальное окно для изменения атрибутов

    const onFinishForm = () => {
        typeof onFinish === "function" && onFinish();
    };

    return (
        <Modal {...props} destroyOnClose title="Редактирование атрибутов" footer={
            <Button htmlType="submit" form="attributes_change_form" type="primary">Подтвердить</Button>
        }>
            <EditAttributesForm
                id="attributes_change_form"
                obj={service_item}
                contragent={service_item?.contract?.buyer}
                apisrv={X.Contragent(service_item?.contract?.buyer).Service(service_item)}
                onFinish={onFinishForm}
            />
        </Modal>
    );
}
