import BaseService from "./Base";

class CurrencyService extends BaseService {
    MODEL = "currency";
}

export default CurrencyService;
export {
    CurrencyService,
};
