import { Layout, Row, Col, Tag, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'

import { Card } from '../../Base'
import API_ from 'app/ApiService/Module'

export default function ModuleList () {
  const [data, setData] = useState([])
  const API = useMemo(() => new API_(), [])

  useEffect(() => {
    API.list((r) => {
      setData(r.data.data)
    })
  }, [API])

  return (
    <Layout.Content>
      <Card title='Маркетплейс'>
        <Row gutter={[16, 16]}>
          {data.map((v) => (
            <Col span={6} key={v.name}>
              <Card
                extra={
                  <>
                    <Tag color='green' visible={v.is_active}>Вкл</Tag>
                    <Tag color='magenta' visible={!v.is_active}>Выкл</Tag>
                  </>
                }
                title={<Link to={`modules/${v.name}`}>{v.verbose_name}</Link>}
              >
                <Link to={`modules/${v.name}`}>

                  {/* <Image src={v.image} preview={false} style={{maxWidth: '250px', heigth: '200px'}} /> */}
                  <div style={{
                      background: `url(${v.image})`, width: '150px', height: '150px',
                      backgroundSize: 'cover', margin: '0 auto', marginBottom: '1em'
                    }}>

                  </div>
                </Link>
                <Typography.Text
                  style={{ width: '100%' }}
                  ellipsis={{ tooltip: v.description }}>{v.description}</Typography.Text>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </Layout.Content>
  )
}
