import Modal from "app/Base/Modals/Modal";
import React, {useEffect} from "react";
import {Form, message, notification} from "antd";

import NomenclatureForm from "../Forms/NomenclatureForm";
import useUser from "src/Providers/UserProvider";
import {X} from "app/ApiService/Query";


export default function NomenclatureModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();
    const {contragent} = useUser();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                uuid: data.uuid,
                name: data.name,
                code: data.code,
                description: data.description,
                pp_product: data.pp_product,
            });
        } else
            form.resetFields();
    }, [data]);

    function editAttribute(d) {
        if (d.code === data.code)
            delete d.code;

        d.contragent = props.contragent?.external_id || props.contragent?.id;

        X.Contragent(contragent).Nomenclature(data.uuid).update(d).then(res => {
            message.success("Номенклатура изменена");
            props.toggle();
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить номенклатуру",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message,
            });
        });
    }

    function createAttribute(d) {
        d.contragent = props.contragent?.external_id || props.contragent?.id;
        X.Contragent(contragent).Nomenclature().create(d).then(res => {
            message.success("Номенклатура создана");
            props.toggle();
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить номенклатуру",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message,
            });
        });
    }

    function execute(d) {
        if (data) {
            editAttribute(d);
        } else {
            createAttribute(d);
        }
        onSuccess();
    }

    return <Modal {...props}
                  title={`${data?"Редактировать":"Создать"} Номенклатуру`}
                  onSuccess={onSuccess}
                  form="manage_nomenclature"
                  btnname={"Сохранить"}>
        <NomenclatureForm form={form} id="manage_nomenclature" onFinish={(d) => execute(d)}/>
    </Modal>;
}
