import {Filter} from "./Types";
import {FableModalButton} from "./FableModal";
import {loadFromLS, saveToLS} from "./SaveLoad";
import {SearchDataTable} from "app/Base/Tables/SearchTable";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useToggle} from "react-use";
import {ColumnFactory, OrderBy, View} from "./Types";
import PropTypes from "prop-types";
import {Button, Dropdown, Select, Space} from "antd";
import {DownloadOutlined, LoadingOutlined, MoreOutlined, RightOutlined} from "@ant-design/icons";
import {X} from "app/ApiService/Query";


export function FilterPanel({title}) {
    const {
        current, viewsets, setCurrent,
        model,
    } = useFable();
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [loadingXLSX, setLoadingXLSX] = useState(false);

    function onSelect(val) {
        setCurrent(val);
    }


    function onClickCSV() {
        if (!loadingCSV) {
            setLoadingCSV(true);
            model.saveAsCSV().finally(x => setLoadingCSV(false));
        }
    }

    function onClickXLSX() {
        if (!loadingXLSX) {
            setLoadingXLSX(true);
            model.saveAsXLSX().finally(x => setLoadingXLSX(false));
        }
    }

    return <>
        <Space>
            <Select onChange={(e) => onSelect(e)}
                    style={{minWidth: 200}}
                    value={current?.name}>
                {viewsets && viewsets.map(f => {
                        return <Select.Option key={f.name} value={f.name}>
                            {f.name} {f.default && "По умолчанию"}
                        </Select.Option>;
                    },
                )}
            </Select>
            <FableModalButton/>
            <Button icon={loadingCSV ? <LoadingOutlined/> : <DownloadOutlined/>}
                    onClick={onClickCSV}>
                csv
            </Button>
            <Button icon={loadingXLSX ? <LoadingOutlined/> : <DownloadOutlined/>}
                    onClick={onClickXLSX}>
                xlsx
            </Button>
            {title && typeof title == "function" && title()}
        </Space>

    </>;
}


const FableContext = createContext();

export function Fable({
                          model,
                          actions,
                          click,
                          columns: excols,
                          renders,
                          title,
                          extra,
                          onUpdate,
                          expandable = false,
                          ...props
                      }) {
    const [index, setIndex] = useState(0);
    const [ti, setTi] = useState(0);
    const [viewsets, setViewsets] = useState([]);
    const {options, setView} = model;

    const [_save, save] = useToggle();

    let current = viewsets[index];

    const columns = current?.columns?.map(x => ColumnFactory(options).Column(x));
    const filters = current?.filters?.map(x => Filter.create(x));

    const [orderby, _setOrderby] = useState(current?.orderby || new OrderBy());

    useEffect(async () => {
        const fs = X.Fable(model.ModelName);
        const vs_data = (await fs.load()).data;
        const viewname = loadFromLS(model.ModelName);

        if (vs_data?.length) {
            setViewsets(vs_data.viewsets);
            const v = vs_data.viewsets.find(x => x.name == viewname);
            const i = vs_data.viewsets.indexOf(v);
            setTi(i);
        } else {
            setViewsets([View.DEFAULT_VIEW]);
            setTi(0);
        }
    }, []);

    useEffect(() => {
        if (current) {
            setColumns(current.columns);
            setFilters(current.filters);
            setOrderby(current.orderby);
            setView(current);
        }
    }, [current]);

    useEffect(() => {
        if (viewsets[ti])
            setIndex(ti);
    }, [viewsets, ti]);

    useEffect(async () => {
        if (_save !== undefined && viewsets?.length) {
            const fsrv = X.Fable(model.ModelName);
            model.toggleTableUpdate();
            await fsrv.save({viewsets: viewsets, current: index});
        }
    }, [_save]);

    function _build_columns() {
        const immucols = [{
            dataIndex: "collapse",
            key: "collapse",
            width: 5,
        }];

        if (actions) {
            immucols.push({
                dataIndex: "actions",
                render: (_, record) => (
                    <Space>
                        <Dropdown overlay={() => (typeof actions === "function") ? actions(record) : null}
                                  trigger={["click"]} placement="bottomRight">
                            <Button size="small" icon={<MoreOutlined/>}/>
                        </Dropdown>
                        {click && typeof click === "function" &&
                            <Button size={"small"}
                                    icon={<RightOutlined/>}
                                    onClick={() => click?.(record)}/>}
                    </Space>
                ),
                width: 30,
            });
        }

        if (current && current.columns?.length) {
            return immucols.concat(current.columns.map(col => {
                const exc = (excols || []).find(exc =>
                    (exc.dataIndex == col.name) ||
                    (exc.key == col.name));
                if (exc) {
                    return ColumnFactory(options).Column(exc, click);
                }

                return ColumnFactory(options).Column(col, click);
            }));
        } else if (excols?.length) {
            return (immucols.concat(
                excols.map(x => {
                    const mtd_cln = (options?.columns || columns || []).find(m => (
                        (m.key || m.dataIndex) == (x.key || x.dataIndex))) || x;
                    if (x.render && typeof x.render === "function")
                        mtd_cln.render = x.render;
                    return ColumnFactory(options).Column(mtd_cln, click);
                }),
            ));
        } else {
            return (immucols);
        }
    }

    function _build_columns_additional() {
        if (!renders)
            return _build_columns();

        return _build_columns().map(c => {
            const rndr = renders[c?.key || c?.dataIndex || c?.name];
            if (rndr && typeof rndr === 'function')
                c.render = rndr;
            return c;
        });
    }

    function setOrderby(orderby) {
        if (orderby)
            _setOrderby(new OrderBy(orderby));
    }

    function setColumns(cols) {
    }

    function setFilters(fltrs) {
    }

    function setCurrent(view) {

        let x, view_obj;
        switch (typeof view) {
            case "number":
                x = view;
                break;
            case "object":
                x = viewsets.indexOf(
                    viewsets.find(x => x.name == view.name));
                break;
            case "string":
                x = viewsets.indexOf(
                    viewsets.find(x => x.name == view));
                break;
        }
        setTi((x < 0) ? viewsets.length : x || 0);

        if (view != current.view) {
            view_obj = viewsets[x];
            saveToLS(model.ModelName, view_obj.name);
        }
    }

    function updateCurrent(view) {
        const view_obj = new View(view);
        let vsts = [...viewsets];

        vsts[index] = view_obj;
        setViewsets(vsts);
    }

    function setDefault() {
        setViewsets(viewsets.map(x => ({...x, "default": false})));
        updateCurrent({...current, "default": false});
    }

    function deleteViewset(viewset) {
        setIndex(0);
        setViewsets(viewset.filter(x => viewset.name != x.name));
    }

    function clearViewsets() {
        setViewsets([]);
    }

    function addViewset(view) {
        const vs = viewsets.find(x => x.name == view?.name);

        if (!vs) {
            let vsts = [...viewsets];

            vsts.push(View.NEW_VIEW);
            setViewsets(vsts);

            return vs;
        } else {
            const i = viewsets.indexOf(vs);
            return vs;
        }
    }

    function updateFilter(flt, i) {
        const flt_obj = Filter.create(flt);
        let flts = [...filters];

        if (typeof i === "number")
            flts[i] = flt_obj;
        else {
            const ii = flts.findIndex(x => (flt.column == x.column && flt.operator == x.operator));
            flts[ii] = flt_obj;
        }
        setFilters(flts);
    }

    function removeFilter(flt) {
        setFilters(filters.filter(x => x.column == flt.column && x.operator == flt.operator));
    }

    const values = useMemo(() => ({
        // variables
        viewsets, current, columns, filters, orderby, model, excols,
        updateFable: model.updateFable,
        // functions
        toggleFable: model.toggleTableUpdate,
        //viewsets
        setCurrent, setDefault, updateCurrent, deleteViewset, save, clearViewsets,
        addViewset,
        //columns
        setColumns,
        //filters
        setFilters, updateFilter, removeFilter,
        //orderby
        setOrderby,
    }), [viewsets, current, columns, filters, orderby, model]);

    return <FableContext.Provider value={values}>
        <SearchDataTable model={model}
                         columns={_build_columns_additional()}
                         title={() => <FilterPanel/>}
                         additional_title={title}
                         toggle={() => {
                             model.toggleTableUpdate();
                             onUpdate?.();
                         }}
                         extra={extra}
                         expandable={expandable}
                         {...props}/>
    </FableContext.Provider>;
}

export function useFable() {
    return useContext(FableContext);
}

Fable.propTypes = {
    model: PropTypes.object.isRequired,
    actions: PropTypes.func,
    click: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    renders: PropTypes.objectOf(PropTypes.func),
    title: PropTypes.func,
    extra: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onUpdate: PropTypes.func,
    expandable: PropTypes.bool
};
