import {X} from "app/ApiService/Query";


const LS_KEY = "flexible_views_set";
const DEFAULT = "Default";


export function clearForModel(model) {
    let ls = JSON.parse(localStorage.getItem(LS_KEY)) || {[model]: {}};
    delete ls[model];
    localStorage.setItem(LS_KEY, JSON.stringify(ls));
}

/**
 *
 * @param model {string}
 * @returns {string}
 */
export function loadFromLS(model) {
    if (!model) throw "model is required argument";

    try {
        const ls = JSON.parse(localStorage.getItem(LS_KEY));
        return (ls || {})[model] || DEFAULT;
    } catch (e) {
        return DEFAULT;
    }
}

/**
 *
 * @param model {string} {required}
 * @param view {string} {required}
 */
export function saveToLS(model, view) {
    if (!model) throw "model is requred argument";

    let ls = JSON.parse(localStorage.getItem(LS_KEY)) || {[model]: DEFAULT}; // берём весь объект
    ls[model] = view;
    localStorage.setItem(LS_KEY, JSON.stringify(ls)); // записываем изменённый
}


export function loadFromDb(model) {
    if (!model)
        throw "model is required argument";

    // const fsrv = new FlexibleService(model);
    const fsrv = X.Fable(model);

    return fsrv.load();
}

export function saveToDb(model, data) {
    if (!model) throw "model is requred argument";

    // const fsrv = new FlexibleService(model);
    const fsrv = X.Fable(model);
    return fsrv.save(data);

    // let ls = JSON.parse(localStorage.getItem(LS_KEY)) || {[model]: {}}; // берём весь объект
    // ls[model] = data;
    // localStorage.setItem(LS_KEY, JSON.stringify(ls)); // записываем изменённый
}
