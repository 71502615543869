import React, {useEffect, useState} from "react";
import {useToggle} from "react-use";
import {Button, message, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

import useUser from "../../../Providers/UserProvider";

import {BlockingTypeModal} from '../Modals/BlockingTypeModal'
import {X} from "app/ApiService/Query";


export default function BlockingTypeTable(){
    const {is_permitted} = useUser();
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(null);
    const [tgl, toggle, setValue] = useToggle(false);

    const {contragent} = useUser()

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined />} danger ghost onClick={() => {
                            X.BlockingType(record.id).delete().then(res => {
                                message.success('Тип удалён');
                                setEdited(null);
                                toggle(!tgl)
                            }).catch(err => {
                                message.error('Возникла ошибка');
                            })
                        }} />
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined />} onClick={() => {
                            setEdited(record);
                            setVisible(true);
                            toggle(!tgl)
                        }} />
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        if (!visible) {
            X.BlockingType().list({params: {contragent: contragent.id}}).then(res => {
                setList(res.data.data)
            })
        }
    }, [tgl])

    return <>
        {is_permitted('application.attribute.create') &&
            <Button type="primary" onClick={() => setVisible(true)} style={{marginBottom: '1em'}}>Создать</Button>
        }
        <Table size='small' dataSource={list} columns={columns} pagination={false} />
        <BlockingTypeModal
            data={edited}
            visible={visible}
            destroyOnClose
            onCancel={() => {setVisible(false); setEdited(null)}}
            onSuccess={() => {setVisible(false); setEdited(null)}}
            contragent={contragent}
            toggle={toggle}
        />
    </>

}
