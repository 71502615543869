import React, {useEffect, useState} from "react";
import {Button, message, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import EnumGroupModal from "../Modals/EnumGroupModal";
import {useToggle} from "react-use";
import useUser from "src/Providers/UserProvider";
import {X} from "app/ApiService/Query";


export default function EnumGroupTable() {
    const {is_permitted} = useUser();
    const [groups, setGroups] = useState([]);
    const [visible, setVisible] = useState(false);
    const [edited, setEdited] = useState(null);
    const [tgl, toggle] = useToggle(false);


    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Значения',
            dataIndex: 'values',
            key: 'values',
            render: (val, record) => (val.map(v => v.value).join(", "))
        },
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined/>} danger ghost onClick={() => {
                            X.EnumGroup(record.id).delete().then(res => {
                                message.success('Словарь удалён');
                                toggle();
                            }).catch(err => {
                                message.error('Возникла ошибка');
                            })
                        }}/>
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined/>} onClick={() => {
                            X.EnumGroup(record.id).get().then(r=>{
                                setEdited(r.data);
                                setVisible(true);
                            })
                        }}/>
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        X.EnumGroup().list().then(res => {
            setGroups(res.data.data)
        })
    }, [tgl])

    function onCreateEnumGroup(data) {
        setVisible(false);
        setEdited(null);
        toggle();
    }

    return <>
        {is_permitted('application.attribute.create') &&
            <Button type="primary" onClick={() => {
                setEdited(null);
                setVisible(true);
            }} style={{marginBottom: '1em'}}>Создать</Button>
        }
        <Table size='small' dataSource={groups} columns={columns} pagination={false}/>
        <EnumGroupModal
            data={edited}
            visible={visible}
            destroyOnClose
            onCancel={() => {
                setVisible(false);
                setEdited({});
                toggle();
            }}
            onSuccess={onCreateEnumGroup}
        />
    </>

}
