import React, {useEffect} from "react";
import {Button, Form, message, Modal, notification} from 'antd'

import BlockingTypeForm from "../Forms/BlockingTypeForm";
import {X} from "app/ApiService/Query";


export function BlockingTypeModal({data, onSuccess, contragent, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                id: data.id,
                name: data.name,
            });
        } else
            form.resetFields();
    }, [data])

    function editAttribute(d) {
        if (d.code === data.code)
            delete d.code
        d.contragent = contragent?.id

        X.BlockingType(data.id).update(d).then(res => {
            message.success('Тип блокировки изменен');
            props.toggle()
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить тип блокировки",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function createAttribute(d) {
        d['contragent'] = contragent?.id
        X.BlockingType().create(d).then(res => {
            message.success('Тип блокировки создан');
            props.toggle()
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить тип блокировки",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message
            })
        })
    }

    function execute(d) {
        if (data) {
            editAttribute(d);
        } else {
            createAttribute(d);
        }
        onSuccess();
    }

    return <Modal {...props} onSuccess={onSuccess} onOk={onSuccess} footer={
        <Button type="primary" htmlType="submit" form="manage_blockingtype">Сохранить</Button>
    }>
        <BlockingTypeForm form={form} id='manage_blockingtype' onFinish={(d) => execute(d)}/>
    </Modal>
}
