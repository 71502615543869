import {EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Card, Checkbox, DatePicker, Form, Input, List, Radio, Select, Space, Tag, Typography} from "antd";
import React, {useEffect, useState} from "react";
import Modal from "src/Base/Modals/Modal";
import useRule from "src/Providers/RuleProvider";
import useUser from "src/Providers/UserProvider";
import {X} from "app/ApiService/Query";


const GREEN_COLOR = "#E0FFD0";
const BLUE_COLOR = "#E0F0FF";
const WHITE_COLOR = "#FFFFFF";
const RED_COLOR = "#FFE0E0";

function interpolate(t, c) {
    return t.replace(/\${([^}]+)}/g, (m, p) => p.split('.').reduce((a, f) => a ? a[f] : undefined, c) ?? '');
}

function InterConditionalType(value) {
    switch (value) {
        case 'and':
            return 'и'
        case 'or':
            return 'или'
        default:
            return '-'
    }
}

function getMetaConditions(expressions, model) {

    function reduce(a,b) {
        return Object.keys({...a, ...b}).map((k) => {
            const ak = a[k], bk = b[k];

            if (typeof bk == 'string' || typeof ak == 'string') {
                return {}
            } else if (typeof ak == 'object' || typeof bk == 'object') {
                return {[k]: {...ak, ...bk}};
            } else
                return {[k]: ak||bk};
        }).reduce((a,b) => ({...a, ...b}));
    }

    if (expressions && Array.isArray(expressions)) {
        return expressions
            .filter(el => el.model === model)
            .map(x => x)
            .reduce(reduce, {})
    } else
        return {};
}

export default function RuleCard({...props}) {

    const {form, rule, event, setRule, edited, setEdited} = useRule();
    // const {contragent} = useContragent();
    const {contragent} = useUser();
    const [color, setColor] = useState(WHITE_COLOR);

    useEffect(() => {
        if (edited) {
            if (rule?.id)
                setColor(BLUE_COLOR)
            else
                setColor(GREEN_COLOR)
        }
        else {
            setColor(WHITE_COLOR)
        }
    }, [edited, rule])

    useEffect(() => {
        setRule(null);
    }, [contragent]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({type: rule?.type, contragent_id: contragent?.id, event: rule?.event});
        if (rule?.id) {
            setEdited(false);
            rule?.fetch_detail().then(res => {
                res.data.conditions = res.data.conditions.map(el => {
                    return {
                        id: el?.id,
                        field: `${el?.field.entity}.${el?.field.field}`,
                        operator: el.operator,
                        value: el?.value?.val,
                        interconditional_operator: el.interconditional_operator
                    }
                })
                res.data.contragent_id = res.data.contragent;
                res.data.actions_if = res.data.actions.filter(el => el.branch === 'if');
                res.data.actions_else = res.data.actions.filter(el => el.branch === 'else');
                res.data.actions_finally = res.data.actions.filter(el => el.branch === 'finally');
                form.setFieldsValue(res.data);
            })
        } else setEdited(true);
    }, [rule])

    if (!rule || !event) {
        return null;
    }

    function onFinishRule(data) {
        let actions_if = data?.actions_if || [];
        delete data?.actions_if;
        let actions_else = data?.actions_else || [];
        delete data?.actions_else;
        let actions_finally = data?.actions_finally || [];
        delete data?.actions_finally;

        data['actions'] = [...actions_if, ...actions_else, ...actions_finally];
        data['contragent_id'] = data?.contragent_id ? data?.contragent_id : contragent?.id;

        X.Contragent(contragent).Rule(data?.id).update_or_create(data).then(res => {
            // setRule(null);
            rule.id = res.data.id;
            setRule(rule);
            setEdited(false);
            form.setFieldsValue({id: res.data.id});
            typeof props.onFinish === 'function' && props.onFinish(data);
        })
    }

    return (
        <Card title={rule?.id ? `${event?.name}` : `новое правило: ${event?.name}`}
              // style={{marginLeft: '1em', backgroundColor: (rule.id)?WHITE_COLOR:GREEN_COLOR}}
              style={{marginLeft: '1em', backgroundColor: color}}
              extra={
                <Space>
                    <Button type='primary' form="rule_form" htmlType='submit'>Сохранить</Button>
                    <Button type='danger' onClick={() => setRule(null)}>Закрыть</Button>
                    {/* <Button icon={<CloseOutlined />} onClick={onClose}></Button> */}
                </Space>
        }>
            <Form form={form} layout='vertical' id='rule_form' onValuesChange={() => setEdited(true)} onFinish={onFinishRule}>
                <Card size='small' title='общее' style={{marginBottom: '1em'}}>
                    <Form.Item name='id' label='идентификатор правила' hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='contragent_id' label='идентификатор контрагента' hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='type' label='тип правила (до/после)' hidden rules={[
                        {required: true, message: 'необходимое поле для заполнения'}
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='event' label='событие' hidden rules={[
                        {required: true, message: 'необходимое поле для заполнения'}
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='name' label='наименование правила' rules={[
                        {required: true, message: 'необходимое поле для заполнения'}
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='description' label='описание правила'>
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item name='priority' label='приоритет'
                               tooltip='если приоритет равнозначный, то выполняется в порядке даты создания правила'
                               initialValue={1}
                               rules={[
                                   {required: true, message: 'необходимое поле для заполнения'}
                               ]}
                    >
                        <Input type="number" min={1}/>
                    </Form.Item>
                    <Form.Item name='enabled' label='включить правило?' initialValue={true} rules={[
                        {required: true, message: 'необходимое поле для заполнения'}
                    ]}>
                        <Radio.Group size="small">
                            <Radio.Button value={true}>да</Radio.Button>
                            <Radio.Button value={false}>нет</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Card>
                <Card size='small' title='если'>
                    <FormListCondition/>
                    <Card size='small' title='выполнить' style={{marginBottom: '1em'}}>
                        <FormListAction branch={'if'}/>
                    </Card>
                    {/* эти секции пока не работают, чтобы не вводить в заблуждение */}
                    {/* <Card size='small' title='иначе' style={{marginBottom: '1em'}}>
                        <FormListAction branch={'else'}/>
                    </Card>
                    <Card size='small' title='в любом случае'>
                        <FormListAction branch={'finally'}/>
                    </Card> */}
                </Card>
            </Form>
        </Card>
    )
}


function FormListCondition({...props}) {
    const {form, expressions, operators, setEdited} = useRule();
    const [conditionModal, setConditionModal] = useState({visible: false});
    const [opermap, setOpermap] = useState({});

    useEffect(() => {
        setOpermap(Object.fromEntries(operators.map(o => ([o[0], o[1]]))));
    }, [operators]);

    function conditionalType(value){
        return opermap[value] || value;
    }

    return (
        <Form.List name={`conditions`} {...props}>
            {(fields, {add, remove}) => {
                let conditions = form.getFieldsValue().conditions || [];

                function onAddOrEdit(data) {
                    if (conditionModal?.action === 'edit') {
                        remove(conditionModal?.index);
                        add(data, conditionModal?.index);
                    } else {
                        add(data);
                    }
                    setConditionModal({visible: false});
                    setEdited(true);
                }

                return (
                    <>
                        {!!fields?.length && expressions?.length && conditions?.length &&
                        <List
                            size='small'
                            bordered
                            style={{marginBottom: '1em'}}
                            dataSource={fields}
                            renderItem={field => {
                                let condition = conditions[field.name];
                                let [model, fld] = String(condition.field).split('.')
                                let meta_condition = getMetaConditions(expressions, model).fields;
                                let meta_condition_attributes = getMetaConditions(expressions, model).attributes;
                                let input = meta_condition[fld]?.input || meta_condition_attributes[fld]?.input;
                                return (
                                    <List.Item actions={[
                                        <Typography.Text type='secondary' >{condition?.id ? `id: ${condition.id}` : null}</Typography.Text>,
                                        <Typography.Text code>{InterConditionalType(condition.interconditional_operator)}</Typography.Text>,
                                        <EditOutlined onClick={() => setConditionModal({visible: true, index: field.name, item: condition, action: 'edit'})}/>,
                                        <MinusCircleOutlined onClick={() => {
                                            remove(field.name);
                                            setEdited(true);
                                        }}/>
                                    ]}>
                                        <Space style={{display: 'flex'}} align="baseline">
                                            <Typography.Text code strong>{input?.label}</Typography.Text>
                                            <Typography.Text>{conditionalType(condition.operator)}</Typography.Text>
                                            <Typography.Text code>{String(condition.value)}</Typography.Text>
                                        </Space>
                                    </List.Item>
                                )
                            }}
                        />}
                        <Form.Item>
                            {/* <ActionConfirm actions={available_actions} onConfirm={(v) => add(v)}> */}
                            <Button type="dashed" block icon={<PlusOutlined/>} onClick={() => setConditionModal({visible: true, action: 'add'})}>
                                добавить условие
                            </Button>
                            <ConditionModal
                                visible={conditionModal?.visible}
                                item={conditionModal?.item}
                                onCancel={() => setConditionModal({visible: false})}
                                onConfirm={(res) => onAddOrEdit({...res})}
                                destroyOnClose
                            />
                            {/* </ActionConfirm> */}
                        </Form.Item>
                    </>)
            }}
        </Form.List>
    )
}

function ConditionModal({item, children, onConfirm, ...props}) {
    // модальное окно по работе с условиями

    const {expressions, operators, inputs} = useRule();
    const [field, setField] = useState(null);
    const [operator, setOperator] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        setField(null);

        if (item) {
            let [model, fld] = String(item.field).split('.');
            let meta_condition = getMetaConditions(expressions, model).fields;
            let meta_condition_attributes = getMetaConditions(expressions, model).attributes;
            setField(meta_condition[fld] || meta_condition_attributes[fld]);
            setOperator(item?.operator);
            form.setFieldsValue(item);
        }
    }, [item])

    function onFinishRule(data) {
        typeof onConfirm === 'function' && onConfirm(data);
    }

    function onChangeConditional(value) {
        let [model, fld] = String(value).split('.');
        let meta_condition = getMetaConditions(expressions, model).fields;
        let meta_condition_attributes = getMetaConditions(expressions, model).attributes;
        setField(meta_condition[fld] || meta_condition_attributes[fld]);
    }

    function onChangeOperator(value) {
        setOperator(value);
    }

    return (
        <Modal {...props} title={'добавить условие'} footer={
            <Button type='primary' htmlType='submit' form='add_condition'>Добавить</Button>
        }>
            <Form form={form} layout='vertical' id='add_condition' onFinish={onFinishRule}>
                <Form.Item name='id' hidden>
                    <Input size='small'/>
                </Form.Item>
                <Form.Item name='field' label='поле' rules={[{required: true, message: 'пропущено поле'}]}>
                    <SelectConditionalField size='small' placeholder="поле" expressions={expressions} onChange={onChangeConditional}/>
                </Form.Item>
                {field &&
                <Form.Item name='operator' label='условие' rules={[{required: true, message: 'пропущено условие'}]}>
                    {/*<ConditionOperatorDataSelect input={field?.input} onChange={onChangeOperator}/>*/}
                    <ConditionOperatorSelect input={field?.input}
                                             operators={operators}
                                             inputs={inputs}
                                             onChange={onChangeOperator}/>
                </Form.Item>
                }
                {operator &&
                <Form.Item name='value' label='значение' rules={field?.input.required ? [{required: true, message: 'пропущено значение'}] : []}>
                    <GenerateInput size='small' placeholder="значение" condition={operator} input={field?.input}/>
                </Form.Item>
                }
                <Form.Item name='interconditional_operator' label='межусловный оператор' initialValue={'and'} hidden>
                    <Select size='small' placeholder="оператор" initialValue={'and'}>
                        <Select.Option value={'and'}>и</Select.Option>
                        <Select.Option value={'or'}>или</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

function FormListAction({branch, ...props}) {
    const {form, actions, setEdited} = useRule();
    const [actionModal, setActionModal] = useState({visible: false});

    return (
        <Form.List name={`actions_${branch}`} {...props}>
            {(fields, {add, remove}) => {
                let acts = form.getFieldsValue() || {};
                let action_name = `actions_${branch}`

                function onAddOrEdit(data) {
                    if (actionModal?.action === 'edit') {
                        remove(actionModal?.index);
                        add(data, actionModal?.index);
                    } else {
                        add(data);
                    }
                    setActionModal({visible: false});
                    setEdited(true);
                }

                return (
                    <>
                        {!!fields?.length && actions?.length && acts.hasOwnProperty(action_name) &&
                        <List
                            size='small'
                            bordered
                            style={{marginBottom: '1em'}}
                            dataSource={fields}
                            renderItem={field => {
                                let action = acts[action_name][field.name];
                                let meta_action = actions?.filter(act => acts[action_name][field.name].action === act.name).pop()

                                let interpolate_wrapper = {...acts[action_name][field.name].params}
                                Object.entries(interpolate_wrapper).map(el => {
                                    interpolate_wrapper[el[0]] = `${el[1]}`
                                    return el
                                })

                                return (
                                        <List.Item actions={[
                                            <Typography.Text type='secondary' >{action?.id ? `id: ${action.id}` : null}</Typography.Text>,
                                            <EditOutlined onClick={() => setActionModal({visible: true, item: action, index: field.name, action: 'edit'})}/>,
                                            <MinusCircleOutlined onClick={() => {
                                                remove(field.name);
                                                setEdited(true);
                                            }}/>
                                        ]}>
                                            <Space style={{display: 'flex'}} align="baseline">
                                                <Typography.Text strong delete={meta_action?.deprecated}>{meta_action?.verbose_name}</Typography.Text>
                                                {!!meta_action?.placeholder &&
                                                    <Typography.Text code>
                                                        {acts[action_name][field.name].title}{interpolate(meta_action?.placeholder, interpolate_wrapper)}
                                                    </Typography.Text>
                                                }
                                                <Tag color={meta_action?.sync ? 'green' : 'blue'}>
                                                    {meta_action?.sync ? 'синхронный' : 'асинхронный'}
                                                </Tag>
                                                {meta_action?.deprecated &&
                                                    <Tag color={'red'}>
                                                        Устарело
                                                    </Tag>
                                                }
                                            </Space>
                                        </List.Item>
                                )
                            }}
                        />}
                        <Form.Item>
                            {/* <ActionConfirm actions={available_actions} onConfirm={(v) => add(v)}> */}
                            <Button type="dashed" block icon={<PlusOutlined/>} onClick={() => setActionModal({visible: true, action: 'add'})}>
                                добавить действие
                            </Button>
                            <ActionConfirmModal
                                visible={actionModal?.visible}
                                item={actionModal?.item}
                                onCancel={() => setActionModal({visible: false})}
                                onConfirm={(res) => onAddOrEdit({...res, branch: branch})}
                                destroyOnClose
                            />
                            {/* </ActionConfirm> */}
                        </Form.Item>
                    </>)
            }}
        </Form.List>
    )
}

function ActionConfirmModal({item, children, onConfirm, ...props}) {

    const {actions} = useRule();
    const [actionValue, setActionValue] = useState(null);
    const [form] = Form.useForm();

    function confirm(data) {
        data['placeholder'] = actionValue?.placeholder
        typeof onConfirm === 'function' && onConfirm(data);
        setActionValue(null);
    }

    useEffect(() => {
        form.resetFields();
        setActionValue(null);
        if (item) {
            let meta_action = actions?.filter(act => item?.action === act.name).pop()
            setActionValue(meta_action);
            form.setFieldsValue(item);
        }
    }, [item])

    return (
        <Modal {...props} title={'добавить действие'} footer={
            <Button disabled={!actionValue} type='primary' htmlType='submit' form='add_action'>Добавить</Button>
        }>
            <Form form={form} layout='vertical' id='add_action' onFinish={confirm}>
                <Form.Item name='id' hidden>
                    <Input size='small'/>
                </Form.Item>
                <Form.Item name='action'>
                    <Select size='small' placeholder="действие" onSelect={(_, o) => setActionValue(o)}>
                        {actions?.map(el => {
                            return (
                                <Select.Option key={el?.name} value={el?.name} placeholder={el?.placeholder} sync={el?.sync} fields={el?.fields}>
                                    {el?.verbose_name}
                                </Select.Option>)
                        })}
                    </Select>
                </Form.Item>
                {actionValue &&
                Object.entries(actionValue?.fields).map((attr, i) => {
                    return (
                        <Form.Item
                            key={i}
                            name={['params', attr[1]?.input?.name]}
                            label={attr[1]?.input?.label}
                            tooltip={attr[1]?.input?.help_text}
                            rules={[
                                {required: attr[1]?.input?.required, message: 'поле пропущено'}
                            ]}>
                            <GenerateInput
                                input={attr[1]?.input}
                                size='small'
                            />
                        </Form.Item>
                    )
                })
                }
            </Form>
        </Modal>
    )
}

export function SelectConditionalField({expressions, onSelectInput, ...props}) {

    function extractConditions() {
        let options = []

        expressions?.map(root => {
            let model_fields = Object.entries(root.fields).map((el) => {
                let field = el[0];
                let attrs = el[1];
                let value = `${root.model}.${field}`;
                return {
                    value: value,
                    name: attrs?.input?.label,
                    input: attrs?.input,
                    label: <Typography.Text>{attrs?.input?.label} - <Typography.Text code>{value}</Typography.Text></Typography.Text>,
                    use_fields: attrs?.use_fields,
                    use_function: attrs?.use_function
                }
            })
            let model_attributes = []
            if (root?.attributes) {
                model_attributes = Object.entries(root?.attributes).map((el) => {
                    let field = el[0]
                    let attrs = el[1]
                    let value = `${root.model}.${field}`;
                    return {
                        value: value,
                        name: attrs?.input?.label,
                        input: attrs?.input,
                        label: <Typography.Text>{attrs?.input?.label} - <Typography.Text code>{value}</Typography.Text></Typography.Text>,
                        use_fields: true,
                        use_function: attrs?.use_function
                    }
                })
            }
            options.push({
                label: `поля ${root.model}`,
                options: model_fields
            })
            if (model_attributes?.length) {
                options.push({
                    label: `поля атрибутов ${root.model}`,
                    options: model_attributes
                })
            }

            return root;
        })
        return options;
    }

    return (<Select {...props}
        showSearch
        optionFilterProp="children"
        onSelect={(v, e) => {
            typeof onSelectInput === 'function' && onSelectInput(e.input);
            typeof props.onSelect === 'function' && props.onSelect(v, e);
        }}
        filterOption={(input, option) => (option?.name ?? '').includes(input) || (option?.value ?? '').includes(input)}
        filterSort={(a, b) =>
            (a?.name ?? '').toLowerCase().localeCompare((b?.name ?? '').toLowerCase())
        }
        options={extractConditions()}
    />)
}

export function ConditionOperatorSelect({input, operators=[], inputs={}, ...props}) {
    const operators_map = Object.fromEntries(operators.map(o => [o[0], <Select.Option value={o[0]}>{o[1]}</Select.Option>]));
    const inputs_map = ('default' in inputs)?inputs:{...inputs, ...{'default': ['eq', 'gt', 'gte', 'lt', 'lte', 'in']}};

    const accepted_operators = inputs_map[input?.widget] || inputs_map['default'];

    return (
        <Select size='small' placeholder="условие" {...props}>
            {accepted_operators?.map(op => {
                return operators_map[op];
            })}
        </Select>
    )
}


export function GenerateInput({input, condition, ...props}) {
    let component = {}

    switch (input?.widget) {
        case 'TextInput':
            component = {
                'in': <Select mode='tags' {...props} />,
                'default': <Input {...props} />
            }
            return component[condition] || component['default'];
        case 'NumberInput':
            component = {
                'in': <Select mode='tags' {...props} />,
                'default': <Input type='number' {...props} />
            }
            return component[condition] || component['default'];
        case 'EmailInput':
            component = {
                'in': <Select mode='tags' {...props} />,
                'default': <Input {...props} rules={[
                    {type: 'email', message: 'некорректный email адрес'}
                ]}/>
            }
            return component[condition] || component['default'];
        case 'URLInput':
            component = {
                'in': <Select mode='tags' {...props} />,
                'default': <Input {...props} rules={[
                    {type: "url", message: "некорректный url адрес"}
                ]}/>
            }
            return component[condition] || component['default'];
        case 'PasswordInput':
            return <Input.Password {...props} />
        case 'HiddenInput':
            return <Input hidden {...props} />
        case 'DateInput':
            return <DatePicker {...props} />
        case 'DateTimeInput':
            return <DatePicker showTime {...props} />
        case 'TimeInput':
            return <DatePicker.TimePicker {...props} />
        case 'Textarea':
            return <Input.TextArea {...props} />
        case 'CheckboxInput':
            return <Checkbox {...props} />
        case 'Select':
            component = {
                'in': <Select mode='multiple' {...props}>
                    {input?.choices.map(el => (
                        <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>
                    ))}
                </Select>,
                'default': <Select {...props}>
                    {input?.choices.map(el => (
                        <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>
                    ))}
                </Select>
            }
            return component[condition] || component['default'];
        case 'NullBooleanSelect':
            return <Select {...props} />
        case 'SelectMultiple':
            component = {
                'contains': <Select {...props}>
                    {input?.choices.map(el => <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>)}
                </Select>,
                'overlap': <Select mode='multiple' {...props}>
                    {input?.choices.map(el => (
                        <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>
                    ))}
                </Select>,
                'default': <Typography>Нет возможности выбора</Typography>
            }
            return component[condition] || component['default'];
        case 'RadioSelect':
            return <Select {...props}>
                {input?.choices.map(el => (
                    <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>
                ))}
            </Select>
        case 'CheckboxSelectMultiple':
            return <Select {...props}>
                {input?.choices.map(el => (
                    <Select.Option key={el?.id} value={el?.id}>{el?.name}</Select.Option>
                ))}
            </Select>
        case 'FileInput':
            return <Input type='file' {...props} />
        case 'ClearableFileInput':
            return <Input type='file' {...props} />
        case 'MultipleHiddenInput':
            return <Input hidden {...props} />
        case 'SplitDateTimeWidget':
            return <DatePicker.RangePicker {...props} />
        case 'SplitHiddenDateTimeWidget':
            return <DatePicker.RangePicker {...props} />
        case 'SelectDateWidget':
            return <DatePicker.RangePicker {...props} />

        default:
            return <Input {...props} />
    }
}
