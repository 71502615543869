import {ObjectService} from "./Object";

export default class DiscountService extends ObjectService {

    generatePromocodes(data){
        // генерирует промокоды
        return this._post('generate', data).then(res => {
            return Promise.resolve(res)
        }).catch(this.error_handler)
    }
}
