import React, {useEffect, useState} from "react"
import {Button, Col, Divider, Input, Popover, Row, Skeleton, Space, Tree, Typography} from 'antd';
import {DeleteOutlined, PlusOutlined, QuestionCircleOutlined, ReloadOutlined} from '@ant-design/icons';
import useRule from "app/Providers/RuleProvider";
import {useEffectOnce, useToggle} from 'react-use'
import useUser from "app/Providers/UserProvider";
import {X} from "app/ApiService/Query";

export default function TreeRules(props) {
    // Компонент, отображающее дерево правил для контрагента
    // const {contragent} = useContragent();
    const {rule, setRule, setEdited} = useRule();
    const {contragent} = useUser();

    const [tree, setTree] = useState();
    const [update, toggle] = useToggle(false);

    useEffectOnce(() => {
        setTree(null)
        X.Event().get().then(res => {
            setTree(generate_tree(res.data.data));
            toggle();
        })
    })

    useEffect(() => {
        if (tree) {
            X.Contragent(contragent).Rule().list().then(res => {
                setTree(prev => generate_tree(prev, res.data.data));
            })
        }
    }, [contragent, update, rule]);

    function setContextRule(data) {
        data['contragent_id'] = contragent?.id
        setRule(data);
    }

    function destroyRule(id) {
        X.Contragent(contragent).Rule(id).destroy().then(res => {
            toggle();
            setRule(null);
        })
    }

    function generate_tree(events, handlers = []) {
        const counts = handlers?.reduce((prev, el) => {
            prev[el.event]++;
            return prev;
        }, Object.fromEntries(handlers?.map(el => [el.event, 0])));

        let modified_events = handlers?.map(el => {
            el.key = el.id
            el.data = {}
            el.title = <Space>
                <Typography onClick={() => setContextRule(el)}
                            style={(!el?.enabled)?{"color": "#BBB"}:{}}>{el.name}</Typography>
                <Button size='small' onClick={() => destroyRule(el.id)} danger icon={<DeleteOutlined/>}/>
            </Space>
            return el
        })

        function generate_before(item) {
            if (Array.isArray(item?.when) && item.when?.includes('before')) {
                return {
                    title: <Typography>До <Button size='small' onClick={() => setContextRule({event: item?.id, type: 'before'})}
                                                  icon={<PlusOutlined/>}/></Typography>,
                    type: 'before',
                    key: `before_${item?.key}`,
                    selectable: false,
                    children: modified_events.filter(el => (el?.type === 'before' && item?.key === el?.event))
                }
            }
        }

        function generate_after(item) {
            if (Array.isArray(item?.when) && item.when?.includes('after')) {
                return {
                    title: <Typography>После <Button size='small' onClick={() => setContextRule({event: item?.id, type: 'after'})}
                                                     icon={<PlusOutlined/>}/></Typography>,
                    type: 'after',
                    key: `after_${item?.id}`,
                    selectable: false,
                    children: modified_events.filter(el => (el?.type === 'after' && item?.key === el?.event))
                }
            }
        }

        return events?.map(el => {
            el.key = el.id;
            el.title = el.name;
            el.selectable = false;

            if (el?.is_leaf) {
                el.children = [
                    generate_before(el),
                    generate_after(el)
                ].filter(x => x)
            } else {
                el.children = el?.children.map(el => {
                    el.key = el.id;
                    el.title = el.name;
                    el.selectable = false;
                    el.event = true;
                    el.count = counts[el.id] || 0;
                    return el;
                })
                el?.children?.map(el => {
                    el.children = [];
                    if (!el?.disabled) {
                        el.children = [generate_before(el), generate_after(el)].filter(x => x);
                    }
                    return el
                })
            }
            el.count = el.children.reduce((sum, el) => sum + el.count, 0);
            return el
        });
    }

    function nodeRender(el, ano) {
        return <Space>
            <Typography style={(el.disabled)?{"color": "#AAA"}:{}}>
                {el.title} {el.count ? <b style={{"color": "#BBB"}}>[{el.count}]</b>: ""}
            </Typography>
            {el.id && ('is_leaf' in el) && el?.event &&
                <Popover title={`Описание события "${el.title}"`} content={
                        <Typography>Идентификатор события - <Typography.Text code copyable>{el.id}</Typography.Text></Typography>
                }>
                    <QuestionCircleOutlined />
                </Popover>
            }
            {/* {el.id && ('is_leaf' in el) && <i style={{"color": "#99D"}}>({el.id})</i>} */}
        </Space>
    }

    return (<>
        <Row gutter={8}>
            <Col flex='1'>
                <Input.Search disabled />
            </Col>
            <Col flex='0'>
                <Button icon={<ReloadOutlined/>} onClick={() => {
                    toggle();
                }}/>
            </Col>
        </Row>
        <Divider />
        <Row>
            <Col>
                {tree ?
                    <Tree
                        onLabelClick="collapse"
                        showLine={{showLeafIcon: false}}
                        treeData={tree}
                        titleRender={nodeRender}
                        {...props}
                    />
                    :
                    <Skeleton active={!tree}/>
                }
            </Col>
        </Row>
    </>)


}
