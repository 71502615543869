import BaseService from "./Base";


class FableService extends BaseService {
    MODEL = 'flexible'

    constructor(model) {
        super();
        this._model = model;
    }

    list() {
        return super.list("", {model_name: this._model})
    }

    load() {
        return super._get(`data/${this._model}`)
    }

    save(data) {
        return super._post(`data/${this._model}`, data);
    }
}

export {
    FableService,
};
