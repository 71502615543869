import {Layout} from "antd";
import React from "react";
import {Route, Switch} from "react-router";
import {CartProvider} from "src/Providers/CartProvider";
import useUser from "../../Providers/UserProvider";
import CartDetail from "../Products/Bucket";
import Product from "../Products/Products";
import {ContragentProvider} from "./ContragentContext";
import {AccountProvider} from "app/Providers/AccountProvider";
import ContragentDetail from "app/Components/Contragents/ContragentDetail";
import ContragentTable from "app/Components/Contragents/ContragentTable";


export default function Client(){

    const {is_global_permitted} = useUser();

    return <Layout.Content>
        <Switch>
            <Route path='/contragents/:id/products'>
                <Product />
            </Route>
            <Route path='/contragents/:id/cart'>
            {is_global_permitted('application.contragent.cart') &&
                <ContragentProvider>
                    <CartProvider>
                        <CartDetail />
                    </CartProvider>
                </ContragentProvider>
            }
            </Route>
            <Route path='/contragents/:id'>
                <ContragentProvider>
                    <AccountProvider>
                        <ContragentDetail />
                    </AccountProvider>
                </ContragentProvider>
            </Route>
            <Route exact path='/contragents'>
                {is_global_permitted('application.contragent.list') &&
                    <ContragentTable/>
                }
            </Route>
        </Switch>
    </Layout.Content>
}
