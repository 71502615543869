import BaseService from "./Base";

class AtolPayService extends BaseService {
    MODEL = 'atolpay'

    register(data) {
        // Регистрация платежа
        return this._post('register', data).then(res => {
            return Promise.resolve(res)
        }).catch(this.error_handler)
    }
}

class S3Service extends BaseService {
    MODEL = 's3'

    live(data){
        return this._post('live', data).then(res => {
            return Promise.resolve(res)
        }).catch(this.error_handler)
    }
}

class KeycloakService extends BaseService {
    MODEL = 'keycloak'

    live(){
        return this._post('live').then(res => {
            return Promise.resolve(res)
        }).catch(this.error_handler)
    }
}

class Buh1CService extends BaseService {
    MODEL = 'buh_1c'
}

class SMTPCService extends BaseService {
    MODEL = 'smtp'

    live(){
        return this._post('live').then(res => {
            return Promise.resolve(res)
        }).catch(this.error_handler)
    }
}



export {
    AtolPayService,
    S3Service,
    KeycloakService,
    Buh1CService,
    SMTPCService
}
