import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {X} from "src/ApiService/Query";


const ContragentContext = React.createContext();


export class Contragent {
    // Объект контрагента
    // Зачем он вообще? какие задачи решает?
    // TODO: Давайте сделаем универсально. общий предок всех сущностей - Base (или другое название).
    // в конструкторе либо один параметр obj типа js Object и цикл по его ключам и перегонка их в поля класса
    // либо конкретные отдельные параметры
    constructor({id, name, number, type, status, external_id, role, blocked}) {
        this.id = id
        this.name = name
        this.type = type
        this.status = status
        this.number = number
        //TODO: Временное решение, избавиться потом и переделать через атрибут
        this.external_id = external_id
        this.role = role
        this.blocked = blocked

        this.query = X.Contragent(this.id)
    }
}

export function ContragentProvider({initial_contragent, children}) {
    // Контекст хранилища по работе с контрагентом
    let initial = null;

    if (initial_contragent){
        initial = new Contragent(initial_contragent)
    }

    const [contragent, _setContragent] = useState(initial);
    const [cart, setCart] = useState(null);
    const [loading, setLoading] = useState(true);

    // const {contragent: ctg} = useUser();

    // useEffect(() => {
    //     if (!initial_contragent && !contragent){
    //         setContragent(new Contragent({...ctg}));
    //     }
    // }, [ctg]);

    useEffect(() => {
        contragent && refreshCart()
    }, [contragent])

    function refreshCart(){
        // TODO: надо ли передавать в get_items параметры?
        X.Contragent(contragent).Cart().get_items({contragent: contragent?.id}).then(response => {
            setCart(response.data)
        })
    }

    const setContragent = useCallback((ctg) => {
        setLoading(true);
        if (ctg instanceof Contragent) {
            _setContragent(ctg);
            setLoading(false);
        } else if (ctg === null) {
            _setContragent(null)
        } else {
            console.error('Объект должен быть типа "Contragent"')
        }
    }, [])

    function check_role(role) {
        return role === contragent?.role?.name;
    }

    function check_roles(roles) {
        return roles.includes(contragent?.role?.name);
    }

    function is_vendor() {
        return check_roles(["MAIN_OPERATOR", "VENDOR"]);
    }

    const memoedValue = useMemo(
        () => ({
            contragent,
            cart,
            loading,
            setContragent,
            setLoading,
            refreshCart,
            is_vendor,
            check_role,
            check_roles
        }),
        [contragent, cart, loading, setContragent]
    );


    return (
        <ContragentContext.Provider value={memoedValue}>
            {children}
        </ContragentContext.Provider>
    );
}

export default function useContragent() {
    return useContext(ContragentContext);
}
