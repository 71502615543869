import axios, {refresh_axios} from "../config/axios";
import BaseService, {error_handler} from "./Base";


class UnAuthService extends BaseService {

    token_type = null;

    login() {
        return Promise.reject("Authorization Failed");
    }

    register() {
        return Promise.reject("Register Unavailable");
    }

    logout() {
        return Promise.reject("Logout Unavailable");
    }

    refresh() {
        return Promise.reject("Refresh Unavailable");
    }

    test() {
        return axios.get("/api/auth/test")
    }
}

class BaseAuthService extends UnAuthService {
    token_type = "JWT";

    login(data) {
        return axios.post("/api/auth", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    register(data) {
        return axios.post("/api/auth/register", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    logout(data) {
        return axios.post("/api/auth/logout", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    refresh(data) {
        return refresh_axios.post("/api/auth/refresh-token", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

}

class KeycloakAuthService extends UnAuthService {
    token_type = "Keycloak";

    login(data) {
        return axios.post("/api/keycloak/login", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    logout(data) {
        return axios.post("/api/keycloak/logout", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }

    refresh(data) {
        return refresh_axios.post("/api/keycloak/refresh-token", data).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            error_handler(err);
            return Promise.reject(err);
        });
    }
}

function loginAPI({username, password}) {
    return axios.post("/api/auth", {username, password});
}

function loginKeycloakAPI({username, password}) {
    return axios.post("/api/keycloak/login", {username, password});
}

function logoutAPI() {
    return axios.post("/api/auth/logout");
}

function refreshTokenAPI({refresh}) {
    return axios.post("/api/auth/refresh-token", {refresh});
}

function registerUserAPI(data) {
    return axios.post("/api/auth/register", data);
}

function getUserContragentAPI() {
    return axios.get("/api/user/contragent/active");
}

function getUserInfoAPI() {
    return axios.get("/api/user");
}

function getUserPermissionsAPI() {
    return axios.get("/api/user/permissions");
}

export {
    loginAPI,
    logoutAPI,
    refreshTokenAPI,
    registerUserAPI,
    getUserInfoAPI,
    getUserContragentAPI,
    getUserPermissionsAPI,
    loginKeycloakAPI,
    BaseAuthService,
    KeycloakAuthService,
    UnAuthService,
};
