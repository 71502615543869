import {Button} from "antd";
import SearchTable from "src/Base/Tables/SearchTable";
import {usePaginationEx} from "src/Components/Common/Hooks/usePagination";
import React, {useEffect, useState} from "react";
import {useToggle} from "react-use";
import Money from "../../Accounts/utils/Money";
import useContragent from "../ContragentContext";
import {X} from "src/ApiService/Query";


export default function OfferTable({onSelectOffer, type, filter = {}}) {
    // таблица с предложениями от заданного контрагента

    const [search, setSearch] = useState();
    const [update, toggle] = useToggle(false);
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const {contragent} = useContragent();
    const [{page, limit, total}, pagination,
        {setTotal}] = usePaginationEx();

    useEffect(() => {
        setLoading(true);
        X.Contragent(contragent).Offer()
            .list({params: {...filter, search, page, limit, type}})
            .then(res => {
                setOffers(res.data.data);
                setTotal(res.data.count);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [update, search, page, limit]);

    const columns = [
        // { key: 'actions', width: 10, render: (record) => {}},
        {title: "№", dataIndex: "id", key: "id"},
        {title: "наименование", dataIndex: "name", key: "name"},
        {
            title: "период", dataIndex: "period", key: "period", render: (val => {
                // вынести в функцию в lib
                return val ? `${val?.days}д ${val?.months}м ${val?.years}г` : null;
            }),
        },
        // { title: 'кол-во', dataIndex: 'num', key: 'num' },
        {
            title: "сумма", dataIndex: "price", align: "right", key: "price", width: 150, render: (sum) => (
                <Money sum={sum}/>
            ),
        },
        {
            key: "actions", width: 100, render: (record) => (
                <Button size="small" type="primary" onClick={() => onSelectOffer(record)}>Выбрать</Button>
            ),
        },
        // { title: 'НДС', dataIndex: 'tax', align: 'right', key: 'tax', width: 150, render: (sum) => (
        //     <Money sum={sum} />
        // )},
    ];

    return <SearchTable dataSource={offers}
                        size={"small"}
                        columns={columns}
                        toggle={toggle}
                        pagination={pagination}
                        loading={loading}
                        onSearchEdit={setSearch}/>;
}

export function PackageTable({onSelect, type}) {
    // таблица с предложениями от заданного контрагента

    const [search, setSearch] = useState();
    const [update, toggle] = useToggle(false);
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [offers_fltrd, setOffersFltrd] = useState([]);
    const {contragent} = useContragent();
    const [{page, limit, total}, pagination,
        {setTotal}] = usePaginationEx();

    useEffect(async () => {
        console.log("search", search); //CONSOLE_DEBUG search
        try {
            setLoading(true);
            const res = await X.Contragent(contragent).Package()
                .list({params: {type, search, page, limit}});
            setOffers(res.data.data);
            setOffersFltrd(res.data.data);
            setTotal(res.data.count)
        } finally {
            setLoading(false);
        }
    }, [update, search, page, limit]);

    const columns = [
        // { key: 'actions', width: 10, render: (record) => {}},
        {title: "№", dataIndex: "id", key: "id"},
        {title: "наименование", dataIndex: "name", key: "name"},
        // { title: 'сумма', dataIndex: 'price', align: 'right', key: 'price', width: 150, render: (sum) => (
        //     <Money sum={sum} />
        // )},
        {
            key: "actions", width: 100, render: (record) => (
                <Button size="small" type="primary" onClick={() => onSelect(record)}>Выбрать</Button>
            ),
        },
    ];

    return <SearchTable dataSource={offers_fltrd}
                        size={"small"}
                        columns={columns}
                        toggle={toggle}
                        pagination={pagination}
                        loading={loading}
                        onSearchEdit={setSearch}
    />;
}
