import React, {useState} from 'react'
import {useEffectOnce} from 'react-use'

import {Form, Input, Select, Typography} from 'antd'
import useUser from 'app/Providers/UserProvider'
import {X} from "app/ApiService/Query";


const required = {required: true, message: 'Обязательное поле.'}

export default function ProductForm({...props}) {
    // Форма создания Продукта

    const [status, setStatus] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [nomenclatureList, setNomenclatureList] = useState([])
    const [nomenclature, setNomenclature] = useState()

    const {contragent} = useUser()
    const nmFilter = {contragent: contragent?.id, pp_product__isnull: true}

    useEffectOnce(() => {
        X.Measure().list().then(res => {
            setMeasures(res.data.data)
        })
        X.Contragent(contragent).Product().getStatuses().then(res => {
            setStatus(res.data.data)
        })

        if (contragent)
            X.Contragent(contragent).Nomenclature().list(
                {params: nmFilter}).then(res => {
                setNomenclatureList(res.data.data)
            })
    })

    function searchNomenclature(value) {
        X.Contragent(contragent).Nomenclature().list(
            {params: {...nmFilter, code__startswith: value}}).then(res => {
            setNomenclatureList(res.data.data)
        })
    }

    function changeNomenclature(value) {
        setNomenclature(value);
    }


    return (
        <Form
            layout='vertical'
            {...props}
        >
            <Form.Item
                label='наименование продукта'
                name='name'
                rules={[required]}
            >
                <Input type='text'/>
            </Form.Item>

            <Form.Item name='description' label='описание'>
                <Input type='text'/>
            </Form.Item>

            <Form.Item
                label='Единица измерения'
                name='measure'
                rules={[required]}
            >
                <Select>{measures.map((m) => (
                    <Select.Option key={m.id} value={m.id}>
                        <Typography>{m.name} <Typography.Text type='secondary'>({m.short})</Typography.Text>
                        </Typography>
                    </Select.Option>
                ))}
                </Select>
            </Form.Item>
            <Form.Item
                label='cтатус'
                name='status'
                rules={[required]}
            >
                <Select>{status.map((m) => (
                    <Select.Option
                        key={m.id}
                        value={m.id}
                    >
                        <Typography>{m.name}</Typography>
                    </Select.Option>
                ))}
                </Select>
            </Form.Item>

            {!props.edit &&
                <Form.Item name='nomenclature' label='Номенклатура'>
                    <Select
                        showSearch
                        value={nomenclature}
                        showArrow={true}
                        filterOption={false}
                        onSearch={searchNomenclature}
                        onChange={changeNomenclature}
                        notFoundContent={null}
                    >
                        {nomenclatureList.map(nm =>
                            <Select.Option
                                key={nm.id}
                                value={nm.id}
                            >
                                <Typography>{nm.code} {nm.name}</Typography>
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>}
        </Form>
    )
}
