import {MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Layout, List, Row, Typography} from "antd";
import {sleep} from "app/lib/tools";
import React, {useState} from "react";
import useIntegration from "app/Providers/IntegrationsProvider";
import {downloadDocument} from "src/ApiService/Document";
import {AtolX} from "app/ApiService/AtolQuery";

const Link = Typography.Link;

export default function Docs({revenue}) {
    const {atol} = useIntegration();
    const [docs, setDocs] = useState([]);
    const [visible, setVisible] = useState(false);

    async function on_click() {
        if (!visible) {
            console.log("visible", visible); //CONSOLE_DEBUG visible
            const res = await AtolX.Revenue(revenue).get_documents();
            setDocs(res.data.data);
            setVisible(true);
        } else
            setVisible(false);
    }

    if (atol)
        return <Dropdown overlay={
            (docs.length) ?
            <List dataSource={docs}
                  itemLayout={"vertical"}
                  size={"small"}
                  style={{paddingRight: 20}}
                  split={true}
                  renderItem={item => (
                      <List.Item>
                          {/*<Link href={`${process.env.REACT_APP_BASE_URL||""}${item.url}`}*/}
                          {/*      target={"_blank"}>*/}
                          <Link onClick={() => downloadDocument(item.url)}>
                              {item.filename}
                          </Link>
                      </List.Item>
                  )}/> :
            <Layout>
                <Row>
                    <b>Документов нет</b>
                </Row><Row>
                ПО этой реализации печатные формы отсутствуют
            </Row>
            </Layout>}
                         visible={visible}
                         trigger={["click"]}>
            <Button size={"small"}
                    icon={<MoreOutlined/>}
                    onBlur={() => sleep(200).then(x => setVisible(false))}
                    onClick={on_click}/>
        </Dropdown>;
    else
        return "--";

    // return <Link onClick={() => new AtolRevenueService(revenue.id).get_documents()}>
    //     Документы {revenue.name}
    // </Link>;
}
