import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  PageHeader,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { Card } from "../../Base";
import DynamicalyInput from "../../Base/Inputs/DynamicalyForm";
import API from "app/ApiService/Module";
import useIntegration from "../../Providers/IntegrationsProvider";


export default function ModuleDetail() {
  const [fields, setFields] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [title, setTitle] = useState("");
  const [formsList, setFormsList] = useState([]);

  const { setStatusIntegration, getStatusIntegration } = useIntegration();

  const { id } = useParams();

  useEffect(() => {
    new API(null).retrieve(id, (r) => {
      setFields(r.data?.fields || []);
      setIsActive(r.data?.data.is_active);
      setTitle(r.data?.data.verbose_name);
    });
    new API(null).forms(id, (res) => {
      setFormsList(res.data.data);
    });
  }, [id]);

  return (
    <Layout.Content>
      <PageHeader style={{background: 'white'}} onBack={() => window.history.back()} title={title}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            size='small'
            extra={
              <Switch
                checked={isActive}
                checkedChildren="Вкл"
                onChange={(checked) => setIsActive(checked)}
                unCheckedChildren="Выкл"
              />
            }
            title="Настройки"
          >
            <Form
              layout="vertical"
              onFinish={(values) => {
                values.is_active = Boolean(isActive);
                setStatusIntegration(id, Boolean(isActive));
                new API().create(id, values);
              }}
            >
              {fields.map((v) => (
                <Form.Item
                  initialValue={v.value}
                  key={v.name}
                  label={v.label}
                  name={v.name}
                  rules={v.rules}
                >
                  {(() => {
                    if (v.choices) {
                      if (v.type === "checkbox") {
                        return (
                          <Checkbox.Group>
                            {v.choices.map((vv) => (
                              <Checkbox value={vv[0]} key={vv[0]}>
                                {vv[1]}
                              </Checkbox>
                            ))}
                          </Checkbox.Group>
                        );
                      } else if (v.type === "radio") {
                        return (
                          <Radio.Group>
                            {v.choices.map((vv) => (
                              <Radio value={vv[0]} key={vv[0]}>
                                {vv[1]}
                              </Radio>
                            ))}
                          </Radio.Group>
                        );
                      } else {
                        return (
                          <Select>
                            {v.choices.map((vv) => (
                              <Select.Option value={vv[0]} key={vv[0]}>
                                {vv[1]}
                              </Select.Option>
                            ))}
                          </Select>
                        );
                      }
                    } else if (v.type === 'BooleanField')  {
                      return <Radio.Group defaultValue={v.value}>
                        <Radio value={true}>Да</Radio>
                        <Radio value={false}>Нет</Radio>
                      </Radio.Group>;
                    } else  {
                      return <Input />;
                    }
                  })()}
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        {getStatusIntegration(id) &&
          formsList?.map((form) => {
            return (
              <Col span={12} key={form.name_form}>
                <Card title={form.name_form}>
                  <Form
                    id={form.name_form}
                    onFinish={(d) => {
                      new API(null).forms_submit(
                        id,
                        form.endpoint,
                        "POST",
                        null,
                        (res) => {}
                      );
                    }}
                  >
                    {form.fields?.map((field) => {
                      return (
                        <Form.Item key={field.name} name={field.name}>
                          <DynamicalyInput widget={field.input.widget} />
                        </Form.Item>
                      );
                    })}
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        form={form.name_form}
                      >
                        {form.submit_button_name}
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            );
          })}
      </Row>
      </PageHeader>
    </Layout.Content>
  );
}
