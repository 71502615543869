import React, {useContext, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {message, notification} from 'antd';
import {registerUserAPI} from '../ApiService/Auth';

const AuthContext = React.createContext();


export function AuthProvider({children}) {
    // Контекст хранилища по работе с авторизацией и пользователем

    const history = useHistory();

    const [user, setUser] = useState(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    // function login({username, password}) {
    //     setLoading(true);
    //     loginAPI({username, password}).then(res => {
    //         localStorage.setItem('jwt_sec_token_v1', JSON.stringify({...res.data, token_type: 'JWT'}));
    //         history.push('/');
    //     }).catch(err => {
    //         message.error('неверный логин или пароль')
    //         setError(error)
    //     }).finally(() => {
    //         setLoading(false)
    //     })
    // }

    function register(data) {
        setLoading(true);
        registerUserAPI(data).then(res => {
            notification.open({
                message: 'Вы успешно зарегистрированы',
                description: 'Войдите в систему, чтобы начать работу'
            })
            history.push('/signin');
        }).catch(err => {
            message.error('неверный логин или пароль')
            setError(error)
        }).finally(() => {
            setLoading(false)
        })
    }


    // function logout() {
    //     localStorage.removeItem('jwt_sec_token_v1');
    //     logoutAPI().finally(() => {
    //         history.push('/signin');
    //     })
    // }

    const memoedValue = useMemo(() => ({
            user, loading, error
        }),
        [user, loading, error]
    );

    const functions = {
        register,
        setUser,
    }
    let values = {...memoedValue, ...functions}

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    );
}

export default function useAuth() {
    return useContext(AuthContext);
}
