import BaseService from "./Base";

class EventService extends BaseService {
    MODEL = 'events'

    getNotifications() {
        return new Promise((resolve, reject) => {
            this._post('notification').then(res => {
                return resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    getOperators() {
        return this._get('operators').then(r => {
            return Promise.resolve(r);
        }).catch(EventService.error_handler)
    }
}

export {
    EventService,
}
