import BaseService from "./Base";
import axios from "app/config/axios";

export
function getExistUsername({username}) {
    return axios.post('/api/auth/register/exist-username', {username})
}

class UserService extends BaseService {
    MODEL = 'user'
    CONTRAGENT = `contragent`

    getUserList() {
        return this._get();
    }

    addUser(data) {
        return this.create(data);
    }

    modifyUser(data) {
        return this._patch(data);
    }

    getContragents() {
        return this._get(this.CONTRAGENT)
    }

    setActiveContragent(id) {
        return this._post(`${this.CONTRAGENT}/active`, {'contragent': id})
    }
}

export default UserService;
