import {Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Skeleton, Typography} from "antd";
import {useForm} from "antd/es/form/Form";
import AtolService from "app/ApiService/Atol";
import ContractService from "app/ApiService/Contract";
import {Contragent, X} from "app/ApiService/Query";
import {AccountSelect} from "app/Base/Inputs";
import useIntegration from "app/Providers/IntegrationsProvider";
import useUser from "app/Providers/UserProvider";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useEffectOnce} from "react-use";
import AttributesForm from "app/Components/Common/Forms/AttributesForm";
import useContragent from "app/Components/Contragents/ContragentContext";


const required = {required: true, message: "Обязательное поле."};

export default function ContractForm({form, item, onFinish, type, ...props}) {
    // TODO: переработать форму

    const {contragent: buyer} = useContragent();
    const {contragent} = useUser();
    const [contragents, setContragents] = useState([]);
    const [buyerAccounts, setBuyerAccounts] = useState([]);

    const [sellerAccounts, setSellerAccounts] = useState([]);

    const [contractTypes, setContractTypes] = useState([]);
    const [typeContract, setTypeContract] = useState(null);

    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(true);

    const [attrForm] = useForm();
    const [dictionaries, setDictionaries] = useState([]);

    // const [ref_code, setRefCode] = useState();

    const params = useParams();

    function onFinishContract(values) {
        values['attributes'] = attrForm.getFieldsValue()

        X.Contragent(contragent).Contract(item?.id).update_or_create(values).then(() => {
            onFinish && onFinish(values);
        }).catch(ContractService.create_error_handler("Невозможно создать договор"));
    }

    useEffect(() => {
        const contragentsAPI = Contragent();
        const contractAPI = Contragent(contragent?.id).Contract();
        setLoading(true);

        form.resetFields();

        Promise.all([
            contragentsAPI.list(),
            contractAPI.listStatuses(),
            contractAPI.listTypes(),
            buyer.query.accounts(),
        ]).then(([contragents, statuses, types, accs]) => {
            setContragents(contragents.data.data);
            setStatuses(statuses.data.data);
            setContractTypes(types.data.data.filter(x => (x.id === type) || !type));
            setBuyerAccounts(accs.data.data.filter(el => el.type === "internal"));
        }).then(_ => {
            if (item?.id) {
                X.Contragent(contragent).Contract(item?.id).get().then(res => {
                    let extract_values = {
                        buyer: res.data.buyer.id,
                        buyer_account: res.data.buyer_account.id,
                        seller: res.data.seller.id,
                        seller_account: res.data.seller_account.id,
                        start_date: moment(res.data.start_date),
                        end_date: res.data.end_date ? moment(res.data.end_date) : null,
                        number: res.data.number,
                        status: res.data.status,
                        type: res.data.type.id,
                        ref_code: res.data.ref_code,
                        agency_comission: res.data.agency_comission,
                        agency_discount: res.data.agency_discount,
                    };
                    form.setFieldsValue(extract_values);
                    setTypeContract(res.data.type.id);
                    // setRefCode(res.data.ref_code);
                    loadAccountsSeller(res.data.seller.id);
                    setLoading(false);
                });

                X.Contragent(contragent).Contract(item?.id).attributes.get().then(res => {
                    setDictionaries(res.data.data);
                })

            } else {
                X.Dictionary().getDictsByEntity("contracts").then(res => {
                    setDictionaries(res.data.data);
                });
                setLoading(false);
            }
        });
    }, [params.id, buyer]);

    function loadAccountsSeller(ctg_id) {
        X.Contragent(ctg_id).accounts().then(res => {
            setSellerAccounts(res.data.data.filter(el => el.type === "internal"));
        });
    }

    function loadAccountsBuyer(ctg_id) {
        X.Contragent(ctg_id).accounts().then(res => {
            setBuyerAccounts(res.data.data.filter(el => el.type === "internal"));
        });
    }

    function buyerName(type) {
        switch (type) {
            case 2: {
                return "Агент";
            }
            default: {
                return "Покупатель";
            }
        }
    }

    function sellerName(type) {
        switch (type) {
            case 2: {
                return "Вендор";
            }
            default: {
                return "Вендор";
            }
        }
    }

    if (loading) {
        return Array(3).fill(1).map(el => (
            <Skeleton></Skeleton>
        ));
    }

    return (<>
        <Form layout="vertical"
              form={form}
              onFinish={onFinishContract}
              {...props}
        >
            <Form.Item label="Тип договора" name="type" rules={[required]}>
                <Radio.Group onChange={(e) => setTypeContract(e.target.value)} buttonStyle="solid" size="small">
                    {contractTypes.map(t => (
                        <Radio value={t.id} key={t.id}>
                            {t.name}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
            {typeContract ? <>
                <Row gutter={16}>
                    <Col xs={12}>
                        <Form.Item>
                            <Card size="small" title={buyerName(typeContract)}>
                                <Form.Item name="buyer" initialValue={buyer?.id} rules={[required]}>
                                    <Select onSelect={loadAccountsBuyer}>
                                        {contragents.map(el => <Select.Option value={el.id}
                                                                              key={el.id}>
                                            {el.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Лицевой счет" name="buyer_account" rules={[required]}>
                                    <AccountSelect accounts={buyerAccounts} OptionKey="id"/>
                                </Form.Item>
                            </Card>
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            <Card size="small" title={sellerName(typeContract)}>
                                <Form.Item name="seller" rules={[required]}>
                                    <Select onSelect={loadAccountsSeller}>
                                        {contragents.map(el =>
                                            <Select.Option value={el.id} key={el.id}>
                                                {el.name}
                                            </Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Лицевой счет" name="seller_account" rules={[required]}>
                                    <AccountSelect accounts={sellerAccounts} OptionKey="id"/>
                                </Form.Item>
                            </Card>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Номер договора" name="number" rules={[required]}>
                    <Input type="text"/>
                </Form.Item>
                <Form.Item label="Статус договора" name="status" rules={[required]}>
                    <Select options={statuses}/>
                </Form.Item>
                <Form.Item label="Дата начала договора" name="start_date">
                    <DatePicker format="DD.MM.YYYY" style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item label="Дата окончания договора" name="end_date">
                    <DatePicker format="DD.MM.YYYY" style={{width: "100%"}}/>
                </Form.Item>
                {typeContract === 2 && <>
                    {/* <Form.Item label='Реферальный код' name='ref_code' rules={[required]}
                               extra={ref_code && <Typography.Text>реферальная ссылка: <Typography.Text code
                                                                                                        copyable>priceplan.appef_code}</Typography.Text></Typography.Text>}
                               onChange={(d) => setRefCode(d.target.value)}>
                        <Input addonAfter={<Button size='small' type='link' icon={<ReloadOutlinapp/>
                    </Form.Item> */}
                    <Form.Item label="Модификатор скидки" name="agency_discount"
                               help={
                                   <Link to={`/contragents/${contragent.id}/discount`}>
                                       Для скидки клиенту, необходимо создать модификатор
                                   </Link>
                               } rules={[required]}>
                        <AgencyDiscountSelect/>
                    </Form.Item>
                    <Form.Item label="Процент выплаты агенту" name="agency_comission" rules={[
                        required,
                        {
                            validator: (_, value) => {
                                if (value >= 0 && value <= 100) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Некорректно задано значение комиссии");
                            },
                        },
                    ]}>
                        <InputNumber min={0} max={100}/>
                    </Form.Item>
                    <Form.Item label="Условия выплаты">
                        <Select defaultValue={"all"}>
                            <Select.Option key={"all"} value={"all"}>Заказы в любом статусе</Select.Option>
                        </Select>
                    </Form.Item>
                </>} </> : null}
        </Form>
        {!dictionaries.length && <Form.Item>
                <Typography>Расширьте информацию в <Link to='/dictionary'>справочнике</Link></Typography>
            </Form.Item>}
        {dictionaries.map(el => {
            return <AttributesForm id={el.codename}
                                    attributes={el.attributes}
                                    form={attrForm}
            />
        })}
        </>
    );
}

function AgencyDiscountSelect({...props}) {

    const {contragent} = useUser();
    const [discounts, setDiscounts] = useState([]);

    useEffect(() => {
        X.Contragent(contragent).Discount().list().then(res => {
            setDiscounts(res.data.data);
        });
    }, []);

    return (
        <Select {...props}>
            {discounts.map(el => (
                <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
            ))}
        </Select>
    );
}


export function ContractAtolEditForm({row, form, ...props}) {
    // форма редактирования договора
    const [types, setTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const params = useParams();
    const {atol} = useIntegration();
    const [dictionaries, setDictionaries] = useState([]);
    const [attrForm] = useForm();
    const {contragent} = useContragent();

    useEffect(() => {
        X.Contragent(contragent).Contract().getFormData(params.id).then((r) => {
            setTypes(r.data.types);
            setStatuses(r.data.statuses);
        });
        form.setFieldsValue({
            type: row.type.id,
            status: row.status,
            start_date: row.start_date ? moment(row.start_date) : "",
            end_date: row.end_date ? moment(row.end_date) : "",
            ref_code: row.ref_code,
        });

        if (row?.id) {
            X.Contragent(contragent).Contract(row?.id).attributes.get().then(res => {
                setDictionaries(res.data.data);
            })}
        else {
            X.Dictionary().getDictsByEntity("contracts").then(res => {
                setDictionaries(res.data.data);
            });
        }

    }, [params.id, row?.id]);

    function onFinishContract(values) {
        let data = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != ""));
        data['attributes'] = attrForm.getFieldsValue()

        if (atol && row.external_id) {
            new AtolService().update(row.external_id, data).then((r) => {
                props.setIsModal(false);
            }).catch(AtolService.error_handler);
        } else {
            X.Contragent(contragent).Contract(row?.id).update(data).then((r) => {
                props.setIsModal(false);
            }).catch(ContractService.error_handler);
        }
    }

    return (<>
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinishContract}
            // {...props}
        >
            <Form.Item label="Тип договора" name="type">
                <Select options={types}/>
            </Form.Item>
            <Form.Item label="Статус договора" name="status">
                <Select options={statuses}/>
            </Form.Item>
            <Form.Item label="Дата начала договора" name="start_date">
                <DatePicker format="DD.MM.YYYY"/>
            </Form.Item>
            <Form.Item label="Дата окончания договора" name="end_date">
                <DatePicker format="DD.MM.YYYY"/>
            </Form.Item>
            <Form.Item label="Реферальная ссылка" name="ref_code">
                {/* <Input disabled/> */}
                <Input/>
            </Form.Item>
            <Form.Item label="Процент выплаты агенту" name="agency_comission">
                <Input/>
            </Form.Item>
        </Form>
        {!dictionaries.length && <Form.Item>
                <Typography>Расширьте информацию в <Link to='/dictionary'>справочнике</Link></Typography>
            </Form.Item>}
        {dictionaries.map(el => {
            return <AttributesForm id={el.codename}
                                    attributes={el.attributes}
                                    form={attrForm}
            />
        })}
        </>
    );
}

export function ContractAtolForm({form, onFinish, seller, type, ...props}) {
    // форма создания договора (Атол)
    const [buyers, setBuyers] = useState([]);
    const [types, setTypes] = useState([]);
    const [typeContract, setTypeContract] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const params = useParams();
    const {is_vendor: is_vendor_user} = useUser();
    const {contragent, is_vendor: is_vendor_ctg} = useContragent();
    const [dictionaries, setDictionaries] = useState([]);
    const [attrForm] = useForm();

    function _onFinish(values) {
        const map_buyers = Object.fromEntries(buyers.map(x => [x.id, x.external_id]));
        let data = {...values};
        data['attributes'] = attrForm.getFieldsValue()

        if (is_vendor_ctg()) {
            if (data.contragent_external_id in map_buyers)
                data.contragent_external_id = map_buyers[data.contragent_external_id];
        } else {
            data.contragent_external_id = contragent.external_id;
        }
        new AtolService().new_contract(data).then((r) => {
            onFinish && onFinish();
        }).catch(AtolService.create_error_handler("Невозможно создать договор"));
    }

    useEffect(() => {
        const ctrsrv = Contragent(contragent?.id).Contract();
        const ctgsrv = Contragent();
        form.resetFields();
        Promise.all([
            ctgsrv.list(),
            ctrsrv.listStatuses(),
            ctrsrv.listTypes(),
        ]).then(([ctgs, sttss, tps]) => {
            setBuyers(ctgs.data.data);
            setTypes(tps.data.data.filter(x => (x.id === type) || !type));
            setStatuses(sttss.data.data);
        });

        X.Dictionary().getDictsByEntity("contracts").then(res => {
            setDictionaries(res.data.data);
        });
    }, [params.id, seller]);

    useEffectOnce(() => {
        if (!is_vendor_user()) // если мы работаем НЕ от лица АТОЛ, тогда поставим себя в отключенный select
            form.setFieldsValue({contragent_external_id: contragent.id});
    });

    return (
        (!contragent.external_id && !is_vendor_ctg()) ?
            <Typography>У контрагента нет внешнего ID. Продолжить невозможно!</Typography> :
            <>
                <Form layout="vertical"
                      form={form}
                      onFinish={_onFinish}
                      {...props}>
                    {(is_vendor_user()) &&
                        <Form.Item label="Контрагент" name="contragent_external_id" rules={[required]}>
                            <Select>
                                {buyers.map(el => <Select.Option value={el.external_id || el.id}
                                                                 key={el.external_id || el.id}>
                                    {el.name}
                                </Select.Option>)}
                            </Select>
                        </Form.Item>}
                    <Form.Item label="Внешний ID договора (из 1С)" name="contract_external_id" rules={[required]}>
                        <Input type="text"/>
                    </Form.Item>
                    <Form.Item label="Номер договора" name="custom_number">
                        <Input type="text"/>
                    </Form.Item>
                    <Form.Item label="Тип договора" name="type" rules={[required]}>
                        <Select onChange={setTypeContract}>
                            {types.map(t => (
                                <Select.Option value={t.id} key={t.id}>
                                    {t.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Статус договора" name="status" rules={[required]}>
                        <Select options={statuses}/>
                    </Form.Item>
                    <Form.Item label="Дата начала договора" name="start_date" rules={[required]}>
                        <DatePicker format="DD.MM.YYYY"/>
                    </Form.Item>
                    <Form.Item label="Дата окончания договора" name="end_date">
                        <DatePicker format="DD.MM.YYYY"/>
                    </Form.Item>
                </Form>
                {!dictionaries.length && <Form.Item>
                        <Typography>Расширьте информацию в <Link to='/dictionary'>справочнике</Link></Typography>
                    </Form.Item>}
                {dictionaries.map(el => {
                    return <AttributesForm id={el.codename}
                                            attributes={el.attributes}
                                            form={attrForm}
                    />
                })}
            </>
    );
}

