import moment from "moment";

import React, {useEffect, useState} from "react";
import useContragent from "src/Components/Contragents/ContragentContext";

import useUser from "src/Providers/UserProvider";
import useIntegration from "src/Providers/IntegrationsProvider";

import {DatePicker, Form, Input, Modal, notification, Select, Typography} from "antd";
import {useForm} from "antd/es/form/Form";

import {X} from "src/ApiService/Query";
import {AtolX} from "src/ApiService/AtolQuery";


export function BlockingModal({targetObject, visible, setModalVisible, blocking = null, ...props}) {
    const [types, setTypes] = useState([]);
    const [form] = useForm();
    const {time, contragent} = useUser();
    const {atol} = useIntegration();
    const [isFreezee, setIsFreeze] = useState(false);

    // const {contragent} = useContragent();


    useEffect(() => {
        X.BlockingType().list().then(res =>
            setTypes(res.data.data),
        );
    }, []);

    function create() {
        const formValues = form.getFieldsValue();

        if (atol && isFreezee) {
            const freeze_data = {
                contragent_id: targetObject.id,
                btype: formValues.type,
                activate_date: formValues.activate_date?.utcOffset(0, true).format(),
                cancel_date: formValues.cancel_date?.utcOffset(0, true).format(),
                months: formValues.months,
                comment: formValues.comment || null,
            };

            AtolX.Blocking().create(freeze_data).then(response => {
                props.onSuccess();
                if (typeof props.setTableUpdated === "function") {
                    props.setTableUpdated(!props.tableUpdated);
                }
                notification.success({
                    message: `Заказ на заморозку создан.`,
                });
            });
        } else {
            const data = {
                target_object: targetObject.id,
                btype: formValues.type,

                activate_date: formValues.activate_date?.utcOffset(0, true).format(),
                cancel_date: formValues.cancel_date?.utcOffset(0, true).format(),
                comment: formValues.comment,
            };
            X.Contragent(contragent).Blocking().create(data).then(response => {
                props.onSuccess();
                if (typeof props.setTableUpdated === "function") {
                    props.setTableUpdated(!props.tableUpdated);
                }
                notification.success({
                    message: `Блокировка установлена.`,
                });
            });
        }
    }

    function edit() {
        const formValues = form.getFieldsValue();

        const data = {
            activate_date: formValues.activate_date,
            cancel_date: formValues.cancel_date,
            comment: formValues.comment,
        };
        X.Contragent(contragent).Blocking(blocking.id).update(data).then(response => {
            props.onSuccess();
            notification.success({
                message: `Блокировка изменена.`,
            });
        });
    }

    function changeMonths(e) {
        const formValues = form.getFieldsValue();
        const cancel_date = moment(formValues.activate_date);
        form.setFieldsValue({"cancel_date": cancel_date.add(e.target.value, "months")});
    }

    const title = blocking ? "Редактировать" : "Заблокировать объект";

    function handleFreeze(typeValue) {
        const currentTypeName = types.filter(t => t.id === typeValue)[0].name;
        if (currentTypeName.includes("аморозк"))
            setIsFreeze(true);
        else
            setIsFreeze(false);
    }

    return (
        <Modal title={title} visible={visible}
               onOk={() => blocking ? edit() : create()}
               onCancel={() => props.onCancel()}>

            <Form
                form={form}
                layout="vertical"
                initialValues={blocking ? {
                    type: blocking.btype,
                    activate_date: moment(blocking.activate_date),
                    cancel_date: blocking.cancel_date && moment(blocking.cancel_date),
                    // cancel_date: 1,
                    comment: blocking.comment,
                } : {activate_date: time}
                }
            >
                {!blocking &&
                    <Form.Item label="Тип блокировки" name="type">
                        <Select onChange={handleFreeze}>
                            {types.map(t => <Select.Option value={t.id}>{t.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                }
                <Form.Item label="Дата начала" name="activate_date">
                    <DatePicker/>
                </Form.Item>

                <Form.Item label="Дата окончания" name="cancel_date">
                    <DatePicker disabled={isFreezee}/>
                </Form.Item>

                {(atol && isFreezee) &&
                    <Form.Item label="Количество месяцев" name="months"
                               onChange={changeMonths}>
                        <Input type="number"/>
                    </Form.Item>
                }

                <Form.Item label="Комментарий" name="comment">
                    <Input/>
                </Form.Item>

                {isFreezee && <Typography.Text strong>Будет создан заказ на предоплату.</Typography.Text>

                }
            </Form>
        </Modal>
    );
}
