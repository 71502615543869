import {X} from "app/ApiService/Query";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    List,
    Modal,
    notification,
    Radio,
    Row,
    Select,
    Skeleton,
    Space,
    Typography,
} from "antd";
import {EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {ConditionOperatorSelect, GenerateInput, SelectConditionalField} from "src/Components/Rules/Components/RuleCard";
import useUser from "src/Providers/UserProvider";
import ContractSelect from "src/Base/Inputs/ContractSelect";


export function DiscountForm({item, onFinish, ...props}) {

    const [conditionModal, setConditionModal] = useState({visible: false});
    const [promocodeModal, setPromocodeModal] = useState({visible: false});
    const [generatePromocodeModal, setGeneratePromocodeModal] = useState({visible: false});
    const [expressions, setExpressions] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [operators, setOperators] = useState([]);
    const [offers, setOffers] = useState([]);
    const [packages, setPackages] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [level, setLevel] = useState("order");
    const [typePay, setTypePay] = useState("percent");
    const [loading, setLoading] = useState(true);

    const {contragent} = useUser();


    useEffect(() => {
        setLoading(true);
        Promise.all([
            X.Contragent(contragent).Discount().options(),
            X.Contragent(contragent).Contract().list(),
            X.Contragent(contragent).getOffers(),
            // new ContragentService(contragent.id).getPackages() - будет позже, когда АПИ будет смержено
            X.Contragent(contragent).Package().list(),
        ]).then(([meta, ctrcts, ofrs, pckgs]) => {
            setExpressions(meta.data.expressions);
            setOperators(meta.data.operators);
            setInputs(meta.data.inputs);
            setContracts(ctrcts.data.data);
            setOffers(ofrs.data.data);
            setPackages(pckgs.data.data);
            item && item.offer ? setLevel("offer") : item?.package ? setLevel("package") : setLevel("order");
            item && item.type_pay && setTypePay(item.type_pay);
            setLoading(false);
        });
    }, []);


    function onCreateOrUpdate(data) {
        X.Contragent(contragent).Discount(item?.id).update_or_create(data).then(res => {
            typeof onFinish === "function" && onFinish(data);
        });
    }

    if (loading) {
        return Array(3).fill(1).map(el => (
            <Skeleton key={el}/>
        ));
    }

    return (
        <Form id="discount" layout="vertical" onFinish={(d) => onCreateOrUpdate(d)} {...props}>
            <Form.Item name="name" label="Наименование" rules={[
                {required: true, message: "Необходимое поле"},
            ]}>
                <Input/>
            </Form.Item>
            <Form.Item name="contract"
                       label="Вендор"
                       initialValue={contracts.length !== 0 ? contracts[0]?.id : null}
                       rules={[
                           {required: true, message: "Необходимое поле"},
                       ]}>
                <ContractSelect contracts={contracts}/>
            </Form.Item>
            <Form.Item name="description" label="Описание">
                <Input.TextArea/>
            </Form.Item>
            <Row gutter={16}>
                <Col>
                    <Form.Item name="type_pay"
                               label="Тип значения"
                               initialValue={"percent"}
                               onChange={(v) => setTypePay(v.target.value)}
                               rules={[
                                   {required: true, message: "Необходимое поле"},
                               ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="percent">Процент</Radio.Button>
                            <Radio.Button value="money">Сумма</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col>
                    {typePay === "percent" &&
                        <Form.Item name="value" label="Процент" rules={[
                            {required: true, message: "Необходимое поле"},
                        ]}>
                            <InputNumber max={100} min={1}/>
                        </Form.Item>
                    }
                    {typePay === "money" &&
                        <Form.Item name="value" label="Сумма" rules={[
                            {required: true, message: "Необходимое поле"},
                        ]}>
                            <Input/>
                        </Form.Item>
                    }
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Form.Item label="Уровень применения">
                        <Radio.Group value={level} onChange={(d) => setLevel(d.target.value)}>
                            <Radio.Button value="order">К заказу</Radio.Button>
                            <Radio.Button value="offer">К позиции заказа</Radio.Button>
                            <Radio.Button value="package">К пакету</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col flex="1">
                    {level === "offer" &&
                        <Form.Item name="offer" label="Ценовое предложение" rules={[
                            {required: true, message: "Необходимое поле"},
                        ]}>
                            <Select>
                                {offers.map(el => (
                                    <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }
                    {level === "package" &&
                        <Form.Item name="package" label="Пакет" rules={[
                            {required: true, message: "Необходимое поле"},
                        ]}>
                            <Select>
                                {packages.map(el => (
                                    <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }
                </Col>
            </Row>
            <Row gutter={8}>
                <Col>
                    <Form.Item name="date_start" label="Дата начала">
                        <DatePicker/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name="date_end" label="Дата окончания">
                        <DatePicker/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="is_combine" label="Комбинируется?" initialValue={true}>
                <Radio.Group>
                    <Radio.Button value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Card size="small" title="Условия применения" extra={
                    <Button type="primary" size="small" // onClick={() => add()}
                            onClick={() => setConditionModal({visible: true})}
                            block icon={<PlusOutlined/>}>

                    </Button>
                }>
                    <Form.List name="conditions">
                        {(fields, {add, remove}) => (
                            <>
                                <ConditionList form={props.form} fields={fields} rm={remove}
                                               onClickEdit={(d, index) => {
                                                   setConditionModal({visible: true, item: d, index});
                                               }}
                                />
                                <ConditionModal
                                    destroyOnClose
                                    visible={conditionModal?.visible}
                                    item={conditionModal?.item}
                                    expressions={expressions}
                                    operators={operators}
                                    offers={offers}
                                    packages={packages}
                                    inputs={inputs}
                                    onSelect={(d) => {
                                        remove(conditionModal.index);
                                        add(d);
                                        setConditionModal({visible: false});
                                    }}
                                    onCancel={() => setConditionModal({visible: false})}
                                />
                            </>
                        )}
                    </Form.List>
                </Card>
            </Form.Item>
            <Form.Item>
                <Card size="small" title="Промокоды/Купоны" extra={
                    <Space>
                        <Button type="primary"
                                size="small"
                                onClick={() => setGeneratePromocodeModal({visible: true})}>Сгенерировать</Button>
                        <Button type="primary"
                                onClick={() => setPromocodeModal({visible: true})}
                                size="small" icon={<PlusOutlined/>}
                        />
                    </Space>
                }>
                    <Form.List name="promocodes">
                        {(fields, {add, remove}) => (
                            <>
                                <PromocodeList form={props.form} fields={fields} rm={remove}
                                               onClickEdit={(d, index) => {
                                                   setPromocodeModal({visible: true, item: d, index});
                                               }}
                                />
                                <PromocodeModal
                                    destroyOnClose
                                    visible={promocodeModal?.visible}
                                    item={promocodeModal?.item}
                                    onSelect={(d) => {
                                        remove(promocodeModal.index);
                                        add(d);
                                        setPromocodeModal({visible: false});
                                    }}
                                    onCancel={() => setPromocodeModal({visible: false})}
                                />
                                <GeneratePromocodeModal
                                    visible={generatePromocodeModal?.visible}
                                    onCancel={() => setGeneratePromocodeModal({visible: false})}
                                    onSelect={(promocodes) => {
                                        promocodes.map(add);
                                        setGeneratePromocodeModal({visible: false});
                                    }}
                                />
                            </>
                        )}
                    </Form.List>
                </Card>
            </Form.Item>
        </Form>
    );
}

function ConditionList({fields, onClickEdit, form, rm}) {

    let values = form.getFieldValue("conditions");

    return (
        <List
            size="small"
            bordered
            dataSource={fields}
            renderItem={field => {
                return (
                    <List.Item {...field} actions={[
                        <Typography.Text type="secondary">{values[field.name]?.id}</Typography.Text>,
                        <EditOutlined
                            onClick={() => {
                                typeof onClickEdit === "function" && onClickEdit(values[field.name], field.name);
                            }}
                        />,
                        <MinusCircleOutlined onClick={() => {
                            rm(field.name);
                        }}/>,
                    ]}>
                        <Space>
                            <Typography.Text code strong>{values[field.name].field}</Typography.Text>
                            <Typography.Text code>{values[field.name].operator}</Typography.Text>
                            <Typography.Text code>{values[field.name].value}</Typography.Text>
                        </Space>
                    </List.Item>
                );
            }}
        />
    );
}

function ConditionModal({onSelect, item, inputs, operators, expressions, offers, packages, ...props}) {

    const [input, setInput] = useState(null);
    const [operator, setOperator] = useState(null);
    const [form] = Form.useForm();

    function onChangeOperator(d) {
        setOperator(d);
    }

    useEffect(() => {
        form.resetFields();
        if (item) {
            form.setFieldsValue(item);
        }
    }, [item]);

    return (
        <Modal destroyOnClose title={item ? "Изменить условие" : "Добавить условие"} {...props} footer={
            <Button form="condition_form" htmlType="submit">{item ? "Изменить" : "Добавить"}</Button>
        }>
            <Form form={form} id="condition_form" layout="vertical" onFinish={(d) => onSelect(d)}>
                <Form.Item name="id" hidden>
                    <Input/>
                </Form.Item>
                <Form.Item name="offer" label={"Ценовое предложение"}>
                    <Select>
                        {offers.map(el => (
                            <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="package" label={"Пакет"}>
                    <Select>
                        {packages.map(el => (
                            <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="field" label={"Поле"}>
                    <SelectConditionalField expressions={expressions} onSelectInput={setInput}/>
                </Form.Item>
                {
                    <Form.Item name="operator" label={"Оператор"}>
                        <ConditionOperatorSelect input={input}
                                                 operators={operators}
                                                 inputs={inputs}
                                                 onChange={onChangeOperator}
                        />
                    </Form.Item>
                }
                {<Form.Item name="value" label={"Значение"}>
                    <GenerateInput
                        input={input}
                        condition={operator}
                    />
                </Form.Item>}
            </Form>
        </Modal>
    );
}

function PromocodeList({fields, onClickEdit, form, rm}) {

    let values = form.getFieldValue("promocodes");

    return (
        <List
            size="small"
            bordered
            dataSource={fields}
            renderItem={field => {
                return (
                    <List.Item {...field} actions={[
                        <Typography.Text type="secondary">{values[field.name]?.id}</Typography.Text>,
                        <EditOutlined
                            onClick={() => {
                                typeof onClickEdit === "function" && onClickEdit(values[field.name], field.name);
                            }}
                        />,
                        <MinusCircleOutlined onClick={() => {
                            rm(field.name);
                            // setEdited(true);
                        }}/>,
                    ]}>
                        <Space>
                            <Typography.Text code strong copyable>{values[field.name].name}</Typography.Text>
                            <Divider type="vertical"/>
                            <Typography.Text>Реферальный код:
                                <Typography.Text code
                                                 copyable>{`priceplan.ru/si/${values[field.name].name}`}</Typography.Text>
                            </Typography.Text>
                            <Divider type="vertical"/>
                            <Typography.Text>C лимитом: </Typography.Text>
                            {values[field.name].limit ?
                                <Typography.Text code>{values[field.name].limit}</Typography.Text> :
                                <Typography.Text code>∞</Typography.Text>}
                            {values[field.name].count_used !== undefined && <>
                                <Typography>С количеством применений:</Typography>
                                <Typography.Text code>{values[field.name].count_used}</Typography.Text>
                            </>}
                        </Space>
                    </List.Item>
                );
            }}
        />
    );
}

function PromocodeModal({item, onSelect, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        if (item) {
            form.setFieldsValue(item);
        }
    }, [item]);

    return (
        <Modal title={item ? "Изменить промокод" : "Добавить промокод"} {...props} footer={
            <Button form="promocode_form" htmlType="submit">{item ? "Изменить" : "Добавить"}</Button>
        }>
            <Form form={form} id="promocode_form" layout="vertical" onFinish={(d) => onSelect(d)}>
                <Form.Item hidden name="id">
                    <Input/>
                </Form.Item>
                <Form.Item name="name" label="наименование">
                    <Input/>
                </Form.Item>
                <Form.Item name="limit" label="Лимит">
                    <InputNumber min={0}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

function GeneratePromocodeModal({onSelect, ...props}) {
    const {contragent} = useUser();
    const [promocodes, setPromocodes] = useState([]);


    function onGenerate(data) {
        X.Contragent(contragent).Discount().generatePromocodes(data).then(res => {
            setPromocodes(res.data.data);
        });
    }

    function onChoosePromocodes() {
        if (!promocodes.length) {
            notification.warning({message: "Пустой список промокодов", description: "Необходимо сгенерировать"});
            return;
        }
        typeof onSelect === "function" && onSelect(promocodes);
    }

    return (
        <Modal title={"Сгенерировать промокоды"} {...props} footer={
            <Space>
                <Button form="generate_promocode_form" htmlType="submit">Сгенерировать</Button>
                <Button type="primary" onClick={onChoosePromocodes}>Применить</Button>
            </Space>
        }>
            <Form id="generate_promocode_form" layout="vertical" onFinish={onGenerate}>
                <Form.Item name="size" label="Количество символов" initialValue={6}>
                    <InputNumber min={6} max={31}/>
                </Form.Item>
                <Form.Item name="count" label="Сколько сгенерировать?" initialValue={1}>
                    <InputNumber
                        min={1}
                        max={100}
                    />
                </Form.Item>
                <Form.Item name="limit" label="С лимитом" help={"без указания - без лимита"}>
                    <InputNumber min={1}/>
                </Form.Item>
                {!!promocodes.length &&
                    <Form.Item>
                        <Collapse>
                            <Collapse.Panel key="preview" header="Предпросмотр">
                                <List
                                    size="small"
                                    bordered
                                    dataSource={promocodes}
                                    renderItem={(field, index) => {
                                        return (
                                            <List.Item key={index} extra={
                                                <Typography.Text
                                                    type="secondary">{field.limit ? field.limit : "∞"}</Typography.Text>
                                            }>
                                                {field.name}
                                            </List.Item>
                                        );
                                    }}
                                />
                            </Collapse.Panel>
                        </Collapse>
                    </Form.Item>
                }
            </Form>
        </Modal>
    );
}
