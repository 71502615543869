import {ObjectService} from "./Object";

class PackageService extends ObjectService {
    MODEL = 'package'

    calcProlongDate(params) {
        return this._get('calc_prolong_date', {params})
    }

    getStatuses() {
        return this._get('status')
    }

    getContragents() {
        return this._get('contragent')
    }

    updateContragents(data) {
        return this._patch('contragent', data)
    }

    getFreeConts() {
        return this._get('free-conts')
    }

    getPrices() {
        return this._get(`offer`)
    }

    patchAttributesValue(data) {
        try {
            this._check_id()
            return this._patch(`attribute`, data)
        } catch (err) {
            return this._patch('attribute', data)
        }
    }

    getTaxes() {
        return this._get(`taxes`)
    }

    getAttributesValue() {
        return this._get("attribute")
    }

    deletePrice(id) {
        this._check_id()
        return this._delete(`offer/${id}`)
    }

    patchPrice(price_id, data) {
        return this._patch(`offer/${price_id}`, data)
    }

    createPrice(data) {
        return this._post(`offer`, data)
    }

    createOrUpdatePrice(price_id, data) {
        if (price_id)
            return this.patchPrice(price_id, data)
        else
            return this.createPrice(data)
    }
}


export {
    PackageService,
}
