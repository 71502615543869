import {
    AtolBlockingService,
    AtolContragentService, AtolFullReportService, AtolImportService,
    AtolRevenueService,
    AtolSubscribeGroupService,
    AtolSubscribeService
} from "app/ApiService/Atol";
import {AtolPayService} from "app/ApiService/Integration";
import {Parent, ParentizeService, PropertyMixin, Query, Servicize} from "app/ApiService/Query";
import {RevenueItemService} from "app/ApiService/Revenue";
import {AtolFullReportTransactionService} from "app/ApiService/Atol";


function Contragent(contragent=null) {
    // @formatter:off
    class ExContragent extends PropertyMixin(AtolContragentService) {
        get Service() {return ParentizeService(this, AtolSubscribeService);}
        get ServiceGroup() {return ParentizeService(this, AtolSubscribeGroupService);}
        get Blocking() {return ParentizeService(this, AtolBlockingService);}
        get Revenue() {return ParentizeService(this, Revenue);}
    }
    // @formatter:on

    return Parent(
        contragent?.external_id ||
            contragent?.id ||
            contragent,
        ExContragent);
}

function Revenue(revenue=null) {
    class RevenueEx extends AtolRevenueService {
        get Items() {return ParentizeService(this, RevenueItemService);}
    }
    return Parent(revenue, RevenueEx);
}


function AtolQuery() {
    return {
        ...Query(),
        Contragent,
        Pay: Servicize(AtolPayService),
        Revenue: Servicize(AtolRevenueService),
        Blocking: Servicize(AtolBlockingService),
        Import: Servicize(AtolImportService),
        FullReport: Servicize(AtolFullReportService),
        FullReportTransactinos: Servicize(AtolFullReportTransactionService),
        Service: Servicize(AtolSubscribeService),
        ServiceGroup: Servicize(AtolSubscribeGroupService),
    };
}

const AtolX = AtolQuery();


export {
    AtolX,
    AtolQuery
}
