import {ObjectService} from "./Object";


export class RevenueItemService extends ObjectService {
    MODEL = "items";
}

export default class RevenueService extends ObjectService {
    MODEL = "revenue";
    ITEMS = "items";

    add_item(data) {
        return this._post(this.ITEMS, data);
    }

    get_items(contragent) {
        return this._get(this.ITEMS, {params: {contragent}});
    }

    add_items(ids) {
        return this._patch({items: ids});
    }

    create_from_unjoined(ids) {
        return this._post("create-from-unjoined", {items: ids});
    }

    create_full_period(subscribe_id) {
        return this._post("create-from-subscribe", {subscribe: subscribe_id});
    }

    realize() {
        return this._post("status", {status: "active"});
    }

    send_again() {
        return this._post("send")
    }

    get_documents() {
        return this._get("documents");
    }

    get_zip_file() {
        const file_url = this._mk_res("zip-file");

        this._get("zip-file", {responseType: "blob"}).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${file_url.split("/").at(-1)}.zip`);
            document.body.appendChild(link);
            link.click();
        });
    }

    static error_handler(response, message = "Ошибка создания реализации") {
        return super.error_handler(response, message);
    }

    static warning_handler(response, message = "Реализация. Обратите внимание") {
        return super.error_handler(response, message, "warning");
    }

    static warning_or_error_handler(response) {
        if (response?.response)
            response = response.response;

        if (response.data.status === "warning")
            return RevenueService.warning_handler(response);
        else
            return RevenueService.error_handler(response);
    }
}


export class TemporaryRevenueService extends RevenueService {
    MODEL = "tmp-revenue";

    add_item(order_item) {
        return this._post("additem", order_item);
    }
}
