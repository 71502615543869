import {AttributesProperty} from "app/ApiService/Dictionary";
import BaseService from "app/ApiService/Base";

export
class ObjectService extends BaseService {
    constructor(id) {
        super(id);
    }

    set_id(id) {
        super.set_id(id);
        this.attributes.set_id(this.get_id());
    }

    get attributes() {
        return new AttributesProperty(this);
    }
}
