import React, {useEffect, useState} from 'react';
import {Button, Form, message, Skeleton} from 'antd';

import {useForm} from 'antd/es/form/Form';
import Modal from 'src/Base/Modals/Modal';
import useContragent from '../ContragentContext';
import {DocumentForm} from '../Forms/DocumentCreateForm';
import {X} from "app/ApiService/Query";


const TYPES = {
    bill: 'УПД',
    reconciliation: 'Акт сверки',
    invoice: 'Платёжное поручение'
}

export default function DocumentCreateModal({visible, updated, ...props}) {
    const [form] = useForm();

    return (
        <Modal btnname='Создать'
               onSuccess={() => {
                   form.submit();
                   (typeof updated === 'function') && updated();
               }}
            // title={`Создать ${docType?TYPES[docType]:""}`}
            // visible={operVisible}
            // onCancel={()=>setOperVisible(false)}
               {...props}>
            {/* { (docType=='reconciliation') &&
          <ReconCreateForm form={form} setIsModal={props.setIsModal}/> ||
        (docType=='bill') &&
          <BillCreateForm form={form} setIsModal={props.setIsModal}/> ||
        (docType=='invoice') &&
          <InvoiceCreateForm form={form} setIsModal={props.setIsModal}/> } */}
        </Modal>
    )
}

export function DocumentModal({item, ...props}) {
    const {contragent} = useContragent();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (item) {
            X.Contragent(contragent).Document(item.id).get().then(res => {
                form.setFieldsValue(res.data);
                setLoading(false);
            })
        }
    }, [item])

    function onUpdateDocument(data) {
        X.Contragent(contragent).Document(item.id).update(data).then(res => {
            setLoading(false);
            message.success('Документ обновлен');
            props?.onCancel();
        })
    }

    return (
        <Modal {...props} footer={
            <Button type='primary' htmlType='submit' form='document_form'>Сохранить</Button>
        }>
            {loading ? <Skeleton/> : <DocumentForm form={form} id='document_form' onFinish={onUpdateDocument}/>}
        </Modal>
    )
}

