import {BaseService} from "app/ApiService/Base";


class MailService extends BaseService {
    MODEL = 'mail';
}

export {
    MailService,
}
