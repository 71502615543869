import {ObjectService} from "./Object";


export default class TemplateService extends ObjectService {
    MODEL = 'text-template'

    getTemplateAPI() {
        return this.get()
    }

    getRenderedTemplate(data) {
        return this._post('rendered', data)
    }

    getPlaceholders() {
        return this._get("attribute")
            .catch(this.error_handler);
    }

}
