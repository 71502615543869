import React, {useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined, EditOutlined, PoweroffOutlined, UploadOutlined} from "@ant-design/icons";
import {
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    Layout,
    Menu,
    message,
    Modal,
    notification,
    Radio,
    Select,
    Skeleton,
    Space,
    Spin,
    Switch as SwitchButton,
    Table,
    Tag,
    Typography,
    Upload,
} from "antd";
import {Link, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {AtolPService} from "src/ApiService/Atol";
import {Buh1CService, KeycloakService, S3Service, SMTPCService} from "app/ApiService/Integration";
import {useForm} from "antd/es/form/Form";
import useSystemInfo from "../../Providers/SystemProvider";
import useUser from "../../Providers/UserProvider";
import useIntegration from "../../Providers/IntegrationsProvider";
import {NumeratorModal} from "./Modals/NumeratorModal";
import {X} from "app/ApiService/Query";
import {AtolX} from "app/ApiService/AtolQuery";


export function Settings() {
    let location = useLocation();

    const {contragent} = useUser();
    const {integrations} = useIntegration();

    return <Layout>
        <Card title={`Раздел настроек контрагента ${contragent?.name}`}>
            <Layout>
                <Layout.Sider width="300">
                    <Menu
                        defaultSelectedKeys={["/contragents"]}
                        selectedKeys={[location.pathname]}
                    >
                        <Menu.ItemGroup title='Общее'>
                            <Menu.Item key="general">
                                <Link to={`/settings/general`}>
                                    Общие настройки
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="numerator">
                                <Link to={`/settings/numerator`}>
                                    Нумераторы
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="import">
                                <Link to={`/settings/import`}>
                                    Импорт
                                </Link>
                            </Menu.Item>
                        </Menu.ItemGroup>
                        <Menu.ItemGroup title='Интеграции'>
                            {Object.entries(integrations).map(([key, value]) => (
                                    <Menu.Item key={`/settings/${value?.codename}`}
                                                icon={<Tag
                                                    color={value?.enable ? "green" : "red"}><PoweroffOutlined/></Tag>}>
                                        <Link to={`/settings/${value?.codename}`}>
                                            {value?.name}
                                        </Link>
                                    </Menu.Item>
                                ))
                            }
                        </Menu.ItemGroup>
                    </Menu>
                </Layout.Sider>
                <Layout.Content style={{padding: "0 24px", minHeight: 280, background: "#fff"}}>
                    <Switch>
                        <Route strict path="/settings/general">
                            <GeneralSettings/>
                        </Route>
                        <Route strict path="/settings/numerator">
                            <NumeratorSettings />
                        </Route>
                        <Route strict path="/settings/atolpay.AtolPay">
                            <AtolPayModule/>
                        </Route>
                        <Route strict path={"/settings/edo.Edo"}>
                            <EdoModule/>
                        </Route>
                        <Route strict path="/settings/import">
                            <ImportDataComponent/>
                        </Route>
                        <Route strict path="/settings/smtp.SMTP">
                            <SMTPSettings/>
                        </Route>
                        <Route strict path="/settings/s3.S3">
                            <S3Settings/>
                        </Route>
                        <Route strict path="/settings/keycloak.KeycloakConfig">
                            <KeycloakSettings/>
                        </Route>
                        <Route strict path="/settings/buh_1c.BuhConfig">
                            <Settings1C />
                        </Route>
                        {/*
                        <Route strict path="/settings/personal">
                            <LkSettings/>
                        </Route>
                         */}
                        <Route strict path="/settings">
                            <Redirect to="/settings/general"/>
                        </Route>
                    </Switch>
                </Layout.Content>
            </Layout>
        </Card>
    </Layout>;
}

function GetImportRecordsAsCsv() {
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const atolImportService = AtolX.Import();

    const handleStartDateTimeChange = (dateTime) => {
        setStartDateTime(dateTime);
    };

    const handleEndDateTimeChange = (dateTime) => {
        setEndDateTime(dateTime);
    };

    const handleDownloadCSV = () => {
        if (!startDateTime || !endDateTime) {
            message.error('Укажите даты');
            return;
        }

        const startDateTimeStr = startDateTime.format('YYYY-MM-DD');
        const endDateTimeStr = endDateTime.format('YYYY-MM-DD');

        atolImportService.get_csv({ start_datetime: startDateTimeStr, end_datetime: endDateTimeStr })
            .catch(error => {
                message.error('Ошибка при загрузке CSV');
            });
    };

    return (
        <Card size="small" title='Экспорт данных импорта в CSV'>
            <Form.Item>
                <Space>
                    <DatePicker format="YYYY-MM-DD" onChange={handleStartDateTimeChange} />
                    <Typography.Text> - </Typography.Text>
                    <DatePicker format="YYYY-MM-DD" onChange={handleEndDateTimeChange} />
                </Space>
            </Form.Item>
            <Button type="primary" onClick={handleDownloadCSV}>
                Скачать CSV
            </Button>
        </Card>
    );
}

export function ImportDataComponent() {
    const [file, setFile] = useState(null);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [isLoadingOrders, setIsLoadingOrders] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');

    function upload(dataType) {
        if (dataType === 'products') {
            setIsLoadingProducts(true);
        } else if (dataType === 'orders') {
            setIsLoadingOrders(true);
        }

        X.Setting().importData({
            file: file,
            label: dataType,
        }).then(res => {
            message.success('Импорт данных запущен.');
            if (dataType === 'orders') {

                if (res.data && res.data.ca_list){
                    const results = Object.entries(res.data.ca_list).map(([key, value]) => `${key}: ${value}`).join('\n');
                    setModalContent(results);
                    setIsModalVisible(true);
                }
            }
        }).catch(error => {
            message.error('Произошла ошибка при импорте данных.');
        }).finally(() => {
            if (dataType === 'products') {
                setIsLoadingProducts(false);
            } else if (dataType === 'orders') {
                setIsLoadingOrders(false);
            }
        });
    }

    return (<Form>
            <Form.Item>
                <Spin spinning={isLoadingProducts}>
                    <Card size='small' title='Импорт Продуктов и Ценовых предложений'>
                        <Form.Item>
                            <Upload accept=".csv" onChange={({ file }) => setFile(file.originFileObj)}>
                                <Button icon={<UploadOutlined />}>Выбрать файл</Button>
                            </Upload>
                        </Form.Item>
                        <Button onClick={() => upload('products')} disabled={isLoadingProducts}>
                            Загрузить
                        </Button>
                    </Card>
                </Spin>
            </Form.Item>
            <Form.Item>
                <Spin spinning={isLoadingOrders}>
                    <Card size='small' title='Импорт Заказов и Подписок'>
                        <Form.Item>
                            <Upload accept=".csv" maxCount={1} action={''} customRequest={false} onChange={({ file }) => setFile(file.originFileObj)}>
                                <Button icon={<UploadOutlined />}>Выбрать файл</Button>
                            </Upload>
                        </Form.Item>
                        <Button onClick={() => upload('orders')} disabled={isLoadingOrders}>
                            Загрузить
                        </Button>
                    </Card>
                </Spin>
            </Form.Item>

            <GetImportRecordsAsCsv />

            <Modal
                size='large'
                title="Результаты загрузки заказов"
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={800} // Устанавливает ширину модального окна
                style={{ top: 20 }} // Смещает модальное окно вниз
            >
                <pre>{modalContent}</pre>
            </Modal>
    </Form>);
}

function GeneralSettings() {

    // const [settings, setSettings] = useState([]);
    const [form] = useForm();
    const {system} = useSystemInfo();
    const {settings} = useUser();

    useEffect(() => {
        form.setFieldsValue(settings);
    }, []);

    function onFinish(data) {
        const stt = X.Setting();
        stt.update(data).then(r => {
            notification.success({message: 'Настройки сохранены'});
        }).catch(r => {
            notification.error({
                "message": "Ошибка",
                "description": r.response.data?.msg,
            });
        });
    }


    return <Form form={form} id='general_settings' layout='vertical' onFinish={onFinish}>
        <Form.Item name='billing_domain' label='Основной URL биллинга'>
            <Input type='url' />
        </Form.Item>
        <Form.Item name='atol_url_lk_service' label='Хост ЛК'>
            <Input type='url' />
        </Form.Item>
        <Form.Item name='close_day_time' label='Время закрытия дня'>
            <Input />
        </Form.Item>
        <Form.Item name='close_day_tz' label='Временная зона GMT'>
            <Input />
        </Form.Item>
        <Form.Item name='account_tag_unmatch' label='Отображать ошибку, если теги счетов не совпали'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item name='bill_on_comply' label='Выставлять УПД на исполнение заказа'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item name='invoice_show_zeros' label='Показывать нулевые позиции в счёте'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item name='revenue_on_day_close' label='Создавать реализацию на закрытие дня'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item label="Логотип" name={"file"}
                    tooltip={`
                Поддерживается формат (.svg|.png|.jpeg|.gif).
                Предпочитаемый формат изображений (2:1).\n 
                Предпочитаемый размер пиксельных изображений должен быть в пределах (250-300px по ширине и 150px-200px по высоте)`}
        >
            <Upload listType="picture"
                    maxCount={1}
                    defaultFileList={system?.logo ? [{
                        uid: "-1",
                        name: "logo",
                        status: "done",
                        url: system.logo,
                        thumbUrl: system?.logo,
                    }] : []}
                    customRequest={(obj) => {
                        let fd = new FormData();
                        fd.append("file", obj.file);
                        X.Setting().uploadLogo(fd).then(res => {
                            message.success("лого загружено");
                            return obj.onSuccess();
                        }).catch(err => {
                            return obj.onError();
                        });
                    }}
            >
                <Button icon={<UploadOutlined/>}>Загрузить</Button>
            </Upload>
        </Form.Item>
        <Button type='primary' htmlType='submit'>Сохранить</Button>
    </Form>
}

function SMTPSettings() {
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const {setUpdate} = useIntegration();
    const [isConnTesting, setTestConn] = useState(false);
    // const [update, setUpdate] = useState(false);

    useEffect(() => {
        setLoading(true);
        new SMTPCService().get().then(res => {
            form.setFieldsValue(res.data);
            setLoading(false);
        })
    }, []);

    function onFinish(data) {
        new SMTPCService().create(data).then(res => {
            message.success("Настройки сохранены");
            setUpdate(p => !p);
        });
    }

    function onTestConnection(){
        setTestConn(true);
        new SMTPCService().live().then(res => {
        }).finally(_ => {
            setTestConn(false);
        })
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }


    return <Form form={form} id='smtp' layout='vertical' onFinish={onFinish}>
        <Form.Item name="enable" valuePropName="checked" label="Вкл" required={[{required: true}]}>
            <SwitchButton
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        </Form.Item>
        <Form.Item name='email_source' label='SMTP адрес отправителя'>
            <Input type='email' />
        </Form.Item>
        <Form.Item name='email_dest' label='Адрес для приёма почты в песочнице или тестовой отправки'>
            <Input type='email' />
        </Form.Item>
        <Form.Item name='email_host' label='SMTP сервер'>
            <Input />
        </Form.Item>
        <Form.Item name='email_port' label='SMTP порт'>
            <Input />
        </Form.Item>
        <Form.Item name='email_host_user' label='SMTP логин'>
            <Input />
        </Form.Item>
        <Form.Item name='email_host_password' label='SMTP пароль'>
            <Input type='password' />
        </Form.Item>
        <Form.Item name='email_use_tls' label='SMTP шифрование TLS'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item name='email_use_ssl' label='SMTP шифрование SSL'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item>
            <Space>
                <Button disabled={isConnTesting} type='primary' htmlType='submit'>Сохранить</Button>
                <Button disabled={isConnTesting} loading={isConnTesting} onClick={onTestConnection}>Проверить</Button>
            </Space>
        </Form.Item>
    </Form>
}

function KeycloakSettings() {
    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const {setUpdate} = useIntegration();

    useEffect(() => {
        setLoading(true);
        new KeycloakService().get().then(res => {
            form.setFieldsValue(res.data);
            setLoading(false);
        })
    }, []);

    function onFinish(data) {
        new KeycloakService().create(data).then(res => {
            message.success('Успешно сохранено');
            form.setFieldsValue(res.data);
            setUpdate(p => !p);
        })
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return <Form form={form} id='s3' layout='vertical' onFinish={onFinish}>
        <Form.Item name="enable" valuePropName="checked" label="Вкл" required={[{required: true}]}>
            <SwitchButton
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        </Form.Item>
        <Form.Item name='keycloak_server' label='Сервер KeyCloak'>
            <Input type='url' />
        </Form.Item>
        <Form.Item name='keycloak_client_id' label='Client ID KeyCloak'>
            <Input />
        </Form.Item>
        <Form.Item name='keycloak_realm' label='Realm KeyCloak'>
            <Input />
        </Form.Item>
        <Form.Item name='keycloak_secret_key' label='Секретный ключ KeyCloak'>
            <Input type='password' />
        </Form.Item>
        <Form.Item name='keycloak_type_integration' label='Интеграция с фронтендом(да)/бэкендом(нет)'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item>
            <Space>
                <Button type='primary' htmlType='submit'>Сохранить</Button>
                {/* <Button>Проверить соединение</Button> */}
            </Space>
        </Form.Item>
    </Form>
}

// function LkSettings({group = "personal"}) {

//     const [settings, setSettings] = useState([]);
//     const [form] = useForm();
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         setLoading(true);
//         Promise.all([
//             X.Setting()().list(),
//         ]).then(([set]) => {
//             setSettings(set.data.data);
//             form.setFieldsValue(set.data.data);
//             setLoading(false);
//         });
//     }, []);

//     function onFinish(data) {
//         const stt = X.Setting()();
//         stt.update(data).then(r => {
//             notification.success({"message": r.data.msg});
//         }).catch(r => {
//             notification.error({
//                 "message": "Ошибка",
//                 "description": r.response.data?.msg,
//             });
//         });
//     }

//     if (loading) {
//         return (
//             <Card>
//                 <Layout.Content>
//                     {Array(4).fill(0).map(el => (
//                         <Skeleton></Skeleton>
//                     ))}
//                 </Layout.Content>
//             </Card>
//         );
//     }

//     return (
//         <Form form={form} id={"keycloak_form"} layout="vertical" onFinish={onFinish}>
//             {settings.map(s => {
//                 if (s.group != group) return null;
//                 if (["KEYCLOAK_SECRET_KEY"].includes(s.name)) {
//                     return <Form.Item label={s.descr} name={s.name} initialValue={s.value}>
//                         <Input type="password"/>
//                     </Form.Item>;
//                 }
//                 if (s.type === "bool")
//                     return <Form.Item label={s.descr} name={s.name}
//                                       initialValue={s.value}>
//                         {renderInput(s.type)}
//                     </Form.Item>;
//                 else if (s.type === "date")
//                     return <Form.Item label={s.descr} name={s.name}
//                                       initialValue={moment(s.value)}>
//                         {renderInput(s.type)}
//                     </Form.Item>;
//                 else if (s.type === "datetime")
//                     return <Form.Item label={s.descr} name={s.name}
//                                       initialValue={moment(s.value)}>
//                         {renderInput(s.type)}
//                     </Form.Item>;
//                 else if (s.type === "file")
//                     return <Form.Item label={s.descr} name={s.name}>
//                         <Input type="file"/>
//                     </Form.Item>;
//                 else
//                     return <Form.Item label={s.descr} name={s.name}
//                                       initialValue={s.value}>
//                         {renderInput(s.type)}
//                     </Form.Item>;
//             })}
//             <Form.Item>
//                 <Button type={"primary"} htmlType={"submit"} form={"keycloak_form"}>
//                     Сохранить
//                 </Button>
//             </Form.Item>
//         </Form>
//     );
// }

function Settings1C() {

    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const {setUpdate} = useIntegration();

    useEffect(() => {
        setLoading(true);
        new Buh1CService().get().then(res => {
            form.setFieldsValue(res.data);
            setLoading(false);
        })
    }, []);

    function onFinish(data) {
        new Buh1CService().create(data).then(res => {
            message.success('Успешно сохранено');
            form.setFieldsValue(res.data);
            setUpdate(p=>!p);
        })
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return <Form form={form} id='buh' layout='vertical' onFinish={onFinish}>
        <Form.Item name="enable" valuePropName="checked" label="Вкл" required={[{required: true}]}>
            <SwitchButton
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        </Form.Item>
        <Form.Item name='url_service_1c' label='url_service_1c'>
            <Input type="url" />
        </Form.Item>
        <Form.Item name='user_service_1c' label='user_service_1c'>
            <Input />
        </Form.Item>
        <Form.Item name='password_service_1c' label='password_service_1c'>
            <Input type='password' />
        </Form.Item>
        <Form.Item name='slave_1c' label='slave_1c'>
            <Radio.Group>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item >
            <Space>
                <Button type='primary' htmlType='submit'>Сохранить</Button>
            </Space>
        </Form.Item>
    </Form>
}

function S3Settings() {

    const [form] = useForm();
    const [loading, setLoading] = useState(true);
    const {setUpdate} = useIntegration();
    // const [changes, setChanges] = useState(false);
    const [isConnTesting, setTestConn] = useState(false);

    useEffect(() => {
        setLoading(true);
        new S3Service().get().then(res => {
            form.setFieldsValue(res.data);
            setLoading(false);
        })
    }, []);

    function onFinish(data) {
        new S3Service().create(data).then(res => {
            form.setFieldsValue(res.data);
            message.success('Успешно сохранено');
            setUpdate(p=>!p);
        })
    }

    function onFinishFailed(errorInfo) {
        console.warn('S3 setting validation failed.');
    }

    function onTestConnection(){
        // Проверить валидность введённых данных
        const data = form.getFieldsValue();
        form.validateFields().then(
            _ => {
                setTestConn(true);
                new S3Service().live(data).then(res => {
                    if (res?.status == 200) {
                        message.success(res.data.message);
                    }
                }).finally(_ => {
                    setTestConn(false);
                })
            }
        ).catch(
            _ => {
                console.warn('S3 setting validation failed on connection test.');
            }
        );
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return  <Form form={form} id='s3' layout='vertical' onFinish={onFinish}  onFinishFailed={onFinishFailed}
    validateTrigger="onBlur">
        <Form.Item name="enable" valuePropName="checked" label="Использовать хранилище S3" required={[{required: true}]} >
            <SwitchButton
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        </Form.Item>
        <Form.Item name='aws_s3_endpoint_url' label='Адрес хранилища' rules={[
                {required: true, message: "необходимое поле"},
            ]} validateTrigger="onBlur">
            <Input type='url' />
        </Form.Item>
        <Form.Item name='aws_storage_bucket_name' label='Bucket' rules={[
                {required: true, message: "необходимое поле"},
            ]}>
            <Input />
        </Form.Item>
        <Form.Item name='aws_s3_access_key_id' label='Access Key' rules={[
                {required: true, message: "необходимое поле"},
            ]} validateTrigger="onBlur">
            <Input.Password />
        </Form.Item>
        <Form.Item name='aws_s3_secret_key' label='Secret Key' rules={[
                {required: true, message: "необходимое поле"},
            ]} validateTrigger="onBlur">
            <Input.Password />
        </Form.Item>
        <Form.Item >
            <Space>
                <Button disabled={isConnTesting} type='primary' htmlType='submit'>Сохранить</Button>
                <Button disabled={isConnTesting} loading={isConnTesting} onClick={onTestConnection}>Проверить соединение</Button>
            </Space>
        </Form.Item>
    </Form>
}

function AtolPayModule() {

    const [loading, setLoading] = useState(false);
    const [checkConn, setCheckConn] = useState(false);
    const [form] = useForm();
    const {integrations, setUpdate} = useIntegration();
    // const [genKey, setGenKey] = useState(null);


    function is_available() {
        return integrations["atolpay.AtolPay"]?.enable;
    }

    useEffect(() => {
        setLoading(true);
        new AtolPService().get().then(res => {
            form.setFieldsValue(res.data);
        }).finally(_ => {
            setLoading(false);
        });
    }, []);

    function update(data) {
        new AtolPService().create(data).then(res => {
            message.success("Настройки сохранены");
            setUpdate(p => !p);
        });
    }

    function checkService() {
        setCheckConn(true);
        new AtolPService().check().then(res => {
            message.success("Успешно");
        }).finally(_ => {
            setCheckConn(false);
        });
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<Form form={form} id={"atolpay"} layout="vertical" onFinish={(d) => update(d)}>
            <Form.Item name="enable" valuePropName="checked" label="Вкл" required={[{required: true}]}>
                <SwitchButton
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                />
            </Form.Item>
            <Form.Item name="url" label="Корневой адрес" required={[{required: true}]}>
                <Input disabled={!is_available()} type="url"/>
            </Form.Item>
            <Form.Item name="api_key" label="Секретный ключ авторизации" required={[{required: true}]}>
                <Input.Password disabled={!is_available()}/>
            </Form.Item>
            <Form.Item name="bank_id" label="Идентификатор банка эквайера" required={[{required: true}]}
                       initialValue={100}>
                <Select disabled={!is_available()}>
                    <Select.Option value={100}>Альфа-банк</Select.Option>
                    <Select.Option value={700}>Т-банк</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="tax_system" label="Система налогообложения" required={[{required: true}]}
                       initialValue={100}>
                <Select disabled={!is_available()}>
                    <Select.Option value={0}>Общая СН</Select.Option>
                    <Select.Option value={1}>Упрощенная СН (доходы)</Select.Option>
                    <Select.Option value={2}>Упрощенная СН (доходы минус доходы)</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="generate_check" label="Формировать чек?" required={[{required: true}]}>
                <Radio.Group disabled={checkConn || !is_available()}>
                    <Radio.Button value={true}>Да</Radio.Button>
                    <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Callback принятия платежа в систему">
                <Typography.Text type="secondary"
                                 copyable>{window.location.origin}/api/atolpay/payment</Typography.Text>
            </Form.Item>

            <Space>
                <Button type={"primary"} htmlType={"submit"} form={"atolpay"}>
                    Сохранить
                </Button>
                <Button disabled={checkConn || !is_available()}
                        loading={checkConn} onClick={checkService}>Проверка соединения</Button>
            </Space>
        </Form>
    );
}

function EdoModule() {

    const [loading, setLoading] = useState(false);
    const [checkConn, setCheckConn] = useState(false);
    const [form] = useForm();
    const {integrations, setUpdate} = useIntegration();
    const [enabled, setEnabled] = useState(integrations["edo.Edo"]?.enable);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setEnabled(integrations["edo.Edo"]?.enable);
    }, [integrations]);

    useEffect(async () => {
        setLoading(true);
        const es = X.Edo();
        const res = await es.get();

        form.setFieldsValue(res.data);
        setLoading(false);

        const {data: opts} = await es.options();
        setOptions(Object.fromEntries(opts.fields.map(x => [x.name, x])))

    }, []);

    async function update(data) {
        try {
            await X.Edo().create(data);
            notification.success({message: "Настройки сохранены"});
        } catch (err) {
            notification.error({
                message: err.response?.data?.msg,
                description: JSON.stringify(err.response?.data?.details)
            })
        } finally {
            setUpdate(p => !p);
        }
    }

    async function checkService() {
        setCheckConn(true);
        try {
            const {data} = await X.Edo().check(form.getFieldValue("url"));
            notification.success({
                message: data.msg,
                description: <div>
                    <p>Версия cades: {data.details.cades}</p>
                    <p>Ожидается: {data.details.awaited}</p>
                    <p>Организация сертификата: {data.details.org}</p>
                    <p>Действителен до: {data.details.valid_to}</p>
                </div>
            })
        } catch (ex) {
        } finally {
            setCheckConn(false);
        }
    }

    async function checkDiadocService() {
        setCheckConn(true);
        try {
            await X.Edo().check_diadoc(form.getFieldValue("url"));
            notification.success({message: "Сервис ДИАДОК доступен"});
        } finally {
            setCheckConn(false);
        }
    }

    function customSetDisabled(val) {
        setEnabled(val);
    }

    if (loading) {
        return (
            <Card>
                <Layout.Content>
                    {Array(4).fill(0).map(el => (
                        <Skeleton></Skeleton>
                    ))}
                </Layout.Content>
            </Card>
        );
    }

    return (<Form form={form} id={"atolpay"} layout="vertical" onFinish={update}>
            <Form.Item name="enable" valuePropName="checked" label="Вкл" required={true}>
                <SwitchButton onChange={customSetDisabled}
                              checkedChildren={<CheckOutlined/>}
                              unCheckedChildren={<CloseOutlined/>}
                />
            </Form.Item>
            <Form.Item name="url" label={options?.url?.label} required={true}>
                <Input disabled={!enabled} type="url"/>
            </Form.Item>
            <Form.Item name="api_key" label={options?.api_key?.label} required={true}>
                <Input.Password disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="diadoc_url" label="Адрес ДИАДОК (DIADOC API entrypoint)" required={true}>*/}
            {/*    <Input disabled={!enabled} type="url"/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item name="diadoc_client_id" label="ИД клиента ДИАДОК (Client ID DIADOC)" required={false}>*/}
            {/*    <Input.Password disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Form.Item name="test_box" label={options?.test_box?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="test_box_guid" label="Тестовый GUID ящика  ДИАДОК (для исходящих док)" required={false}>*/}
            {/*    <Input disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Form.Item name="test_dest_inn" label={options?.test_dest_inn?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            <Form.Item name="test_dest_kpp" label={options?.test_dest_kpp?.label} required={false}>
                <Input disabled={!enabled}/>
            </Form.Item>
            {/*<Form.Item name="test_diadoc_login" label={options?.test_diadoc_login?.label} required={false}>*/}
            {/*    <Input disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item name="test_diadoc_password" label={options?.test_diadoc_password?.label} required={false}>*/}
            {/*    <Input.Password disabled={!enabled}/>*/}
            {/*</Form.Item>*/}
            <Space>
                <Button type={"primary"} htmlType={"submit"} form={"atolpay"}>
                    Сохранить
                </Button>
                <Button disabled={checkConn || !enabled}
                        loading={checkConn} onClick={checkService}>
                    Тест сервиса CAdES
                </Button>
                <Button disabled={checkConn || !enabled}
                        loading={checkConn} onClick={checkDiadocService}>
                    Тест сервиса ДИАДОК
                </Button>
            </Space>
        </Form>
    );
}

function NumeratorSettings(){

    const [numerators, setNumerators] = useState([]);
    const [numModal, setNumModal] = useState({visible: false});
    const [update, setUpdate] = useState(false);
    const {contragent} = useUser();

    useEffect(() => {
        X.Contragent(contragent).Numerator().list().then(res => {
            setNumerators(res.data.data)
        })
    }, [update])

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Выражение",
            dataIndex: "expression",
            key: "expression",
        },
        {
            title: "Текущее значение",
            dataIndex: "current_index",
            key: "current_index",
        },
        {
            title: "Действия",
            dataIndex: "actions",
            key: "actions",
            render: (val, record) => (
                <Space>
                    <Button size='small' icon={<EditOutlined />} onClick={() => setNumModal({visible: true, item: record})} />
                </Space>
            )
        },
    ]

    return <Table size='small'
                  title={(record) => (
                    <Space>
                        <NumeratorModal
                            item={numModal?.item}
                            visible={numModal?.visible}
                            onCancel={() => {setNumModal({visible: false}); setUpdate(p => !p)}}
                        />
                    </Space>
                  )
                  }
                  pagination={false}
                  columns={columns}
                  dataSource={numerators}
    />
}
