import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Form, Modal} from 'antd'
import CounterForm from '../Forms/CounterModal'
import useContragent from '../../Contragents/ContragentContext';
import {X} from "app/ApiService/Query";

export default function CounterModal({service_item, ...props}) {

    const [form] = Form.useForm();
    const [counterValues, setCounterValues] = useState(null);
    const {contragent} = useContragent();

    const counter_add = (data) => {
        X.Contragent(contragent).Counter(service_item.id).addCounter(data).then(res => {
            typeof props.onCancel === 'function' && props.onCancel();
        })
    }

    useEffect(() => {
        if (service_item) {
            X.Contragent(contragent).Counter(service_item.id).lastValueCounter().then(res => {
                setCounterValues(res.data)
            })
        }
    }, [service_item])

    const onFinish = (data) => {
        counter_add(data)
    }

    return (
        <Modal {...props} destroyOnClose title='добавить показание' footer={
            <Button type='primary' htmlType='submit' form='counter_form'>Добавить</Button>
        }>
            <CounterForm id='counter_form' form={form} onFinish={onFinish}/>
            {counterValues !== null &&
                <Descriptions column={1} bordered size='small'>
                    <Descriptions.Item label='Текущее значение'>{counterValues?.current_count}</Descriptions.Item>
                    <Descriptions.Item
                        label='Последнее внесённое значение'>{counterValues?.last_count}</Descriptions.Item>
                </Descriptions>
            }
        </Modal>
    )
}
