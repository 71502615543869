import {DeleteOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Button, Col, Menu, message, Popconfirm, Row} from "antd";
import {Card} from "app/Base";
import {Fable} from "app/Base/Tables/Fable/Fable";
import React, {useState} from "react";
import {useOfferData, useOfferProductData} from "src/Data/Base";
import Money from "app/Components/Accounts/utils/Money";
import useUser from "app/Providers/UserProvider";
import OfferModal from "../Modals/OfferModal";
import {X} from "app/ApiService/Query";


export function OffersTable({product}) {

    const [modalVisible, setModalVisible] = useState(false);
    const {contragent} = useUser();
    const model = useOfferData({contragent});
    const {toggleTableUpdate} = model;

    function render_menu(record) {

        function delete_offer() {
            X.Contragent(contragent).Offer(record?.id).delete().then(res => {
                message.success("предложение удалено");
            });
        }

        return (
            <Menu>
                {/* {is_global_permitted('edit_offer') &&  */}
                <Menu.Item
                    icon={<EditOutlined/>}
                    onClick={() => setModalVisible({visible: true, item: record})}
                >Редактировать</Menu.Item>

                {/* {is_global_permitted('delete_offer') && */}
                <Popconfirm
                    title={`вы уверены, что хотите удалить ${record?.name}`}
                    onConfirm={delete_offer}
                    okText="Удалить"
                    cancelText="Отменить"
                    icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                >
                    <Menu.Item icon={<DeleteOutlined/>} danger>Удалить</Menu.Item>
                </Popconfirm>
                {/* } */}
            </Menu>
        );
    }

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "Описание",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: "ascend",
        },
        {
            title: "продукт",
            dataIndex: "product",
            key: "product",
            render: (value, record) => (
                value?.name
            ),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "Налоговая ставка",
            dataIndex: "taxes",
            key: "taxes",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "Предоплата",
            dataIndex: "pre",
            key: "pre",
            render: (value) => {
                return `${value} %`;
            },
        },
        {
            title: "Тип услуги",
            dataIndex: "type_name",
            key: "type_name",
        },
    ];

    return <Card title="Предложения">
        <Fable model={model}
               columns={columns}
               actions={render_menu}
            // click={}
               extra={
                   <Row justify="space-between">
                       <Col>
                           <Button type="primary"
                                   onClick={() => setModalVisible({visible: true})}>
                               Создать предложение
                           </Button>
                           <OfferModal visible={modalVisible?.visible}
                                       item={modalVisible?.item}
                                       product={product}
                                       onCancel={() => {
                                           setModalVisible({visible: false});
                                           toggleTableUpdate();
                                       }}/>
                       </Col>
                   </Row>}/>
    </Card>;

}

export function OfferProductTable({product}) {

    const [modalVisible, setModalVisible] = useState(false);
    const {contragent} = useUser();
    const model = useOfferProductData({contragent_id: contragent?.id, product_id: product.id});
    const {toggleTableUpdate} = model;

    function render_menu(record) {

        function delete_offer() {
            X.Contragent(contragent).Offer(record?.id).delete().then(res => {
                message.success("предложение удалено");
            });
        }

        return (
            <Menu>
                {/* {is_global_permitted('edit_offer') &&  */}
                <Menu.Item
                    icon={<EditOutlined/>}
                    onClick={() => setModalVisible({visible: true, item: record})}
                >Редактировать</Menu.Item>

                {/* {is_global_permitted('delete_offer') && */}
                <Popconfirm
                    title={`вы уверены, что хотите удалить ${record?.name}`}
                    onConfirm={delete_offer}
                    okText="Удалить"
                    cancelText="Отменить"
                    icon={<QuestionCircleOutlined style={{color: "red"}}/>}
                >
                    <Menu.Item icon={<DeleteOutlined/>} danger>Удалить</Menu.Item>
                </Popconfirm>
                {/* } */}
            </Menu>
        );
    }

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "Описание",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: "ascend",
        },
        {
            title: "продукт",
            dataIndex: "product",
            key: "product",
            render: (value, record) => (
                value?.name
            ),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            align: "right",
            render: (value, record) => (
                <Money sum={value} currency={record.currency}/>
            ),
        },
        {
            title: "Налоговая ставка",
            dataIndex: "taxes",
            key: "taxes",
            render: (value) => {
                return value === "NO_VAT" ? "Без НДС" : `${value}%`;
            },
        },
        {
            title: "Предоплата",
            dataIndex: "pre",
            key: "pre",
            render: (value) => {
                return `${value} %`;
            },
        },
        {
            title: "Тип услуги",
            dataIndex: "type_name",
            key: "type_name",
        },
    ];

    return <Fable model={model}
                  columns={columns}
                  actions={render_menu}
        // click={}
                  extra={
                      <Row justify="space-between">
                          <Col>
                              <Button type="primary"
                                      onClick={() => setModalVisible({visible: true})}>
                                  Создать предложение
                              </Button>
                              <OfferModal visible={modalVisible?.visible}
                                          item={modalVisible?.item}
                                          product={product}
                                          onCancel={() => {
                                              setModalVisible({visible: false});
                                              toggleTableUpdate();
                                          }}/>
                          </Col>
                      </Row>}
    />;

}
