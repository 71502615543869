import React, {useEffect, useState} from "react";
import {Button, message, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import Modal from "../../../Base/Modals/Modal";
import MeasureForm from "../Forms/MeasureForm";
import {useForm} from "antd/es/form/Form";
import useUser from "../../../Providers/UserProvider";
import {X} from "app/ApiService/Query";


export default function MeasureTable() {
    const {is_permitted} = useUser();
    const [measures, setMeasures] = useState([]);
    const [visible, setVisible] = useState(false);
    const [update, setUpdate] = useState(false);

    const [form] = useForm();

    const columns = [
        {title: 'id', dataIndex: 'id', key: 'id',},
        {title: 'Название', dataIndex: 'name', key: 'name',},
        {title: 'Короткое', dataIndex: 'short', key: 'short',},
        {title: 'Идентификатор', dataIndex: 'codename', key: 'codename',},
        {
            title: 'Действие',
            key: 'operation',
            fixed: 'right',
            render: (_, record) => {
                return <Space>
                    {is_permitted('application.attribute.delete') &&
                        <Button size='small' shape='circle' icon={<DeleteOutlined/>} danger ghost onClick={() => {
                            X.Measure(record.id).delete().then(res => {
                                message.success('Измерение удалено');
                                setUpdate(prev => !prev);
                            })
                        }}/>
                    }
                    {is_permitted('application.attribute.edit') &&
                        <Button size='small' shape='circle' icon={<EditOutlined/>} onClick={() => {
                            X.Measure(record.id).get().then(res => {
                                    form.setFieldsValue(res.data);
                                }
                            );
                            setVisible(true);
                        }}/>
                    }
                </Space>
            },
        },
    ];

    useEffect(() => {
        X.Measure().list().then(res => {
            setMeasures(res.data.data)
        })
    }, [update])

    return <>
        {is_permitted('application.attribute.create') &&
            <Button type="primary" onClick={() => {
                form.resetFields();
                setVisible(true);
            }} style={{marginBottom: '1em'}}>Создать</Button>
        }
        <Table size='small' dataSource={measures} columns={columns} pagination={false}/>
        <Modal title={'Измерение'}
               btnname={'Сохранить'}
               visible={visible}
               form="form"
               onSuccess={data => {
                   setVisible(false);
                   X.Measure(form.getFieldValue('id'))
                       .update_or_create(form.getFieldsValue()).then(() => {

                       setUpdate(p => !p);
                   })
               }}
               onCancel={() => {
                   setVisible(false)
               }}
        >
            <MeasureForm form={form}/>
        </Modal>
    </>

}
