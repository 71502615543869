import {message} from "antd";
import axios from "../config/axios";
import BaseService from "./Base";
import UserService from "./User";
import {ObjectService} from "./Object";


class ContragentService extends ObjectService {
    MODEL = "contragent";
    TRNS = "transaction";
    PPMAIL = "mail";

    constructor(id = null) {
        super(id);
        this.userService = new UserService();
    }

    getOffers({params}) {
        return this._get('offer', {params});
    }

    getMarket(params) {
        return this._get("market", {params});
    }

    getPackages() {
        return this._get("package");
    }


    getProducts(params) {
        return this._get("product", {params});
    }

    getAllProducts() {
        return this._get("product/all");
    }

    listContracted() {
        return this._get("contracted");
    }

    listCloseDayBilling() {
        return this._get("billing").then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            this.error_handler(err);
            return Promise.reject(err);
        });
    }

    patchUserContragent(data) {
        return this._patch("self", data);
    }

    getContragentAccount() {
        return this.accounts();
    }

    accounts(params) {
        return this._get("accounts", {params});
    }

    partners(name) {
        return this._get("partners", {name});
    }

    getContracts(params) {
        return this._get("contracts", {params: params}).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            this.error_handler(err);
            return Promise.reject(err);
        });
    }

    getContragentInfo() {
        return this._get();
    }

    getDefaultAccount() {
        return this._get("default");
    }

    setDefaultAccount(data) {
        return this._post("default", data).then(res => {
            message.success(`Счёт ${data.account} сделан по умолчанию`);
            return Promise.resolve(res);
        }).catch(err => {
            message.error("Возникла ошибка.");
            return Promise.reject(err);
        });
    }

    getContragentUsers() {
        return this._get("users");
    }

    setCurrentContragent() {
        return this.userService.setActiveContragent(this._id);
    }

    getUsers() {
        return this._get("empty-users");
    }

    setUsers(ids) {
        return this._post("users", ids);
    }

    listOrders(params) {
        return this._get("orders", {params}).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err.response.data && err.response.data.msg) {
                message.error(err.response.data.msg);
            }
            return Promise.reject(err);
        });
    }

    calculateNextBillingDay() {
        return this._post("bill-next-day");
    }

    checkBillingTask() {
        return this._get("bill-next-day");
    }

    getLastBilledDay() {
        return this._get("last-billed-day");
    }

    filter(columns, attributes, filters, page, limit, search) {
        const _cols = [...columns, 'error_message'];

        return super.filter(_cols, attributes, filters, page, limit, search);
    }

}

// TODO: Сделано неправильно. Переделать, унаследовать от ContragentableService
class PPMAILService extends BaseService {
    MODEL = "contragent";

    constructor(contragentId, ppmailId = null) {
        super(contragentId);
        this.contragent_id = contragentId;
        this.ppmail_id = ppmailId;
    }

    sendDocs(data) {
        return this._post(`mail/${this.ppmail_id}/send_docs`, data).then(res => {
            message.success("Отправлено.");
            return Promise.resolve(res);
        }).catch(err => {
            this.error_handler(err);
            return Promise.reject(err);
        });
    }

    forwardMails(data) {
        return this._post(`mail/${this.ppmail_id}/forward`, data).then(res => {
            message.success("Отправлено.");
            return Promise.resolve(res);
        }).catch(err => {
            this.error_handler(err);
            return Promise.reject(err);
        });
    }

    list() {
        return this._get(`mail`).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            this.error_handler(err);
            return Promise.reject(err);
        });
    }
}


class RuleService extends BaseService {
    MODEL = 'rule'
}

export {
    ContragentService,
    RuleService,
    PPMAILService,
};
