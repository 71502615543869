import {ObjectService} from "./Object";

class OfferService extends ObjectService {
    MODEL = 'offer'

    getType() {
        return this._get("type")
    }

    getTaxes() {
        return this._get("taxes")
    }

    getFourmulas() {
        return this._get("formulas")
    }

}


export {
    OfferService,
}
