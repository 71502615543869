import BaseService from "./Base";
import {ContragentService} from "app/ApiService/Contragent";
import {notification} from "antd";


export class EdoService extends BaseService {
    MODEL = "edo";

    _mk_res(part) {
        let url = `${this.API}/${this.model}`;
        if (part)
            url = url.concat(`/${part}`);
        return url;
    }

    check(url) {
        return this._post("status", {url}).catch(err => {
            if (300 <= err.response.status && err.response.status < 400)
                EdoService.error_handler(err, "Обратите внимание!", "warning");
            else
                EdoService.error_handler(err);

            return Promise.reject(err);
        });
    }

    check_diadoc(url) {
        // return this._get('diadoc').catch(EdoService.error_handler);
        return this._post("diadoc", {url}).catch(EdoService.error_handler);
    }

    changeStatus(data) {
        return this._post("status").catch(EdoService.error_handler);
    }

    sign_and_send() {
        return this._get(`senddoc/${this.get_id()}`)
                   .catch(EdoService.error_handler);
    }

    get_status() {
        return this._get(`getdocstatus/${this._id}`).catch(EdoService.error_handler);
    }

    get_statuses_byuuid(uuids) {
        return this._post("getdocstatus", {uuids: uuids}).then(res => {
            return res;
        }).catch(EdoService.error_handler);
    }

    get_statuses(ids) {
        return this._post("getdocstatus", {ids}).then(res => {
            return res;
        }).catch(EdoService.error_handler);
    }

}

export class EdoMessageService extends BaseService {
    MODEL = "edo/messages";

    changeStatus(data) {
        return this._post("status", data).catch(EdoMessageService.error_handler);
    }

    refreshStatus() {
        return this._get("status", {params: {id: this._id}})
                   .catch(EdoMessageService.error_handler);
    }
}


export
class EdoContragentService extends ContragentService {
    MODEL = "edo/contragent";

    async check_edo_status() {
        try {
            const data = await this._get("edo-status");
            return data;
        } catch (ex) {
            notification.error({
                message: "Ошибка. Не удалось выполнить запрос",
                description: ex.response.data.msg
            })
        }
    }

    get model() {
        return this.MODEL;
    }
}
