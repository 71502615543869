import {MenuOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {Badge, Button, Card, Col, Menu, message, Dropdown,
    Row, Skeleton, Space, Tag, Typography} from "antd";
import {X} from "app/ApiService/Query";
import React, {useEffect, useState} from "react";

import {Redirect, Route, Switch} from "react-router";
import {Link, useParams} from "react-router-dom";
import {useLocation} from "react-use";
import {ContragentService} from "src/ApiService/Contragent";
import useUser from "../../Providers/UserProvider";
import BalanceTable from "../Accounts/Table/BalanceTable";
import {AccountCard} from "./Cards/AccountCard";
import {BalanceCard} from "./Cards/BalanceCard";
import CardAttribute from "./Cards/ContragentAttribute";
import useContragent, {Contragent} from "./ContragentContext";
import {BlockingTable} from "./Tables/BlockingTable";
import ContractTable from "./Tables/ContractTable";
import DocumentTable from "./Tables/DocumentTable";
import {OrdersRouter} from "./Tables/OrdersTable";
import {PPMailTable} from "./Tables/PPMailTable";
import RevenueTable from "./Tables/RevenueTable";
import ServiceTable from "./Tables/ServiceTable";
import { BlockingModal } from "../Common/Modal/BlockingModal";


export default function ContragentDetail() {
    const {contragent, cart, setContragent, loading} = useContragent();
    const {globalUpdate, is_global_permitted} = useUser();
    const [modalBlock, setModalBlock] = useState({visible: false})
    const [update, setUpdate] = useState(false);
    const params = useParams();
    const location = useLocation();
    // const {isEnabled} = useIntegration();

    let MENU = [
        {code: 'common', name: "Общее", Component: CardAttribute,
            is_shown: () => is_global_permitted('application.contragent.detail')},
        {code: 'contracts', name: "Договоры", Component: ContractTable,
            is_shown: () => is_global_permitted('application.contragent.detail.contract')},
        {code: 'orders', name: "Заказы", Component: OrdersRouter,
            is_shown: () => is_global_permitted('application.order.access')},
        {code: 'transactions', name: "Баланс", Component: BalanceTable,
            is_shown: () => is_global_permitted('application.contragent.detail.balance')},
        {code: 'subscribes', name: "Услуги", Component: ServiceTable,
            is_shown: () => is_global_permitted('application.contragent.detail.subscribe')},
        {code: 'documents', name: "Документы", Component: DocumentTable,
            is_shown: () => is_global_permitted('application.contragent.detail.document')},
        {code: 'revenue', name: "Реализации", Component: RevenueTable,
            is_shown: () => is_global_permitted('application.contragent.detail.revenue')},
        {code: 'blocking', name: "Блокировки", Component: BlockingTable,
            is_shown: () => is_global_permitted('application.contragent.detail.block')},
        {code: 'mail', name: "Сообщения", Component: PPMailTable,
            is_shown: () => is_global_permitted('application.contragent.detail.mail')},
    ]

    useEffect(() => {
        X.Contragent(params.id).getContragentInfo().then(res => {
            setContragent(new Contragent(res.data));
        }).catch(ContragentService.create_error_handler("Не удалось запросить данные контрагента"));
    }, [params.id, globalUpdate, update]);

    function cartCountItems(){
        let count = 0
        count += cart.data ? cart.data.length : 0
        count += cart.packages ? cart.packages.length : 0
        return count
    }

    function updateContragent(data){
        X.Contragent(params.id).update(data).then(res => {
            message.success('обновлено');
            setContragent(new Contragent(res.data));
        })
    }

    const menu = (
        <Menu>
            <Menu.Item disabled={!is_global_permitted('application.blocking.create')}
                       onClick={() => setModalBlock({visible: true})}>
                        Установить блокировку
            </Menu.Item>
            {is_global_permitted('application.contragent.cart') &&
                <Menu.Item>
                    <Link to={`/contragents/${contragent?.id}/cart`}>
                        Корзина
                    </Link>
                </Menu.Item>
            }
        </Menu>
    )
    return (!loading ?
        <Card
            title={<Space direction='vertical'>
                    <Typography.Text copyable
                        ellipsis={{ tooltip: contragent?.name }}
                        style={{width: '700px'}}
                        editable={
                            is_global_permitted('application.contragent.edit') ?
                            {onChange: (v) => updateContragent({name: v})} : false}
                    >
                        {contragent?.name}
                    </Typography.Text>
                    {contragent?.external_id &&
                        <Typography.Text
                            type='secondary'
                            code copyable
                            editable={
                                is_global_permitted('application.contragent.edit') ?
                                {onChange: (v) => updateContragent({external_id: v})} : false}
                        >{contragent?.external_id}</Typography.Text>
                    }
                    {contragent?.number &&
                        <Typography.Text
                            type='secondary'
                            code copyable
                            editable={
                                is_global_permitted('application.contragent.edit') ?
                                {onChange: (v) => updateContragent({number: v})} : false}
                        >{contragent?.number}</Typography.Text>
                    }
                    {contragent?.blocked &&
                        <Typography.Text type='secondary' style={{fontSize: "12px"}}>
                            <Tag color='red'>Заблокирован</Tag>
                        </Typography.Text>
                    }
                    <BlockingModal
                        targetObject={contragent}
                        onCancel={() => {setModalBlock({visible: false}); setUpdate(p=>!p)}}
                        onSuccess={() => {setModalBlock({visible: false}); setUpdate(p=>!p)}}
                        visible={modalBlock?.visible}
                    />
                </Space>
            }
            extra={
                <Space>
                    {/* <Badge count={cart && cartCountItems()}>
                        <Link to={`/contragents/${contragent?.id}/cart`}>
                            <Button icon={<ShoppingCartOutlined />}></Button>
                        </Link>
                    </Badge> */}
                    <Dropdown trigger={['click']} overlay={menu}>
                        <Button icon={<MenuOutlined />} />
                    </Dropdown>
                </Space>
            }
        >
            <Row gutter={[8, 8]}>
                {/* {is_permitted('application.contragent.detail.balance') &&  */}
                {/*TODO: Использовать SimplPerm*/}
                    <Col xs={12} xl={6}>
                        <AccountCard />
                    </Col>
                {/* } */}
                {/* {is_permitted('application.contragent.detail.balance') &&  */}
                    <Col xs={24} xl={12}>
                        <BalanceCard />
                    </Col>
                {/* } */}
                <Col xs={24}>
                    <Menu selectedKeys={[location.pathname]} mode='horizontal'>
                        {MENU.map(({code, name, is_shown}) => (
                            (is_shown && is_shown()) &&
                                <Menu.Item key={`/app/contragents/${contragent?.id}/${code}`}>
                                    <Link to={`/contragents/${contragent?.id}/${code}`}>{name}</Link>
                                </Menu.Item>
                        ))}
                    </Menu>
                    <Switch >
                        {MENU.map(({code, Component, is_shown}) => (
                            (is_shown && is_shown()) &&
                                <Route path={`/contragents/:id/${code}`}>
                                    <Component/>
                                </Route>
                        ))}
                        <Route exact path='/contragents/:id'>
                            <Redirect to={`/contragents/${params?.id}/common`}/>
                        </Route>
                    </Switch>
                </Col>
            </Row>
        </Card> : <Card>
            {new Array(3).fill(0).map(_ => (
                <Skeleton></Skeleton>
            ))}
        </Card>
    );
}
