import {Button, Descriptions, Form, InputNumber, Spin, Tooltip} from "antd";
import SearchTable from "app/Base/Tables/SearchTable";
import {DateTime} from "app/Components/Common/OtherComponents";
import {OrderPayStatusTag, orderStatusTag, OrderTypeTag} from "app/Components/Common/Tags";
import useContragent from "app/Components/Contragents/ContragentContext";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {useToggle} from "react-use";
import Modal from "src/Base/Modals/Modal";
import MyModal from "src/Base/Modals/Modal";
import {useOrder} from "src/Providers/OrderProvider";
import Currency from "../../Accounts/utils/Currency";
import Money from "../../Accounts/utils/Money";
import {EditAttributesForm} from "../../Common/Forms/AttributesForm";
import {X} from "app/ApiService/Query";


export function PartPayOrderModal({item, visible, onCancel, ...props}) {
    const {contragent} = useContragent();

    function partPaymentOrder(data) {
        X.Contragent(contragent).Order(item.id).pay(data).then(response => {
            // TODO: тут что=то должно быть...
        });
    }

    return (
        <Modal title="Частичная оплата"
               visible={visible}
               onOk={() => partPaymentOrder()}
               onCancel={onCancel}
               form="part_payment_form"
               btnname={"Оплатить"}
               {...props}>
            <Form id="part_payment_form" onFinish={partPaymentOrder}>
                <Form.Item>
                    <Descriptions column={1} bordered size="small">
                        <Descriptions.Item label="Сумма заказа">{item.price}</Descriptions.Item>
                        <Descriptions.Item label="Уже оплачено">{item.paid_amount}</Descriptions.Item>
                    </Descriptions>
                </Form.Item>
                <Form.Item name="amount" rules={[{required: true, message: "Необходимо указать корректную сумму"}]}>
                    <InputNumber style={{width: "100%"}} min={0} max={item.price}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}


export function PartPayOrderItemModal({item, onSuccess, ...props}) {

    const {order} = useOrder();
    const {contragent} = useContragent();

    function partPaymentOrder(data) {
        data["order_items"] = [item.id];
        X.Contragent(contragent).Order(order).pay(data).then(response => {
            onSuccess?.(response.data);
        });
    }

    return (
        <Modal title={`Частичная оплата позиции № ${item?.id}`}
               form="part_payment_form"
               btnname={"Оплатить"}
               {...props}
        >
            <Form id="part_payment_form" onFinish={partPaymentOrder}>
                <Form.Item>
                    <Descriptions column={1} bordered size="small" contentStyle={{textAlign: "right"}}>
                        <Descriptions.Item label="Наименование">{item?.product_name}</Descriptions.Item>
                        <Descriptions.Item label="Сумма позиции" contentStyle={{textAlign: "right"}}>
                            <Money sum={item?.cost} currency={order.currency}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Оплачено" contentStyle={{textAlign: "right"}}>
                            <Money sum={item?.paid} currency={order.currency}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Количество" contentStyle={{textAlign: "right"}}>
                            {item?.count} {item?.measure ? item?.measure : ""}
                        </Descriptions.Item>
                    </Descriptions>
                </Form.Item>
                <Form.Item name="amount" rules={[{required: true, message: "Необходимо указать корректную сумму"}]}>
                    <InputNumber prefix={<Currency code={order.currency}/>} style={{width: "100%"}} min={0}
                                 max={item?.cost - item?.paid}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}


export function OrderAttributesModal({order, onFinish, ...props}) {
    // модальное окно для изменения атрибутов

    const onFinishForm = () => {
        typeof onFinish === 'function' && onFinish();
    }

    return (
        <Modal {...props} destroyOnClose title='Редактирование атрибутов' footer={
            <Button htmlType='submit'
                    form='attributes_change_form'
                    type='primary'>
                Подтвердить
            </Button>
        }>
            <EditAttributesForm
                id='attributes_change_form'
                obj={order}
                contragent={order?.contract?.buyer}
                apisrv={X.Contragent(order?.contract?.buyer).Order(order)}
                onFinish={onFinishForm}
            />
        </Modal>
    );
}

export function OrdersSubscribeTable({subscribe}) {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [update, toggle] = useToggle(false);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const {contragent} = useContragent();

    useEffect(() => {
        if (subscribe) {
            setLoading(true);
            // new OrderService().filter(cols, [], {item__service: subscribe.id}, 1, 1000).then(
            //     r => {
            //         setOrders(r.data.data);
            //         setFilteredOrders(r.data.data);
            //     },
            // ).finally(r => setLoading(false));
            X.Contragent(contragent).Service(subscribe).getOrders().then(
                r => {
                    setOrders(r.data.data);
                    setFilteredOrders(r.data.data);
                },
            ).finally(r => setLoading(false));
        }
    }, [update]);

    useEffect(() => {
        setFilteredOrders(orders);
    }, [search]);

    const columns = [
        {
            title: "Номер", dataIndex: "number", key: "number", render: (val, record) => {
                let res;
                if (val && (val.length > 20))
                    res = `${val.substring(0, 20)}...`;
                else
                    res = val;

                return <Tooltip title={val}>
                    <Link to={`/contragents/${record.buyer.id}/orders/${record.id}`}>
                        {res}
                    </Link>
                </Tooltip>;
            },
        },
        {
            title: "Дата провижнинга", dataIndex: "provision_date", key: "provision_date",
            render: (val) => <DateTime dateOnly dt={val}/>,
        }, {
            title: "Статус", dataIndex: "status", key: "status",
            render: (val, rec) => orderStatusTag(val),
        }, {
            title: "Тип заказа", dataIndex: "type", key: "type",
            render: (val, rec) => OrderTypeTag(val),
        }, {
            title: "Сумма", dataIndex: "cost", key: "cost",
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>,
        }, {
            title: "НДС", dataIndex: "tax", key: "tax",
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>,
        }, {
            title: "Продавец", dataIndex: "seller", key: "seller",
            hidden: subscribe.contract.seller.id == contragent.id,
            render: (val, rec) => (
                <Link to={`/contragents/${rec.seller?.id}`}>{val.name}</Link>
            ),
        }, {
            title: "Покупатель", dataIndex: "buyer", key: "buyer",
            hidden: subscribe.contract.buyer.id == contragent.id,
            render: (val, rec) => (
                <Link to={`/contragents/${rec.buyer?.id}`}>{val.name}</Link>
            ),
        }, {
            title: "Оплачено", dataIndex: "paid_amount", key: "paid_amount",
            render: (val, rec) => <Money sum={val} currency={rec.currency}
                                         text_color={(val === rec.cost) ?
                                             "success" :
                                             (val === 0) ? "" :
                                                 (val < rec.cost) ?
                                                     "warning" : ""}/>,
        }, {
            title: "Реализовано", dataIndex: "realized", key: "realized",
            render: (val, rec) => <Money sum={val} currency={rec.currency}/>,
        },
        {
            title: "Статус оплаты", dataIndex: "status_pay", key: "status_pay",
            render: (val, rec) => OrderPayStatusTag(val),
        },
    ];

    return <Spin spinning={loading}>
        <SearchTable dataSource={filteredOrders}
                     columns={columns.filter(x => !x.hidden)}
            // title={`Заказы подписки: ${subscribe.name}`}
                     toggle={toggle}
                     onSearchEdit={setSearch}/>
    </Spin>;
}

export function OrdersSubscribeModal({subscribe, visible, onCancel}) {
    if (visible)
        return <MyModal width={"90%"}
                        visible={visible}
                        btnname={"Закрыть"}
                        onCancel={onCancel}
                        onSuccess={onCancel}
                        hideCancelBtn={true}
                        title={`${subscribe.offer_name} "№ ${subscribe?.id}"`}>
            <OrdersSubscribeTable subscribe={subscribe}/>
        </MyModal>;
    else
        return null;
}
