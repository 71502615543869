import {Button, Divider, Form, Input, Select, Typography} from 'antd';
import React, {useEffect, useState} from 'react'
import Modal from "src/Base/Modals/Modal";
import {MailModal} from "../Modals/MailModal";
import {PPMAILService} from "app/ApiService/Contragent";
import DocumentService from "app/ApiService/Document";
import Docs from "../DocsTable";
import {X} from "app/ApiService/Query";


export default function SendDocumentModal({visible, item, onConfirm, ...props}) {
    const contragent_id = item?.buyer_name ? item?.buyer_id : item?.contragent; // item?.buyer_id || item?.contragent
    const [message, setMessage] = useState('');
    const [actionValue, setActionValue] = useState(false);
    const [form] = Form.useForm();
    const [showPreview, setShowPreview] = useState(false);
    const [emailBody, setEmailBody] = useState(false);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [useFields, setUseFields] = useState([]);
    const [helpText, setHelpText] = useState('');

    useEffect(() => {
        if (visible) {
            form.resetFields();
            // Это что за издевательство???
            // item?.buyer_name ? form.setFieldsValue({
            //     doc_id: item?.id,
            // })
            // :
            // form.setFieldsValue({
            //     rev_id: item?.id,
            // });
            if (item?.buyer_name)
                form.setFieldsValue({doc_id: item?.id})
            else
                form.setFieldsValue({rev_id: item?.id})

            form.setFieldsValue({
                ctg_id: contragent_id,
            });

            X.Contragent(contragent_id).Template().list().then(({data: {data}}) => {
                // new TemplateService(contragent_id).list().then(({ data: { data } }) => {
                setEmailTemplates(data);
            }).catch(err => {
                console.log("Возникла ошибка при загрузке шаблонов");
            });

            X.Attribute().list().then(({data: {data}}) => {
                // new AttributeService().list().then(({ data: { data } }) => {
                setUseFields(data);
            }).catch(err => {
                console.log("Возникла ошибка при загрузке списка полей");
            });
        }

    }, [visible]);

    function getRenderedTemplate(templateId = null, subject = null, message) {
        if (!templateId)
            templateId = form.getFieldsValue(['template']).template;
        if (!subject)
            subject = form.getFieldsValue(['subject']).subject;
        const data = {
            template: templateId,
            subject: subject,
            message: message
        }

        X.Contragent(contragent_id).Template().getRenderedTemplate(data).then(res => {
            form.setFieldsValue({
                subject: res.data.subject,
                message: res.data.body
            });
            setMessage(res.data.body);
        }).catch(err => {
            console.log("Возникла ошибка при загрузке шаблонов");
        });
    }

    function sendEmail(data) {
        const {id, ..._data} = data;
        // TODO: Это нужно переписать в новом стиле API
        new PPMAILService(contragent_id, id).sendDocs(_data).then((res) => {
            onConfirm?.(data);
        });

    }

    const validateFields = () => {
        const { email, field, subject, message } = form.getFieldsValue(['email', 'field', 'subject', 'message']);
        if (subject && message && (email || field)) {
            setActionValue(true);
        }
        else{
            setActionValue(false);
        }
    };

    const handleTemplateChange = (templateId) => {
        const selectedTemplate = emailTemplates.find(template => template.id === templateId);
        if (selectedTemplate) {
            form.setFieldsValue({
                template: templateId,
                subject: selectedTemplate.title,
                message: selectedTemplate.html
            });
            setMessage(selectedTemplate.html);
            getRenderedTemplate(templateId, selectedTemplate.title, selectedTemplate.html);
            validateFields();
        }
    };

    const handleFieldChange = (fieldId) => {
        var selectedField = useFields.find(field => field.id === fieldId);
        if (selectedField) {
            X.Contragent(contragent_id).attributes.get().then((res) => {
                var attr = res.data.data[0].attributes.find(attr => attr.id === fieldId);
                setHelpText(attr.default);
            }).catch(err => {
                setHelpText('Поле не заполнено');
            });
            validateFields();
        }
    };

    const title = item?.buyer_name
                ? `Отправить счет: ${item?.buyer_name}`
                : `Отправить документы: ${item?.contragent_name}`;
    return (
        <Modal {...props} visible={visible} title={title} footer={
            <Button disabled={!actionValue} type='primary' htmlType='submit' form='forward_check'>Отправить</Button>
        }>
            <Form layout='vertical' form={form} onFinish={sendEmail} id='forward_check'>

                <Form.Item label="Фиксированная почта" name="email" help="отправляется при отсутсвии поля">
                    <Input type="email" onChange={validateFields}/>
                </Form.Item>

                <Form.Item label="Использовать поле" name="field" help={helpText}>
                    <Select onChange={handleFieldChange}>
                        {useFields.map(field => (
                            <Select.Option key={field.id} value={field.id}>
                                {field.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Шаблон для отправки" name="template">
                    <Select onChange={handleTemplateChange}>
                        {emailTemplates.map(template => (
                            <Select.Option key={template.id} value={template.id}>
                                {template.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label='Заголовок' name='subject'
                    rules={[{ required: true, message: 'Это поле обязательно' }]}
                    onChange={validateFields}
                >
                    <Input type='text' />
                </Form.Item>

                <Form.Item label='Текст письма' name='message'
                    rules={[{ required: true, message: 'Это поле обязательно' }]}
                    onChange={validateFields}
                >
                    <Input.TextArea
                        rows='8'
                        onChange={(e) => {
                            const newMessage = e.target.value;
                            getRenderedTemplate(null, null, newMessage);
                        }}
                    />
                </Form.Item>

                <Button type='primary' onClick={() => { setEmailBody(true) }}> Показать содержимое</Button>
                <div style={{ marginTop: '16px' }}>
                    <strong>Вложения: </strong>{
                        (item?.buyer_name) ?
                        <Typography.Link onClick={() => new DocumentService(contragent_id).downloadDocument(item.document_url)}>
                            {item.name}
                        </Typography.Link>
                        :
                        <Docs revenue={item}/>
                    }
                </div>

                <Form.Item style={{ display: 'none'}} name="doc_id">
                    <Input type='text'/>
                </Form.Item>
                <Form.Item style={{ display: 'none'}} name="rev_id">
                    <Input type='text'/>
                </Form.Item>
                <Form.Item style={{ display: 'none'}} name="ctg_id">
                    <Input type='text'/>
                </Form.Item>
                {showPreview && (
                    <>
                        <Divider>Предпросмотр текста письма</Divider>
                        <div
                            style={{
                                border: '1px solid #d9d9d9',
                                padding: '10px',
                                borderRadius: '4px',
                                minHeight: '100px',
                                backgroundColor: '#f5f5f5'
                            }}
                            dangerouslySetInnerHTML={{ __html: message || 'Введите текст письма выше, чтобы увидеть предпросмотр' }}
                        />
                    </>
                )}
            </Form>

            <MailModal
                visible={!!emailBody}
                onCancel={() => setEmailBody(false)}
                onSubmit={() => setEmailBody(false)}
                emailBody={message}
            />
        </Modal>
    );
}
