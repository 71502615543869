import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {initialization as init} from '../ApiService/Sys';


const SystemContext = React.createContext();


export function SystemProvider({children}) {
    // Контекст общей информации о системе биллинга

    const [system, setSystem] = useState(false);
    const [sandbox, setSandbox] = useState(null);

    const timer = useRef();

    function fetchInterval(){
        init().then(res => {
            clearInterval(timer.current);
            setSystem(res.data);
            setSandbox(null);
        }).catch(err => {
            if(err?.response?.status === 451){
                let sandbox_data = err?.response?.data;
                sandbox_data.begin_date = new Date(sandbox_data.begin_date);
                sandbox_data.current_date = new Date(sandbox_data.current_date);
                sandbox_data.arrive_date = new Date(sandbox_data.arrive_date);
                setSandbox(sandbox_data);
            }
        })
    }

    useEffect(() => {
        init().then(res => {
            setSystem(res.data);
        }).catch(err => {
            if(err?.response?.status === 451){
                let sandbox_data = err?.response?.data;
                sandbox_data.begin_date = new Date(sandbox_data.begin_date);
                sandbox_data.current_date = new Date(sandbox_data.current_date);
                sandbox_data.arrive_date = new Date(sandbox_data.arrive_date);
                setSandbox(sandbox_data);
                timer.current = setInterval(fetchInterval, 1000);
            }
        })

        return () => clearInterval(timer.current)
    }, [])

    const memoedValue = useMemo(() => ({
        system, sandbox, setSandbox, setSystem
    }),
    [system, sandbox]
);

    return (
        <SystemContext.Provider value={memoedValue}>
            {children}
        </SystemContext.Provider>
    );
}

export default function useSystemInfo() {
    return useContext(SystemContext);
}
