import {Form, notification} from "antd";
import Modal from "app/Base/Modals/Modal";
import React, {useEffect} from "react";
import AttributeForm from "../Forms/AttributeForm";
import {X} from "app/ApiService/Query";


export default function AttributeModal({data, onSuccess, ...props}) {

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                id: data.id,
                name: data.name,
                measure: data.measure?.id || data.measure,
                codename: data.codename,
                type: data.type,
                enum_group: data.enum_group,
                is_required: data.is_required || false,
            });
        } else
            form.resetFields();
    }, [data]);

    function handleAttribute(d) {
        const as = X.Attribute(data?.id);
        const methodname = (data) ? "update" : "create";

        as[methodname](d).then(res => {
            if (typeof props.onSuccess === "function") {
                props.onSuccess(res.data);
            }
            notification.success({message: "Атрибут сохранен"});
            onSuccess && onSuccess(d);
        }).catch(err => {
            notification.error({
                message: "Невозможно сохранить атрибут",
                description: err?.response?.data?.detail
                    || err?.response?.data?.msg
                    || err?.response?.data?.message,
            });
        });
    }

    return <Modal {...props}
                  btnname={"Сохранить"}
                  title={(data) ? "Редактировать атрибут" : "Создать атрибут"}
                  form={"manage_attribute"}>
        <AttributeForm form={form} id="manage_attribute" onFinish={handleAttribute}/>
    </Modal>;
}
