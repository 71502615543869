import React, {useState, useMemo, useContext, useEffect} from 'react';
import {Skeleton} from "antd";
import API_ from '../ApiService/Module'

const IntegrationContext = React.createContext();


export function IntegrationProvider({children}) {
    // Контекст хранилища по работе с сервисами интеграции

    const [atol, setAtol] = useState(false);

    const [integrations, setIntegrations] = useState({});
    const [update, setUpdate] = useState(false); // TODO: я бы лучше заюзал useToggle, а то вдруг с входных параметром не угадаешь
    const [loading, setLoading] = useState(false);

    const API = new API_();

    const intergrations = {
        'atol': {get: atol, set: setAtol}
    };

    useEffect(() => {
        setLoading(true);
        API.list(res => {
            res.data.data.map(module => {
                if (typeof intergrations[module] !== "undefined" && typeof intergrations[module].set === 'function') {
                    intergrations[module].set(true);
                }
                return module
            });
        })

        API.new_list(res => {
            const outputDict = Object.assign(
                {},
                ...res.data.data.map(item => ({[item.codename]: item}))
            );
            setIntegrations(outputDict);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (!loading) {
            API.new_list(res => {
                const outputDict = Object.assign(
                    {},
                    ...res.data.data.map(item => ({[item.codename]: item})),
                );
                setIntegrations(outputDict);
            });
        }
    }, [update]);

    function isEnabled(name) {
        if (integrations)
            return integrations[name]?.enable || false;
        return false;
    }

    function setStatusIntegration(name_module, status) {
        intergrations[name_module].set(status);
    }

    function getStatusIntegration(name_module) {
        return intergrations[name_module].get
    }

    const values = useMemo(() => (
        {
            integrations, atol, loading,

            getStatusIntegration,
            setStatusIntegration,
            setUpdate,
            isEnabled
        }
    ), [atol, integrations, loading]);

    return (
        <IntegrationContext.Provider value={values}>
            {/*{(loading) ? <Skeleton/> : children}*/}
            {children}
        </IntegrationContext.Provider>
    );
}

export default function useIntegration() {
    return useContext(IntegrationContext);
}
