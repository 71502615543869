import React from 'react'
import {Modal} from 'antd'
import OfferTable from '../../Tables/OfferTable';
import useContragent from '../../ContragentContext';
import {AtolX} from "app/ApiService/AtolQuery";


export default function ATOLRefillCounterModal({item, ...props}) {

    const {contragent} = useContragent();

    function onSelectOffer(v) {
        let data = {counter_id: item.id, offer_id: v.id}
        AtolX.Contragent(contragent).createRefillMeter(data)
    }

    return (
        <Modal title='Выбрать предложение для пополнения' width={'60%'} {...props}>
            <OfferTable onSelectOffer={onSelectOffer} filter={{tags__codename: 'atol_refill_meter'}}/>
        </Modal>
    )
}
