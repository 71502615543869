import {Menu, Tag, Typography} from "antd";
import {downloadDocument} from "app/ApiService/Document";
import {Fable} from "app/Base/Tables/Fable/Fable";
import {useEdoData, usePPMailData} from "app/Data/Base";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import useUser from "../../../Providers/UserProvider";

import {DateTime} from "../../Common/OtherComponents";
import useContragent from "../ContragentContext";
import {ForwardMailModal, MailModal} from "../Modals/MailModal";
import {X} from "app/ApiService/Query";


export function PPMailTable() {
    const [emailBody, setEmailBody] = useState();
    const {contragent} = useContragent();
    const [ppmail, setPPMail] = useState(null);
    const model = usePPMailData({contragent});
    const {is_global_permitted} = useUser();

    const columns = [

        {
            title: "Получатели",
            dataIndex: "emails",
            key: "emails",
            render: (value) => value && value.map(em => em).join(", "),

        }, {
            title: "Контрагент", dataIndex: "contragent", key: "contragent", render: (val, rec) => (
                (val) ? <Link to={`/contragents/${val?.id}`}>{val?.name}</Link> : "- нет -"
            ),
        }, {
            title: "Тема", dataIndex: "subject", key: "subject",
        }, {
            title: "Статус", dataIndex: "status", key: "status", render: (val, obj) => {
                if (val === "new") return <Tag>Новый</Tag>;
                if (val === "success") return <Tag color="green">Отправлено</Tag>;
                if (val === "error") return <Tag color="red">Ошибка при отправке</Tag>;
            },
        }, {
            title: "Отправлено", dataIndex: "_created", key: "_created", render: (value) => {
                return value ? <DateTime dt={value}/> : null;
            },
        }, {
            title: "Текст", dataIndex: "body", key: "body", render: (text) => {
                const maxLength = 100;
                return text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
            },
        }, // {
        //     title: "Вложения",
        //     dataIndex: "attachments",
        //     key: "attachments",
        //     render: (value) => value && value.map(att =>
        //                                               <Typography.Link
        //                                                   onClick={() => downloadDocument(att.file_url)}>{att.file_name}</Typography.Link>,
        //     ),
        // },
        {
            title: "Вложения", dataIndex: "attachments", key: "attachments", render: (value) => (<>
                {value && value.map((att, index) => (<div key={index} style={{marginBottom: "8px"}}>
                    <Typography.Link
                        onClick={() => downloadDocument(att.file_url)}
                    >
                        {att.file_name}
                    </Typography.Link>
                </div>))}
            </>),
        }];

    function actions(record) {
        return <Menu>
            <Menu.Item key="1">
                <Typography
                    onClick={() => {
                        setEmailBody(record.body);
                    }}
                >Показать содержимое</Typography>
            </Menu.Item>
            <Menu.Item key="2">
                <Typography onClick={() => {
                    setPPMail(record);
                }}>
                    Переслать
                </Typography>
            </Menu.Item>
        </Menu>;
    }

    return <>
        <Fable model={model}
               columns={columns}
               actions={is_global_permitted("application.mail.edit") && actions}
        />
        <MailModal
            visible={!!emailBody}
            onCancel={() => setEmailBody(null)}
            emailBody={emailBody}
        />

        {!!ppmail &&
            <ForwardMailModal
                visible={!!ppmail}
                ppmail={ppmail}
                onSubmit={() => setPPMail(null)}
                onCancel={() => setPPMail(null)}
            />}
    </>;

}

export function EDOMessageTable() {

    const model = useEdoData();

    function StatusSignTag(value) {
        switch (value) {
            case "pending":
                return <Tag color="grey">В ожидании</Tag>;
            case "signed":
                return <Tag color="green">Подписан</Tag>;
            case "signle":
                return <Tag color="green">Документооборот завершен</Tag>;
            case "not_signed":
                return <Tag color="red">Не подписан</Tag>;
            case "disagreement":
                return <Tag color="red">Подписан с разногласиями</Tag>;
            case "cancelled":
                return <Tag color="red">Аннулирован</Tag>;
            case "denied":
                return <Tag color="red">Отказано</Tag>;
            case "cancel_denied":
                return <Tag color="red">Отказано в аннулировании</Tag>;
            default:
                return null;
        }
    }

    const columns = [
        {
            title: "Документы",
            dataIndex: "doc",
            key: "doc",
            render: (value, record) => {
                let documents = record.documents?.map(el => {
                    return el.number;
                });
                return documents.join(", ");
            },
        },
        {
            title: "Статус сообщения",
            dataIndex: "status_sign",
            key: "status_sign",
            render: v => StatusSignTag(v),
        },
        {
            title: "Время отправки",
            dataIndex: "date",
            key: "date",
            render: v => <DateTime dt={v}/>,
        },

    ];


    return <Fable
        model={model}
        columns={columns}
        actions={(record) => {

            function refreshStatus() {
                X.EdoMessage(record.id).refreshStatus().then(_ => {
                    model.toggleTableUpdate();
                });
            }

            return <Menu>
                {/*<Menu.Item onClick={sendEmail}>*/}
                {/*    <Typography>Отправить документ</Typography>*/}
                {/*</Menu.Item>*/}
                <Menu.Item onClick={refreshStatus}>
                    <Typography>Обновить статус</Typography>
                </Menu.Item>
                {/*<Menu.SubMenu title="Сменить статус">*/}
                {/*    <Menu.Item onClick={() => changeStatus("signed")}>Подписан</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("signle")}>Документооборот завершен</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("disagreement")}>Подписан с разногласиями</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("not_signed")}>Не подписан</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("cancelled")}>Аннулирован</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("denied")}>Отказано</Menu.Item>*/}
                {/*    <Menu.Item onClick={() => changeStatus("cancel_denied")}>Отказано в аннулировании</Menu.Item>*/}
                {/*</Menu.SubMenu>*/}
            </Menu>;
        }}
    />;
}
