import {Col, Form, message, Typography} from "antd";
import {Contragent} from "app/ApiService/Query";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";

import {DebounceSelect, renderInput} from "src/lib/dynamic-comps";
import useIntegration from "src/Providers/IntegrationsProvider";


async function fetchCAList(ca_name = "") {
    return Contragent().partners(ca_name).then(
        res => {
            let ca_list = res.data.data.map(ca => ({label: ca.name, value: ca.external_id}));
            ca_list.push({label: "Нет партнера", value: null});
            return ca_list;
        },
    );
}

function AttrPartnerId({attr}) {
    const [value, setValue] = useState();

    return (
        <Form.Item key={attr.codename}
                   name={attr.codename}
                   label={attr.name}
                   required={attr.is_required}
                   initialValue={attr.default}
        >
            <DebounceSelect
                showSearch
                // allowClear
                value={value}
                placeholder="Выбор партнера"
                fetchOptions={fetchCAList}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                style={{
                    width: "100%",
                }}
            />
        </Form.Item>
    );
}


export default function AttributesForm({children, attributes, ...props}) {
    const {atol} = useIntegration();

    return <Form layout="vertical" {...props}>
        {children}
        {attributes.map(attr => (
            (attr.codename === "partner_id" && atol)
                ? <AttrPartnerId attr={attr}/>
                : <Form.Item key={attr.codename}
                             name={attr.codename}
                             label={attr.name}
                             required={attr.is_required}
                             initialValue={attr.default === null ? undefined : attr.default}>
                    {renderInput(attr.type, attr.enums)}
                </Form.Item>
        ))}
    </Form>;
}

export function EditAttributesForm({obj, contragent, apisrv, onFinish, ...props}) {
    const [dictionaries, setDictionaries] = useState([]);
    const [attributes, setAttributes] = useState([]);

    useEffect(() => {
        if (obj)
            apisrv.attributes.get().then(res => setDictionaries(res.data.data));
    }, [obj]);

    useEffect(() => {
        if (dictionaries)
            setAttributes(dictionaries.reduce((a, b) => ([...a, ...b.attributes]), []));
    }, [dictionaries]);

    function onFinishForm(data) {
        apisrv.update({attributes: data}).then(res => {
            message.success("Атрибуты обновлены.");
            onFinish?.();
        });
    }

    return <>
            {attributes.length === 0 && <Typography>
                    В данный момент нет активных атрибутов. Создайте или назначьте в <Link
                    to="/dictionary">справочнике</Link> группы атрибутов.
                </Typography> ||
                <Col span={24}>
                    <AttributesForm id={props.id}
                                    attributes={attributes}
                                    onFinish={onFinishForm}/>
                </Col>}
        </>;
}
